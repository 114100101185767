import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ButtonField from 'components/Common/FormFields/ButtonFields'
import React from 'react'

function Modal({ cancelAction, heading, text, confirmText, cancelText, confirmAction, ...props }) {
  const [open] = useState(true)

  const onConfirmAction = () => {
    confirmAction()
    props?.showProfileAction(false)
    cancelAction()
  }
  const onCancelAction = () => {
    cancelAction()
    props?.showProfileAction(false)
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static={true} className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  max-w-[342px] max-h-[173px]">
                <div className="h-[173px] w-[343px] bg-white flex items-center justify-center flex-col rounded-b-[10px]">
                  <p className="font-primary text-lg text-purple-dark">{heading}</p>
                  <p className="font-primary text-sm text-silver-darker w-[266px] leading-5 mt-1 mb-2 text-center	">
                    {text}
                  </p>
                  <div className="flex flex-row justify-center mt-6">
                    {props.showProfileAction && (
                      <div className="mr-3">
                        <ButtonField
                          name={cancelText}
                          variant={'outlined'}
                          width="144px"
                          height="40px"
                          onClick={onCancelAction}
                        />
                      </div>
                    )}

                    <div>
                      <ButtonField
                        name={confirmText}
                        variant={props.variant ? 'contained' : 'outlined'}
                        width="144px"
                        height="40px"
                        type="button"
                        className={confirmText === 'Save' ? 'text-white' : ''}
                        onClick={onConfirmAction}
                      />
                    </div>

                    {!props.showProfileAction && (
                      <div className="ml-2">
                        <ButtonField
                          name={cancelText}
                          variant={'outlined'}
                          width="144px"
                          height="40px"
                          onClick={onCancelAction}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default Modal
