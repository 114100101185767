import styled from 'styled-components'

export const TabWraper = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: center;
  .tabStyle {
    border-radius: 8px 8px 0px 0px;
    z-index: 10;
    /* font-size: 12px; */
    justify-content: center;
    /* width: 50%; */
  }
  .tabSecStyle {
    border-radius: 8px 8px 0px 0px;
    z-index: 0;
    /* font-size: 12px; */
    justify-content: center;
    /* width: 50%; */
  }

  /* @media screen and (min-width: 868px) {
    width: auto;
    .tabSecStyle,
    .tabStyle {
      font-size: 14px;
      width: auto;
    }
  }
  @media screen and (min-width: 1200px) {
    .tabSecStyle,
    .tabStyle {
      font-size: 16px;
    }
  } */
`
