import { useEffect, useMemo } from 'react'

export default function useSubIndustryContainer({
  assessment,
  profileForm,
  reset,
  isIndustryChanged,
  setIsSubIndustrySelected,
}) {
  const profileFormValues = profileForm?.getValues() || []

  const { industryQuestion, foodIndustryQuestionOption, subIndustryQuestion } = useMemo(() => {
    const industryQuestion = assessment?.find((question) => question?.meta?.isIndustry)
    const foodIndustryQuestionOption = industryQuestion?.options?.find(
      (opt) => opt.value === 'Food'
    )
    const subIndustryQuestion = assessment?.find((question) => question?.meta?.isSubIndustry)
    return { industryQuestion, foodIndustryQuestionOption, subIndustryQuestion }
  }, [assessment])

  const isSubIndustryExist = useMemo(() => {
    if (
      profileFormValues[industryQuestion?.id] === foodIndustryQuestionOption?.id &&
      profileFormValues[subIndustryQuestion?.id]?.length === 0
    )
      return true
    else return false
    // eslint-disable-next-line
  }, [profileFormValues])

  useEffect(() => {
    setIsSubIndustrySelected(false)
    if (
      profileFormValues &&
      profileFormValues[industryQuestion?.id] !== foodIndustryQuestionOption?.id
    ) {
      reset({
        ...profileFormValues,
        [subIndustryQuestion?.id]: [],
      })
    } else if (
      profileFormValues[industryQuestion?.id] === foodIndustryQuestionOption?.id &&
      profileFormValues[subIndustryQuestion?.id]?.length === 0
    ) {
      setIsSubIndustrySelected(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIndustryChanged, setIsSubIndustrySelected, isSubIndustryExist])

  return {
    industryQuestion,
    foodIndustryQuestionOption,
    subIndustryQuestion,
    profileFormValues,
  }
}
