import React from 'react'

function DownArrowIcon({ className }) {
  return (
    <svg
      className={className}
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6769 2.66953L6.30779 7.16953C6.06373 7.39102 5.78185 7.5 5.49998 7.5C5.2181 7.5 4.93691 7.39014 4.72207 7.17041L0.353008 2.67041C0.00769009 2.34961 -0.0865112 1.86445 0.0837826 1.44609C0.254076 1.02773 0.655508 0.75 1.09998 0.75H9.86904C10.3139 0.75 10.7154 1.02355 10.8859 1.44434C11.0564 1.86512 10.9931 2.34961 10.6769 2.66953Z"
        fill="#71717A"
      />
    </svg>
  )
}
DownArrowIcon.defaultProps = {
  className: 'cursor-pointer',
}

export default DownArrowIcon
