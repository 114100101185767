import React from 'react'

function SortIcon({ className }) {
  return (
    <svg
      className={className}
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill=""
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.5 4.6875C6.6188 4.6875 6.7376 4.64174 6.82819 4.55016C7.00955 4.36705 7.00955 4.07027 6.82819 3.88746L4.04247 1.07496C3.86111 0.891856 3.56716 0.891856 3.38609 1.07496L0.600377 3.88746C0.419015 4.07057 0.419015 4.36734 0.600377 4.55016C0.781738 4.73297 1.07569 4.73326 1.25676 4.55016L3.25 2.53799V13.5938C3.25 13.8516 3.45893 14.0625 3.71428 14.0625C3.96964 14.0625 4.17857 13.8529 4.17857 13.5938V2.53799L6.17181 4.55039C6.26205 4.64063 6.38102 4.6875 6.5 4.6875ZM12.3993 10.4502C12.3094 10.3594 12.1904 10.3125 12.0714 10.3125C11.9525 10.3125 11.8338 10.3583 11.7432 10.4498L9.75 12.4629V1.40625C9.75 1.14727 9.54107 0.9375 9.28571 0.9375C9.03035 0.9375 8.82143 1.14715 8.82143 1.40625V12.4629L6.82819 10.4505C6.64683 10.2674 6.35288 10.2674 6.17181 10.4505C5.99073 10.6336 5.99044 10.9304 6.17181 11.1132L8.95752 13.9257C9.13888 14.1088 9.43283 14.1088 9.6139 13.9257L12.3996 11.1132C12.5821 10.9307 12.5821 10.6318 12.3993 10.4502Z" />
    </svg>
  )
}
export default SortIcon
