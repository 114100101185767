import React from 'react'
import ContentLoader from 'react-content-loader'

import { colorsPallete } from 'config'

const ConnectedToolsLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${colorsPallete.cream.dark}`}
        foregroundColor={`${colorsPallete.grey.lighter}`}
      >
        <rect x="0" y="1" rx="0" ry="0" width="1" height="140" />
        <rect x="0" y="140" rx="0" ry="0" width="205" height="1" />
        <rect x="0" y="1" rx="0" ry="0" width="205" height="1" />
        <rect x="205" y="1" rx="0" ry="0" width="1" height="140" />

        <rect x="20" y="20" rx="2" ry="2" width="170" height="55" />
        <rect x="62" y="82" rx="2" ry="2" width="90" height="10" />

        <rect x="230" y="1" rx="0" ry="0" width="1" height="140" />
        <rect x="230" y="140" rx="0" ry="0" width="205" height="1" />
        <rect x="230" y="1" rx="0" ry="0" width="205" height="1" />
        <rect x="435" y="1" rx="0" ry="0" width="1" height="140" />

        <rect x="250" y="20" rx="2" ry="2" width="170" height="55" />
        <rect x="295" y="82" rx="2" ry="2" width="90" height="10" />

        <rect x="0" y="166" rx="0" ry="0" width="1" height="140" />
        <rect x="0" y="305" rx="0" ry="0" width="205" height="1" />
        <rect x="0" y="166" rx="0" ry="0" width="205" height="1" />
        <rect x="205" y="166" rx="0" ry="0" width="1" height="140" />

        <rect x="20" y="184" rx="2" ry="2" width="170" height="55" />
        <rect x="62" y="246" rx="2" ry="2" width="90" height="10" />

        <rect x="230" y="166" rx="0" ry="0" width="1" height="140" />
        <rect x="230" y="305" rx="0" ry="0" width="205" height="1" />
        <rect x="230" y="166" rx="0" ry="0" width="205" height="1" />
        <rect x="435" y="166" rx="0" ry="0" width="1" height="140" />

        <rect x="250" y="184" rx="2" ry="2" width="170" height="55" />
        <rect x="295" y="246" rx="2" ry="2" width="90" height="10" />
      </ContentLoader>
    </>
  )
}

export default ConnectedToolsLoader
