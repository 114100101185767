import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import React from 'react'
import CrossIcon from 'components/Common/SvgIcons/CrossIcon'

function Modal({
  title = '',
  children,
  onClose,
  width = '',
  classes = '',
  wrapperClass = '',
  backgroundColor,
  childClass,
  showCancelButton,
  margin,
  height,
  isOnBoarding,
}) {
  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className={`relative z-50 ${wrapperClass} `} onClose={() => undefined}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc bg-opacity-75" />
        </Transition.Child>

        <div className={`fixed inset-0 overflow-y-auto ${margin}`}>
          <div className={` ${height} flex items-center`}>
            <div
              className={`rounded-xl modal-container w-full  ${
                width ? 'w-full' : 'max-w-[800px] mx-auto my-5'
              } 
            ${backgroundColor ? backgroundColor : 'bg-white'} 
            shadow-xl
             `}
            >
              {title && (
                <div className="flex flex-row justify-between items-center p-7 top-0 border-primary-outline border-b">
                  <div className="text-primary-text text-2xl font-primary">{title}</div>
                  <div onClick={onClose}>
                    <CrossIcon className={'text-primary-text  h-[25px] w-[25px] cursor-pointer'} />
                  </div>
                </div>
              )}
              {showCancelButton && (
                <span onClick={onClose}>
                  <CrossIcon
                    className={`xs:hidden sm:block h-[25px] w-[25px] cursor-pointer absolute z-10 ${
                      isOnBoarding
                        ? 'relative fill-black !inline top-5 left-5'
                        : 'fill-grey-lighter z-10 top-10 left-10'
                    } `}
                  />
                </span>
              )}

              <div className="modalBlock rounded-b-xl overflow-y-auto overflow-x-hidden">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className={`${
                      width ? `${width}` : ' max-w-[799px]'
                    }  ${classes} transform overflow-hidden text-left  transition-all`}
                  >
                    <Dialog.Description className={childClass}>{children}</Dialog.Description>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.defaultProps = {
  backgroundColor: 'bg-white',
  onClose: undefined,
  childClass: '',
  overFlow: 'overflow-auto',
  showCancelButton: false,
  margin: '',
  height: 'h-full',
  isOnBoarding: false,
}
export default Modal
