import React from 'react'
import ButtonField from 'components/Common/FormFields/ButtonField'
import history from 'utils/history'
import { useParams } from 'react-router-dom'
import { getRoleFromPath } from 'utils/helper'
import { isTablet } from 'mobile-device-detect'

const UserManagementHeading = ({ title }) => {
  const { tenantId } = useParams()
  const userRole = getRoleFromPath()
  return (
    <>
      <div className="flex justify-between w-full min-w-[800px]">
        <div className="text-3xl font-normal whitespace-nowrap pb-5">Manage {title} Accounts</div>
      </div>
      {isTablet && (
        <div className="mb-6">
          <ButtonField
            type="button"
            name={`+ New ${title !== 'Advisor' ? 'Business' : 'Advisor'} Account`}
            variant="secondary"
            className="w-[240px]"
            onClick={() =>
              history.push(
                `${
                  title !== 'Advisor'
                    ? `/${userRole}/${tenantId}/create-business-owner`
                    : `/${userRole}/${tenantId}/create-advisor`
                }`
              )
            }
          />
        </div>
      )}
    </>
  )
}

export default UserManagementHeading
