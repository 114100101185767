import pick from 'lodash/pick'
import {
  GET_USER_BUSINESS_PROFILE,
  GET_USER_PROFILE,
  UPDATE_USER_BUSINESS_PROFILE,
  UPDATE_USER_PROFILE,
  CHAT_UNREAD_MESSAGE_COUNT,
  GET_USER_NOTIFICATIONS,
  DELETE_USER_NOTIFICATIONS,
  UPDATE_MY_TOOLS,
  CLIENTS_SUMMARY,
} from 'store/types'

const initialState = {
  user: {},
  businessProfile: {},
  notifications: { data: [], pagination: '', totalCount: 0, totalUnread: 0 },
  error: '',
  disableButtonsButtons: false,

  userProfile: {},
  chatUsers: {},
  chatUnreadMessageCount: 0,
  updateOwner: '',
  summary: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_CHAT_USERS':
      return {
        ...state,
        chatUsers: action.payload,
      }

    case GET_USER_PROFILE.FULLFILLED:
      return {
        ...state,
        ...action.payload,
      }
    case GET_USER_BUSINESS_PROFILE.FULLFILLED:
      return {
        ...state,
        businessProfile: action?.payload,
      }
    case UPDATE_USER_PROFILE.STARTED:
      return {
        ...state,
        disableButtons: true,
      }
    case UPDATE_USER_PROFILE.FULLFILLED:
      return {
        ...state,
        user: action?.payload,
        disableButtons: false,
      }
    case UPDATE_USER_PROFILE.REJECTED:
      return {
        ...state,
        error: action.payload,
        disableButtons: false,
      }
    case UPDATE_USER_BUSINESS_PROFILE.STARTED:
      return {
        ...state,
        disableButtons: true,
      }
    case UPDATE_USER_BUSINESS_PROFILE.REJECTED:
      return {
        ...state,
        error: action.payload,
        disableButtons: false,
      }
    case UPDATE_USER_BUSINESS_PROFILE.FULLFILLED:
      return {
        ...state,
        disableButtons: false,
        businessProfile: action?.paylaod,
      }

    case CHAT_UNREAD_MESSAGE_COUNT.FULLFILLED:
      return {
        ...state,
        chatUnreadMessageCount: action.payload,
      }
    case DELETE_USER_NOTIFICATIONS.STARTED:
    case GET_USER_NOTIFICATIONS.STARTED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: true,
        },
      }
    case DELETE_USER_NOTIFICATIONS.FULLFILLED:
    case GET_USER_NOTIFICATIONS.FULLFILLED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: false,
          ...pick(action.payload, ['data', 'pagination', 'totalCount', 'totalUnread']),
        },
      }
    case UPDATE_MY_TOOLS.FULLFILLED:
      return {
        ...state,
        updateOwner: action.payload,
      }
    case CLIENTS_SUMMARY.FULLFILLED:
      return {
        ...state,
        summary: action.payload,
      }
  }

  return state
}

export default reducer
