import React from 'react'
import styled from 'styled-components'

const StyledParagraph = styled.p``

const ErrorMessage = ({ id, children }) => {
  return (
    <StyledParagraph
      id={`${id}-error`}
      data-testid={`${id}-error`}
      className="text-primary-error font-primary text-sm font-semibold my-2"
    >
      {children}
    </StyledParagraph>
  )
}

export default ErrorMessage
