import styled from 'styled-components'

export const SelectFieldWrapper = styled.div`
  .select__indicator {
    display: none !important;
  }
  .select__indicator-separator {
    display: none !important;
  }
`
