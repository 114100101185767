export const getUserName = (user) => {
  return user?.firstName ? `${user.firstName || ''} ${user.lastName || ''}` : user?.name
}

export const getChannelName = (members) => {
  try {
    if (!members.length || members.length === 1) {
      return getUserName(members[0]?.user)
    }
    const memberName = members.map((member) => getUserName(member?.user))

    return ` ${memberName} `
  } catch (err) {
    console.log('***********************')
    return ''
  }
}

export const getBusinessName = (members) => {
  try {
    if (members && members.length === 1) {
      return members[0]?.user?.businessName
    } else {
      return ''
    }
  } catch (err) {
    return ''
  }
}
