import { call, put, takeLatest, select } from 'redux-saga/effects'
import history from 'utils/history'

import { FORGOTPASSWORD, FORGOTPASSWORDSUBMIT, LOGIN, LOGOUT, SIGNUP } from 'store/types'
import { loginAction, forgotPasswordAction, resetPasswordAction } from 'store/actions/auth'
import { userInitialRouting } from 'utils/helper'
import { deleteSession } from 'utils/user'
import AuthApi from 'api/auth'
import UserApi from 'api/user'
import { getUserProfileAction } from 'store/actions/user'
import { getAuthToken } from 'lib/auth'
import { get } from 'lodash'
import { ampli } from 'ampli'

function* login(action) {
  const { email, password } = action.payload

  try {
    const user = yield call(AuthApi.login, email, password)
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      history.push('/auth/reset-password')
    } else {
      const userProfileResponse = yield call(UserApi.getUserProfile)
      const authToken = yield getAuthToken()
      yield call(UserApi.logActivity, {
        action: 'signin',
        accessToken: authToken,
        tenantId: get(userProfileResponse, 'getMyProfile.tenantId[0]', ''),
      })
      const userProfile = userProfileResponse.getMyProfile
      ampli.identify(userProfile.id)
      ampli.loggedIn({
        id: userProfile.id,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
      })
      userInitialRouting(userProfileResponse)
    }
    yield put(loginAction.FULLFILLED({ user, disable: false }))
  } catch (error) {
    yield put(loginAction.REJECTED({ error }))
    ampli.loginFailed({ email: email })
    console.log(error)
  }
}

function* signup(action) {
  try {
    const { email, password, firstName, lastName } = action.payload
    yield call(AuthApi.signup, { email, password, firstName, lastName })
    history.push('/login')
  } catch (error) {}
}

function* forgotPassword(action) {
  try {
    const { email } = action.payload
    const response = yield call(AuthApi.forgotPassword, email)
    yield put(forgotPasswordAction.FULLFILLED({ disable: false }))
    ampli.passwordResetInitiated({ email: email })
    if (response) {
      history.push({
        pathname: '/auth/success',
        state: { thankyou: 'thankyou' },
      })
    }
  } catch (error) {
    yield put(forgotPasswordAction.REJECTED({ error }))
    console.log(error)
  }
}

function* forgotPasswordSubmit(action) {
  const user = yield select((state) => state.auth.user)

  const { email, code, password } = action.payload
  try {
    if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const response = yield call(AuthApi.changePassword, user, password)
      yield put(resetPasswordAction.FULLFILLED({ disable: false }))
      if (response) {
        const authToken = yield getAuthToken()
        const userProfileResponse = yield call(UserApi.getUserProfile)
        yield call(UserApi.logActivity, {
          action: 'First Sign In',
          accessToken: authToken,
          tenantId: get(userProfileResponse, 'getMyProfile.tenantId[0]', ''),
          showClientActivity: true,
          logStatus: '',
        })
        const userProfile = userProfileResponse.getMyProfile
        ampli.identify(userProfile.id)
        ampli.loggedIn({
          id: userProfile.id,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: userProfile.email,
        })
        userInitialRouting(userProfile)
      }
    } else {
      const response = yield call(AuthApi.forgotPasswordSubmit, email, code, password)
      yield put(resetPasswordAction.FULLFILLED({ disable: false }))
      if (response) {
        history.push({
          pathname: '/auth/success',
          state: { passwordChanged: 'passwordChanged' },
        })
      }
      ampli.passwordResetCompleted({ email: email })
    }
  } catch (error) {
    yield put(resetPasswordAction.REJECTED({ error }))
  }
}

function* logout(action) {
  const user = yield select((state) => state.user.user)
  const authToken = yield getAuthToken()
  try {
    yield call(UserApi.logActivity, {
      action: 'signout',
      accessToken: authToken,
      tenantId: user.tenantId[0],
    })
    yield call(AuthApi.logout)
    yield put(getUserProfileAction.FULLFILLED({ user: {}, userProfile: {} }))
    deleteSession()
    ampli.loggedOut()
    history.push('/auth')
  } catch (error) {
    console.log(error)
  }
}
/// /////////// Watchers ///////////////////////
export function* watcherAuth() {
  yield takeLatest(LOGIN.STARTED, login)
  yield takeLatest(SIGNUP.STARTED, signup)
  yield takeLatest(FORGOTPASSWORD.STARTED, forgotPassword)
  yield takeLatest(FORGOTPASSWORDSUBMIT.STARTED, forgotPasswordSubmit)
  yield takeLatest(LOGOUT, logout)
}
