import React from 'react'
import ContentLoader from 'react-content-loader'

import { colorsPallete } from 'config'

const AdvisorOwnerProfileLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 200 300"
        backgroundColor={`${colorsPallete.cream.dark}`}
        foregroundColor={`${colorsPallete.grey.lighter}`}
      >
        <rect x="0" y="4" rx="2" ry="2" width="86" height="15" />

        <rect x="0" y="46" rx="2" ry="2" width="70" height="10" />
        <rect x="0" y="65" rx="2" ry="2" width="70" height="10" />

        <rect x="0" y="102" rx="2" ry="2" width="70" height="10" />
        <rect x="0" y="123" rx="2" ry="2" width="70" height="10" />

        <rect x="0" y="161" rx="2" ry="2" width="110" height="10" />
        <rect x="0" y="182" rx="2" ry="2" width="110" height="10" />

        <rect x="0" y="219" rx="2" ry="2" width="70" height="10" />
        <rect x="0" y="237" rx="2" ry="2" width="70" height="10" />
      </ContentLoader>
    </>
  )
}

export default AdvisorOwnerProfileLoader
