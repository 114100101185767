import React, { useEffect } from 'react'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import ChatApplication from './Chat'
import { getImage } from './assets'
import { getChannelListOptions } from './channelListOptions'
import { UnreadProvider } from 'components/Chat/contexts/UnreadContext'
import './index.css'
import { APP_STREAM_KEY } from 'config'
import { Container } from 'components/Common/StyledComponents'

const Chat = ({
  user,
  chatUsers,
  token,
  usersAssessmentResponseByQuestion,
  getUsersAssessmentResponseByQuestionAction,
  getChatUsersAction,
  chatUnreadMessageCountAction,
  uploadFileAction,
}) => {
  const { tenantId } = useParams()

  const apiKey = APP_STREAM_KEY
  const urlParams = new URLSearchParams(window.location.search)
  const targetOrigin = urlParams.get('target_origin') || process.env.REACT_APP_TARGET_ORIGIN
  const noChannelNameFilter = urlParams.get('no_channel_name_filter') || false
  const skipNameImageSet = urlParams.get('skip_name_image_set') || false

  const channelListOptions = getChannelListOptions(!!noChannelNameFilter, user)
  const userToConnect: { id: string; name?: string; image?: string } = {
    id: user?.id!,
    name: skipNameImageSet ? undefined : user!,
    image: skipNameImageSet ? undefined : getImage(user!),
  }

  useEffect(() => {
    getChatUsersAction({ tenantId })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container>
      <div className="w-full m-0">
        <UnreadProvider>
          <ChatApplication
            token={token}
            userId={user?.id}
            chatUsers={chatUsers}
            apiKey={apiKey!}
            userToConnect={userToConnect}
            targetOrigin={targetOrigin!}
            channelListOptions={channelListOptions}
            usersAssessmentResponseByQuestion={usersAssessmentResponseByQuestion}
            getUsersAssessmentResponseByQuestionAction={getUsersAssessmentResponseByQuestionAction}
            chatUnreadMessageCountAction={chatUnreadMessageCountAction}
            uploadFileAction={(streamMsgId: string) =>
              uploadFileAction({
                userId: user?.id,
                tenantId: get(user, `tenantId[0]`),
                streamMessageId: streamMsgId,
              })
            }
          />
        </UnreadProvider>
      </div>
    </Container>
  )
}

export default Chat
