export const colorsPallete = require('../colors-palette')

export const API_BASE_PATH: string = process.env.REACT_APP_API_URL || 'http://localhost:4000/api/v1'
export const APP_STREAM_KEY: string = process.env.REACT_APP_STREAM_KEY || 'hc4z4u84w63g'

export const COOKIE_USER_TOKEN = 'USER_SESSION'
export const COOKIE_USER = 'USER_DETAILS'
export const IS_COOKIES_ALLOW = 'IS_COOKIES_ALLOW'
export const COMPANY_NAME = 'Tackle'

export const UPPER_CASE_REGEX = '(.*[A-Z].*)'
export const NUMBER_REGEX = '(.*\\d.*)'
export const SPECIAL_CHARACTER_REGEX = '[!%&@#$]'

export * from 'config/enums'
export * from 'config/data'
export * from 'config/columns'
