import React from 'react'
import ContentLoader from 'react-content-loader'

import { colorsPallete } from 'config'

const TopActiveClientsLoader = ({ isMobile }) => {
  return (
    <>
      {isMobile ? (
        <ContentLoader
          speed={2}
          viewBox="0 0 376 85"
          backgroundColor={`${colorsPallete.cream.dark}`}
          foregroundColor={`${colorsPallete.grey.lighter}`}
        >
          <rect x="80" y="30" rx="6" ry="6" width="88" height="10" />
          <rect x="80" y="48" rx="3" ry="3" width="52" height="6" />
          <circle cx="38" cy="40" r="23" />
          <circle cx="355" cy="30" r="9" />
          <rect x="315" y="48" rx="3" ry="3" width="52" height="6" />
        </ContentLoader>
      ) : (
        <ContentLoader
          speed={2}
          viewBox="0 0 350 260"
          backgroundColor={`${colorsPallete.cream.dark}`}
          foregroundColor={`${colorsPallete.grey.lighter}`}
        >
          <circle cx="173" cy="80" r="52" />
          <rect x="60" y="150" rx="8" ry="8" width="221" height="15" />
          <rect x="2" y="190" rx="8" ry="8" width="85" height="12" />
          <rect x="245" y="225" rx="8" ry="8" width="85" height="12" />
          <rect x="2" y="225" rx="8" ry="8" width="85" height="12" />
          <rect x="246" y="190" rx="8" ry="8" width="85" height="12" />
          <rect x="1" y="215" rx="8" ry="8" width="331" height="1" />
          <circle cx="310" cy="35" r="20" />
        </ContentLoader>
      )}
    </>
  )
}

TopActiveClientsLoader.defaultProps = {
  isMobile: false,
}

export default TopActiveClientsLoader
