import React, { Suspense, lazy } from 'react'
import { Redirect, Switch } from 'react-router-dom'

import PrivateRoute from 'routes/PrivateRoute'
import UserRoute from 'routes/UserRoute'
import map from 'lodash/map'

const AuthRoutes = lazy(() => import('pages/Auth'))
const AdminRoutes = lazy(() => import('pages/Admin'))
const OwnerRoutes = lazy(() => import('pages/Owner'))
const AdvisorRoutes = lazy(() => import('pages/Advisor'))
const WelcomeRoutes = lazy(() => import('pages/Welcome'))
const AssessmentRoutes = lazy(() => import('pages/Assessment'))
const PrivacyAndPolicyRoutes = lazy(() => import('pages/PrivacyAndPolicy'))
const TermsAndConditionRoute = lazy(() => import('pages/TermsAndCondition'))

const RoutesHOC = (routes: any, DEFAULT_PATH: any) => {
  return function Component() {
    return (
      <Suspense fallback={<div></div>}>
        <Switch>
          {map(routes, (route) => {
            if (route.isPrivate) {
              return (
                <PrivateRoute
                  key={route.title}
                  title={route.title}
                  path={route.path}
                  component={route.component}
                  defaultPath={DEFAULT_PATH}
                />
              )
            }
            return (
              <UserRoute
                key={route.title}
                title={route.title}
                defaultPath={DEFAULT_PATH}
                path={route.path}
                component={route.component}
              />
            )
          })}
          <Redirect to={DEFAULT_PATH} />
        </Switch>
      </Suspense>
    )
  }
}

export const routes = {
  AUTH: {
    path: '/auth',
    title: 'Login',
    component: AuthRoutes,
    isPrivate: false,
  },
  PRIVACY: {
    path: '/privacy-policy',
    title: 'Privacy Policy',
    component: PrivacyAndPolicyRoutes,
    isPrivate: false,
  },
  TERMS: {
    path: '/terms-condition',
    title: 'Terms and condition',
    component: TermsAndConditionRoute,
    isPrivate: false,
  },
  ADMIN: {
    path: '/admin',
    title: 'Admin',
    component: AdminRoutes,
    isPrivate: true,
  },
  BUSINESS_OWNER: {
    path: '/owner',
    title: 'Owner',
    component: OwnerRoutes,
    isPrivate: true,
  },
  BSO_ADVISOR: {
    path: '/advisor',
    title: 'Advisor',
    component: AdvisorRoutes,
    isPrivate: true,
  },
  WELCOME: {
    path: '/welcome',
    title: 'Welcome',
    component: WelcomeRoutes,
    isPrivate: true,
  },
  ASSESSMENT: {
    path: '/assessment',
    title: 'Assessment',
    component: AssessmentRoutes,
    isPrivate: true,
  },
}

const AppRoutes = RoutesHOC(routes, '/auth')
export default AppRoutes

export const DEFAULT_PATH = '/auth'
export const USER_LANDING_PAGE = '/'
