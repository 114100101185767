import React from 'react'

const UserAccountHeader = ({ children }) => {
  return (
    <div className="flex justify-center w-full">
      <div className="w-full h-full flex flex-col ">{children}</div>
    </div>
  )
}

export default UserAccountHeader
