import React from 'react'
import { CrossIcon, TickIcon } from 'components/Common/SvgIcons'

interface IToggleButtonProps {
  enabled: boolean
  toggleButtonHandler: () => void
  label?: string
}

const ToggleButton = ({ enabled, toggleButtonHandler, label }: IToggleButtonProps) => {
  return (
    <div className="relative">
      {!enabled && (
        <span className={`absolute top-[7px] z-10 left-[7px]`}>
          <CrossIcon className={'h-[10px] w-[10px] fill-zinc'} />
        </span>
      )}
      {enabled && (
        <span className={`absolute top-[8px] z-10 left-[27px]`}>
          <TickIcon className={'h-[10px] w-[10px] fill-zinc'} />
        </span>
      )}
      <label className="inline-flex relative items-center mr-5 cursor-pointer">
        <input type="checkbox" className="sr-only peer" checked={enabled} readOnly />
        <div
          onClick={() => {
            toggleButtonHandler()
          }}
          className={`${
            enabled ? 'bg-primary' : 'bg-primary-disabled'
          }  w-11 h-6 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600`}
        ></div>
        {label ? (
          <span className="ml-2 text-sm text-gray-900">{label}</span>
        ) : // todo: move this out of a common component
        enabled ? (
          <span className="ml-2 text-sm text-gray-900">Admin On</span>
        ) : null}
      </label>
    </div>
  )
}

export default ToggleButton
