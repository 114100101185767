module.exports = {
  primary: {
    DEFAULT: '#5BBAB9',
    brand: '#3BD5D3',
    active: '#35BFBE',
    disabled: '#D4D4D8',
    text: '#3F3F46',
    focus: 'rgba(91, 186, 185, 0.5)',
    outline: '#D4D4D8',
    error: '#DC2626',
    card: '#E2E8F0',
  },
  secondary: {
    DEFAULT: '#FAFAFA',
    text: '#D4D4D8',
  },
  background: {
    DEFAULT: '#E5E5E5',
  },
  black: {
    DEFAULT: '#000000',
    light: '#18181B',
  },
  white: {
    DEFAULT: '#FFFFFF',
  },
  transparent: {
    DEFAULT: 'transparent',
  },
  zinc: {
    DEFAULT: '#3F3F46',
  },
  gerberared: {
    DEFAULT: '#F45E13',
  },
  silver: {
    darkest: '#7c92a9',
    darker: '#888888',
    dark: '#A1A1AA',
    DEFAULT: '#71717A',
    light: '#585757',
    lighter: '#D9D9D9',
  },
  grey: {
    darkest: '#969696',
    darker: '#C4C4C4',
    dark: '#52525B',
    DEFAULT: '#27272A',
    light: '#F7F7F7',
    lighter: '#e8e8e8',
    lightest: '#dddddd',
    lightest2x: '#F1E6FD',
    lighter3x: '#F4F4F5',
  },
  blue: {
    darkest: '#2F035D',
    darker: '#0073C6',
    dark: '#0085ff',
    DEFAULT: '#3B82F6',
    light: '#03a1ff',
    lighter: '#12b8f1',
    lightest: '#d0f1fc',
    curious: '#52B4F9',
  },
  cream: {
    dark: '#F4F4F5',
    darker: '#f3f3f3',
    DEFAULT: '#E5F3FF',
    light: '#e6ebee',
    lighter: '#E5E5E5',
  },
  purple: {
    darker: '#03337C',
    dark: '#111827',
    darkest: '7B61FF2B',
    DEFAULT: '#7B61FF',
    light: '#7600ED',
    lighter: '#5E06B9',
    indigo: '#470C84',
  },
  sky: {
    light: '#E4FEF8',
    DEFAULT: '#DCF0FE',
    dark: '#CDD6E5',
    darker: '#FFE5DE',
  },
  brown: {
    DEFAULT: '#B0B0B0',
  },
  violet: {
    DEFAULT: '#1B1A57',
  },
  green: {
    light: '#3B7D6E',
    DEFAULT: '#059669',
    cyan: '#059669',
  },
  zumthor: {
    DEFAULT: '#EAF2FF',
  },
  bleachedlilac: {
    DEFAULT: '#F3E7FF',
  },
  aqua: {
    DEFAULT: '#77FADB',
  },
  yellow: {
    lighter: '#FFFF8A',
    DEFAULT: '#FFF4CC',
    dark: '#FFA500',
    darker: '#FFC900',
  },
  calander: {
    intro: {
      bg: '#E5F3FF',
      text: '#3B82F6',
    },
    review: {
      bg: '#7B61FF2B',
      text: '#7B61FF',
    },
    playbook: {
      bg: '#F4F4F5',
      text: '#52525B',
    },
  },
}
