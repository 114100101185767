import {
  UPDATE_PLAY_STEP,
  GET_PLAY,
  AUTHORIZE_CONNECTOR,
  UPDATE_PLAY_STAGE,
  GET_PLAY_ASSESSMENT_RESPONSE,
  RESET_PLAY,
  HANDLE_COMPLETE_PLAY,
  GET_CONNECTED_PLAY,
  SAVE_PLAY_DATA,
} from 'store/types'

export const updatePlayStepId = {
  STARTED: (payload: any) => ({ type: UPDATE_PLAY_STEP.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: UPDATE_PLAY_STEP.FULLFILLED, payload }),
  REJECTED: () => ({ type: UPDATE_PLAY_STEP.REJECTED }),
}

export const getPlayAction = {
  STARTED: (payload: any) => ({ type: GET_PLAY.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_PLAY.FULLFILLED, payload }),
  REJECTED: () => ({ type: GET_PLAY.REJECTED }),
}

export const authorizeConnectorAction = {
  STARTED: (payload) => ({
    type: AUTHORIZE_CONNECTOR.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: AUTHORIZE_CONNECTOR.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: AUTHORIZE_CONNECTOR.REJECTED, payload: error }),
}

export const updatePlayStageAction = {
  STARTED: (payload) => ({
    type: UPDATE_PLAY_STAGE.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: UPDATE_PLAY_STAGE.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: UPDATE_PLAY_STAGE.REJECTED, payload: error }),
}

export const getPlayAssessmentResponseAction = {
  STARTED: (payload) => ({
    type: GET_PLAY_ASSESSMENT_RESPONSE.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: GET_PLAY_ASSESSMENT_RESPONSE.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_PLAY_ASSESSMENT_RESPONSE.REJECTED, payload: error }),
}

export const resetPlayAction = () => ({
  type: RESET_PLAY,
})

export const handleCompletePlayAction = {
  STARTED: (payload: any) => ({ type: HANDLE_COMPLETE_PLAY.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: HANDLE_COMPLETE_PLAY.FULLFILLED, payload }),
  REJECTED: () => ({ type: HANDLE_COMPLETE_PLAY.REJECTED }),
}

export const getConnectedPlayAction = {
  STARTED: (payload: any) => ({ type: GET_CONNECTED_PLAY.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_CONNECTED_PLAY.FULLFILLED, payload }),
  REJECTED: () => ({ type: GET_CONNECTED_PLAY.REJECTED }),
}
export const savePlayDataAction = {
  STARTED: (payload: any) => ({ type: SAVE_PLAY_DATA.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: SAVE_PLAY_DATA.FULLFILLED, payload }),
  REJECTED: () => ({ type: SAVE_PLAY_DATA.REJECTED }),
}
