import React from 'react'

const EditProfileDetail = ({ title, children }) => {
  return (
    <div className="inline-flex">
      <div className="w-[24.57px] h-[19.66px]">{children}</div>
      <div>
        <p className="ml-5">{title}</p>
      </div>
    </div>
  )
}

export default EditProfileDetail
