import React from 'react'

function PlusIcon({ className }) {
  className = { className }
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3574 8.99967C17.3574 9.355 17.0681 9.64571 16.7145 9.64571H9.64307V16.7521C9.64307 17.1092 9.35555 17.3986 9.00021 17.3986C8.64487 17.3986 8.35735 17.1075 8.35735 16.7521V9.64571H1.28592C0.930584 9.64571 0.643066 9.35701 0.643066 9.00008C0.643066 8.64435 0.930745 8.35363 1.28592 8.35363H8.35735V1.2472C8.35735 0.8901 8.64487 0.601562 9.00021 0.601562C9.35555 0.601562 9.64307 0.890262 9.64307 1.2472V8.35363H16.7145C17.0681 8.35363 17.3574 8.64435 17.3574 8.99967Z"
        fill="#3F3F46"
      />
    </svg>
  )
}
export default PlusIcon
