import React, { useEffect, useRef } from 'react'
import map from 'lodash/map'
import size from 'lodash/size'
import Message from 'components/NotificationPanel/Message'
import { NotficationIcon } from 'components/Common/SvgIcons'
import { CrossIcon } from 'components/Common/SvgIcons'
import { useOutsideClick } from 'utils/clickOutside'
import CircularLoading from 'components/Common/CircularLoading'
import { NotficationPanel, ListItem } from 'components/NotificationPanel/style'
import { IUSER_NOTIFICATION_STATUS } from 'components/NotificationPanel/types'

const NotificationPanel = ({
  handleClose,
  notifications,
  isLoading,
  totalCount,
  getUserNoticationsAction,
  getUnreadNoticationsAction,
  updateUserNoticationsAction,
  deleteUserNoticationsAction,
}) => {
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, handleClose)
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      getUserNoticationsAction({ viewMore: true })
    }
  }

  useEffect(() => {
    getUserNoticationsAction()
    return () => {
      getUnreadNoticationsAction()
      updateUserNoticationsAction()
    }
  }, [getUserNoticationsAction, getUnreadNoticationsAction, updateUserNoticationsAction])

  return (
    <NotficationPanel ref={wrapperRef}>
      <div className="xs:block sm:hidden flex justify-between gap-2 items-center py-[26px] px-[36px] border-b border-primary-outline">
        <div className="flex justify-between gap-2 items-center">
          <div className="font-primary font-normal leading-7 text-primary-text text-xl">
            Notifications
          </div>
          <div className="cursor-pointer " onClick={handleClose}>
            <CrossIcon className={'cursor-pointer'} />{' '}
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-2 items-center xs:py-[24px]  sm:py-[27px] xs:px-[36px] sm:px-[40px]">
        <div className="font-primary font-normal leading-8 text-primary-text text-2xl inline-flex items-center">
          <span className="xs:hidden sm:inline-block mr-1"> Notifications</span>({totalCount})
          {isLoading && <CircularLoading className="ml-1" />}
        </div>
        {size(notifications) > 0 && (
          <div
            onClick={deleteUserNoticationsAction}
            className="font-primary font-bold leading-5 text-primary text-sm cursor-pointer hover:text-primary-brand"
          >
            Clear All{' '}
          </div>
        )}
      </div>
      <ListItem className=" overflow-auto" id="notficationList" onScroll={handleScroll}>
        {map(notifications, (val) => (
          <li
            key={val.id}
            className="flex xm:items-baseline sm:items-center justify-between xs:gap-[24px] sm:gap-[34px] xs:p-[15px] sm:p-[18px] hover:bg-white cursor-pointer"
          >
            <div className="flex xs:gap-[15px] sm:gap-[20px] items-center">
              <div className="relative">
                <NotficationIcon className={'block width-full max-w-[50px] height-[50px]'} />
                {val.status === IUSER_NOTIFICATION_STATUS.UNREAD && (
                  <div className="absolute top-[1px] right-[3px] w-[10px] h-[10px] rounded-full border border-[white] bg-gerberared"></div>
                )}
              </div>
              <Message notification={val} />
            </div>
            <div
              onClick={() => deleteUserNoticationsAction({ id: val.id })}
              className="font-primary font-bold leading-5 text-primary text-sm cursor-pointer hover:text-primary-brand xs:pt-2 sm:pt-0  "
            >
              Clear
            </div>
          </li>
        ))}
      </ListItem>
    </NotficationPanel>
  )
}

export default NotificationPanel
