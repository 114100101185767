import React from 'react'

import { ArrowRightIcon } from 'components/Common/SvgIcons'
import ArrowLeftIcon from 'components/Common/SvgIcons/ArrowLeftIcon'
import UpArrowIcon from 'components/Common/SvgIcons/UpArrowIcon'
import DownArrowIcon from 'components/Common/SvgIcons/DownArrowIcon'

const TablePagination = ({
  type,
  noOfItemsPerPage,
  currentPage,
  totalPages,
  totalItems,
  startIndex,
  endIndex,
  moveNextHandler,
  movePreviousHandler,
  updateNoOfItemPerPageHandler,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-row border border-grey-darker divide-x divide-grey-darker font-primary">
        <div className="w-[340px] p-3  whitespace-nowrap text-sm">
          <div className="flex flex-row justify-between">
            <div> {type}s per page </div>
            <div className="flex gap-2">
              <div> {noOfItemsPerPage} </div>
              <div className="flex flex-col gap-1">
                <div onClick={() => updateNoOfItemPerPageHandler(noOfItemsPerPage - 1)}>
                  <UpArrowIcon />
                </div>
                <div onClick={() => updateNoOfItemPerPageHandler(noOfItemsPerPage + 1)}>
                  <DownArrowIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 w-full  whitespace-nowrap text-sm">
          {startIndex + 1} - {endIndex} of {totalItems} {type}s
        </div>
        <div className="w-[200px] p-3  whitespace-nowrap text-sm">
          {currentPage} of {totalPages} pages
        </div>
        <div className="min-w-[100px] p-2  whitespace-nowrap">
          <div className="flex flex-col">
            <div className="self-center" onClick={movePreviousHandler}>
              <ArrowLeftIcon
                className={`${
                  currentPage <= totalPages && currentPage !== 1
                    ? 'fill-zinc cursor-pointer'
                    : 'fill-primary-outline'
                } `}
              />
            </div>
          </div>
        </div>
        <div className="min-w-[100px] p-2  whitespace-nowrap">
          <div className="flex flex-col">
            <div className="self-center" onClick={moveNextHandler}>
              <ArrowRightIcon
                className={`${
                  currentPage < totalPages ? 'fill-zinc cursor-pointer' : 'fill-primary-outline'
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TablePagination
