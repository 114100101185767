import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import _debounce from 'lodash.debounce'
import type { UserResponse } from 'stream-chat'
import { MessageToSend, MessageInput, Window, Thread, useChatContext } from 'stream-chat-react'
import { MessagingInput } from 'components/Chat/components'
import PlusIcon from 'components/Common/SvgIcons/PlusIcon'
import SelectField from 'components/Common/FormFields/SelectField'
import { getUserName } from 'components/Chat/helper'
import type { StreamChatGenerics } from 'components/Chat/types'
import { XButtonBackground } from 'components/Chat/assets'
import { getChatUsersHelper } from 'utils/helper'
import './CreateChannel.css'
import { CrossIcon } from 'components/Common/SvgIcons'

export type ChannelInnerProps = {
  toggleMobile: () => void
  theme: string
  showChannelPreview: any
  showChannelCreate: any
  setShowDesktopUserProfile: (showDesktopUserProfile) => void
  setShowChannelPreview: (showChannelPreview) => void
  showDesktopUserProfile
}

const CreateChannel = ({
  onClose,
  setShowChannelPreview,
  showChannelPreview,
  setShowChannelCreate,
  showChannelCreate,
  chatUsers,
}) => {
  const { tenantId } = useParams()
  const { client, setActiveChannel }: any = useChatContext<StreamChatGenerics>()

  const [searching, setSearching] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<UserResponse<StreamChatGenerics>[]>([])
  const [users, setUsers] = useState<UserResponse<StreamChatGenerics>[]>([])
  const [showSelect, setShowSelect] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (chatUsers) {
      findUsersDebounce()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatUsers, users])

  const findUsers = async () => {
    if (searching) return
    setSearching(true)

    try {
      let response: any = await client.queryUsers(
        {
          id: { $ne: client.userID as string },
          $and: [{ teams: { $contains: tenantId } }],
        },
        { id: 1 },
        { limit: 100 }
      )

      let filterUser: any = response.users.filter((user) => user?.firstName || user?.lastName)
      response = filterUser

      let chatUsersList = getChatUsersHelper({
        all_users: response,
        chatUsers,
      })

      response = chatUsersList

      response.forEach((user) => {
        user.name = {
          firstName: user?.firstName || user?.name?.firstName,
          lastName: user?.lastName || user?.name?.lastName,
        }
      })

      if (response.length) {
        setUsers(response)
      }
    } catch (error) {
      console.log({ error })
    }

    setSearching(false)
  }

  const findUsersDebounce = _debounce(findUsers, 100, {
    trailing: true,
  })

  const removeUser = (user: UserResponse<StreamChatGenerics>) => {
    const newUsers = selectedUsers.filter((item) => item.id !== user.id)
    setSelectedUsers(newUsers)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const addUser = (addedUser: UserResponse<StreamChatGenerics>) => {
    const isAlreadyAdded = selectedUsers.find((user) => user.id === addedUser.id)
    if (isAlreadyAdded) return

    setSelectedUsers([...selectedUsers, addedUser])
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const createChannelHandler = async (message: MessageToSend<StreamChatGenerics>) => {
    try {
      const selectedUsersIds = selectedUsers.map((u) => u.id)

      //create channel
      const conversation = await client.channel('messaging', {
        members: [...selectedUsersIds, client.userID],
        team: tenantId,
      })
      await conversation.create()

      //  send message
      const newMessage = { text: message.text }
      await conversation.sendMessage(newMessage)
      await conversation.watch()

      setActiveChannel?.(conversation)
      setSelectedUsers([])
      setUsers([])
      onClose()
    } catch (err: any) {
      console.log('error: ', err.message)
    }
  }

  return (
    <div className="messaging-create-channel">
      <Window>
        <div className="relative h-full">
          <div className="pt-5 pl-5 xs:pr-3">
            {/* <header> */}
            <div className="text-xl font-semibold"> New Message</div>
            <div className="messaging-create-channel__left !items-center	">
              <div className="text-sm font-normal uppercase mt-3 mr-3">To: </div>
              <div className="users-input-container inline-flex">
                {
                  <div className="messaging-create-channel__users ">
                    {!!selectedUsers?.length &&
                      selectedUsers.map((user) => (
                        <div
                          className="messaging-create-channel__user !bg-primary/50"
                          onClick={() => removeUser(user)}
                          key={user.id}
                        >
                          <div className="messaging-create-channel__user-text !text-primary-text !text-lg !font-semibold !font-primary">
                            {getUserName(user.name)}
                          </div>
                          <CrossIcon className={'fill-zinc h-[15px] w-[15px]'} />
                        </div>
                      ))}

                    {showSelect || selectedUsers.length === 0 ? (
                      <div style={{ width: '300px' }}>
                        <SelectField
                          name={''}
                          options={users.map((user: any) => ({
                            label: getUserName(user?.name),
                            value: user.id,
                          }))}
                          onChange={(value) => {
                            addUser(
                              users.find(
                                (user: any) => user.id === value
                              ) as UserResponse<StreamChatGenerics>
                            )
                            setShowSelect(false)
                          }}
                          isSearchable={true}
                        />
                      </div>
                    ) : (
                      <div className="mt-2 ml-5" onClick={() => setShowSelect(true)}>
                        <PlusIcon className="fill-primary h-[26px] w-[26px]" />
                      </div>
                    )}
                  </div>
                }
              </div>
              <div
                className="mdl:hidden"
                onClick={() => {
                  setShowChannelPreview(!showChannelPreview)
                  setShowChannelCreate(!showChannelCreate)
                }}
              >
                <XButtonBackground />
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 w-full">
            <MessageInput focus overrideSubmitHandler={createChannelHandler} />
          </div>
        </div>
      </Window>
      <Thread Input={MessagingInput} />
    </div>
  )
}

export default CreateChannel
