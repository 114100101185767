import React, { Fragment } from 'react'
import ButtonField from 'components/Common/FormFields/ButtonField'
import Highlighter from 'react-highlight-words'

const Option = (props) => {
  const { addOwner, data } = props
  return (
    <Fragment>
      <div className="grid grid-cols-7 py-1 hover:!bg-cream-dark active:!bg-cream-dark px-3">
        <div className="col-span-5 font-primary text-base font-normal text-primary-text mr-1">
          <label className="block">
            <Highlighter
              highlightStyle={{ fontWeight: 'bold', backgroundColor: 'transparent' }}
              searchWords={[props.selectProps.inputValue]}
              autoEscape={true}
              textToHighlight={data.label}
            />
          </label>
          <label className="block break-all">
            <Highlighter
              highlightStyle={{ fontWeight: 'bold', backgroundColor: 'transparent' }}
              searchWords={[props.selectProps.inputValue]}
              autoEscape={true}
              textToHighlight={data.email}
            />
          </label>
        </div>
        <div className="col-span-2 self-center">
          <ButtonField
            name={'Add Client'}
            variant="primary"
            className={'text-xs rounded-[53px] !px-[9px] !py-[3px]'}
            onClick={() => addOwner(data)}
            type="button"
          />
        </div>
      </div>
    </Fragment>
  )
}

export default Option
