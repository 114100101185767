import React from 'react'
import ContentLoader from 'react-content-loader'

import { colorsPallete } from 'config'

const EditAdvisorUserProfileLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 115"
        backgroundColor={`${colorsPallete.cream.dark}`}
        foregroundColor={`${colorsPallete.grey.lighter}`}
      >
        <rect x="15" y="0" rx="2" ry="2" width="50" height="10" />
        <rect x="225" y="0" rx="2" ry="2" width="50" height="10" />
        <rect x="15" y="18" rx="2" ry="2" width="205" height="25" />
        <rect x="225" y="18" rx="2" ry="2" width="205" height="25" />

        <rect x="15" y="55" rx="2" ry="2" width="50" height="10" />
        <rect x="225" y="55" rx="2" ry="2" width="50" height="10" />
        <rect x="15" y="75" rx="2" ry="2" width="205" height="25" />
        <rect x="225" y="75" rx="2" ry="2" width="205" height="25" />
      </ContentLoader>
    </>
  )
}

EditAdvisorUserProfileLoader.defaultProps = {
  isMobile: false,
}

export default EditAdvisorUserProfileLoader
