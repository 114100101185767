import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import UserApi from 'api/user'

import UserManagement from 'api/userManagement'
import { FORM, formKeys, USER_MANAGEMENT_FILTER } from 'config'
import CalendarApi from 'api/calendar'
import { isSameObject, getRoleFromPath } from 'utils/helper'
import { ROLES } from 'config/enums'
import {
  ADD_USER_TO_GROUP,
  ADVISORS_ACCOUNT_FILTER,
  BUSINESSES_ACCOUNT_FILTER,
  ENABLE_DISABLE_USER,
  GET_ADVISORS,
  GET_ADVISORS_ACCOUNT_DETAILS,
  GET_BUSINESSES_ACCOUNT_DETAILS,
  GET_BUSINESS_AND_ASSESSMENT_RESPONSE,
  GET_USER_PROFILE_AND_ADVISOR,
  UPDATE_OWNER_PROFILE,
  UPDATE_ADVISOR_PROFILE,
} from 'store/types'
import {
  advisorAccountFilterAction,
  businessAccountFilterAction,
  enableDisableUserAction,
  getAdvisorsAccountDetailsAction,
  getAdvisorsAction,
  getBusinessesAccountDetailsAction,
  updateOwnerProfileAction,
  updateAdvisorProfileAction,
  openUserManagementEditModalAction,
  checkUserProfileAction,
  checkBusinessProfileAction,
} from 'store/actions/userManagement'
import AssessmentApi from 'api/assessment'

import hookForms from 'utils/hookForms'
import { getBusinessProfileAssessmentsSelector } from 'store/selectors/owner'
import { getAssessmentResponse } from 'utils/helper'
import { getBoAdvisorAction } from 'store/actions/calendar'
import { getUserBusinessProfileAction, getUserProfileAction } from 'store/actions/user'
import { get, pickBy, sortBy } from 'lodash'
import { getBoAdvisorsList } from 'store/selectors/userManagement'
import {
  getAssessmentQuestionAction,
  setActiveQuestion,
} from 'store/actions/owner/initialAssessment'
import { setFormData, setProfileAction } from 'store/actions/form'

/* ----- BUSINESSES ------- */

function* getBusinessesAccountDetails() {
  try {
    /* ------ ALL_DATA ------- */
    const user = yield select((state) => state.user.user)
    const tenantId = user.tenantId[0]

    let payload: any = {}
    payload.tenantId = tenantId
    payload.isPaginated = false

    let userResponse = yield call(UserManagement.getBusinesses, payload)
    userResponse = JSON.parse(userResponse?.getBusinesses?.data)

    // let userResponse = businessAccountDetails //dummyData

    if (userResponse) {
      yield put(getBusinessesAccountDetailsAction.FULLFILLED(userResponse))
    } else {
      yield put(getBusinessesAccountDetailsAction.FULLFILLED(null))
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

/* ----- ADVISORS ------- */
function* getBusinessesAdvisorsDetails() {
  try {
    /* ------ ALL_DATA ------- */
    const user = yield select((state) => state.user.user)
    const tenantId = user.tenantId[0]

    let payload: any = {}
    payload.tenantId = tenantId
    payload.isPaginated = false

    let userResponse = yield call(UserManagement.getAdvisors, payload)
    userResponse = JSON.parse(userResponse?.getAdvisors?.data)

    if (userResponse) {
      yield put(getAdvisorsAccountDetailsAction.FULLFILLED(userResponse))
    } else {
      yield put(getAdvisorsAccountDetailsAction.FULLFILLED(null))
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

function* addUserToGroup(action) {
  try {
    let { userId } = action.payload
    yield call(UserApi.addUserToGroup, {
      role: 'BSO_ADMIN',
      userName: userId,
      remove: action?.payload?.remove ? action?.payload?.remove : undefined,
    })

    /* ------ ALL_DATA  (refetch advisors) ------- */
    const user = yield select((state) => state.user.user)
    const tenantId = user.tenantId[0]

    let payload: any = {}
    payload.tenantId = tenantId
    payload.isPaginated = false

    let userResponse = yield call(UserManagement.getAdvisors, payload)
    userResponse = JSON.parse(userResponse?.getAdvisors?.data)

    if (userResponse) {
      yield put(getAdvisorsAccountDetailsAction.FULLFILLED(userResponse))
    } else {
      yield put(getAdvisorsAccountDetailsAction.FULLFILLED(null))
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

function* getAdvisors(action) {
  try {
    const user = yield select((state) => state.user.user)
    const tenantId = user?.tenantId[0]

    let payload: any = action?.payload
    payload.tenantId = tenantId

    let userResponse = yield call(UserManagement.getAdvisors, payload)
    userResponse = JSON.parse(userResponse?.getAdvisors?.data)

    if (userResponse) {
      yield put(getAdvisorsAction.FULLFILLED(userResponse))
    } else {
      yield put(getAdvisorsAction.FULLFILLED(null))
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

const findAdvisor = ({ advisorsList, advisorName }) => {
  let isAdvisorFound = false
  if (advisorsList && advisorsList.length > 0) {
    advisorsList.some((advisorObj: any) => {
      if (advisorObj?.name?.trim() === advisorName) {
        isAdvisorFound = true
        return true
      } else {
        return false
      }
    })
  }

  if (isAdvisorFound) {
    return true
  } else {
    return false
  }
}

// BusinessAccount (FILTER)
function* businessAccountFilter(action) {
  try {
    /* ---------- START: Filter (FRONTEND) ----------- */

    const businessAccountFilter = yield select(
      (state) => state.userManagement.businessAccountFilter
    )
    let payload: any = {}
    if (action?.payload?.type === USER_MANAGEMENT_FILTER.ADVISOR) {
      payload = {
        ...businessAccountFilter,
        advisor: action?.payload?.value === 'all' ? '' : action?.payload?.value,
      }
    } else if (action?.payload?.type === USER_MANAGEMENT_FILTER.STATUS) {
      payload = {
        ...businessAccountFilter,
        status: action?.payload?.value === 'all' ? '' : action?.payload?.value,
      }
    } else if (action?.payload?.type === USER_MANAGEMENT_FILTER.SEARCH_QUERY) {
      payload = { ...businessAccountFilter, searchQuery: action?.payload?.value || '' }
    }
    yield put(businessAccountFilterAction.FULLFILLED(payload))

    const user = yield select((state) => state.user.user)
    const tenantId = user.tenantId[0]

    let apiPayload: any = {}
    apiPayload.tenantId = tenantId
    apiPayload.isPaginated = false
    let businessesResponse = yield call(UserManagement.getBusinesses, apiPayload)
    businessesResponse = JSON.parse(businessesResponse?.getBusinesses?.data)

    // let businessesResponse = businessAccountDetails //dummyData

    let list: any = []
    let updatedList: any = []
    let lastEvaluatedKey = ''
    list = businessesResponse?.list
    lastEvaluatedKey = businessesResponse?.lastEvaluatedKey

    let searchQuery = ''
    let status = ''
    let advisor = ''
    searchQuery = payload?.searchQuery?.toLowerCase().replace(/\s/g, '')
    status = payload?.status?.toLowerCase().trim()
    advisor = payload?.advisor.trim()

    if (searchQuery && status && advisor) {
      updatedList = list?.filter((obj) => {
        let business = ''
        let name = ''
        name = obj?.name?.toLowerCase().replace(/\s/g, '')
        business = obj?.businessName?.toLowerCase().replace(/\s/g, '')

        if (
          obj?.status?.trim() === status &&
          (business?.includes(searchQuery) || name?.includes(searchQuery)) &&
          findAdvisor({ advisorsList: obj?.advisors, advisorName: advisor })
        ) {
          return obj
        } else {
          return false
        }
      })
    } else if (status && advisor) {
      updatedList = list?.filter((obj) => {
        if (
          obj?.status?.trim() === status &&
          findAdvisor({ advisorsList: obj?.advisors, advisorName: advisor })
        ) {
          return obj
        } else {
          return false
        }
      })
    } else if (status && searchQuery) {
      updatedList = list?.filter((obj) => {
        if (
          obj?.status?.trim() === status &&
          (obj?.name?.toLowerCase()?.replace(/\s/g, '').includes(searchQuery) ||
            obj?.businessName?.toLowerCase()?.replace(/\s/g, '').includes(searchQuery))
        ) {
          return obj
        } else {
          return false
        }
      })
    } else if (advisor && searchQuery) {
      updatedList = list?.filter((obj) => {
        if (
          findAdvisor({ advisorsList: obj?.advisors, advisorName: advisor }) &&
          (obj?.name?.toLowerCase().replace(/\s/g, '').includes(searchQuery) ||
            obj?.businessName?.toLowerCase().replace(/\s/g, '').includes(searchQuery))
        ) {
          return obj
        } else {
          return false
        }
      })
    } else if (searchQuery) {
      updatedList = list?.filter((obj) => {
        let business = ''
        let name = ''
        name = obj?.name?.toLowerCase().replace(/\s/g, '')
        business = obj?.businessName?.toLowerCase().replace(/\s/g, '')

        if (business?.includes(searchQuery) || name?.includes(searchQuery)) {
          return obj
        } else {
          return false
        }
      })
    } else if (status) {
      updatedList = list?.filter((obj) => {
        if (obj?.status?.trim() === status) {
          return obj
        } else {
          return false
        }
      })
    } else if (advisor) {
      updatedList = list?.filter((obj) => {
        let isAdvisorFound = false
        let advisorsList = []
        advisorsList = obj?.advisors

        isAdvisorFound = findAdvisor({ advisorsList, advisorName: advisor })

        if (isAdvisorFound) {
          return obj
        } else {
          return false
        }
      })
    } else {
      updatedList = list
    }

    if (updatedList?.length === 0) {
      yield put(getBusinessesAccountDetailsAction.FULLFILLED(null))
    } else {
      yield put(
        getBusinessesAccountDetailsAction.FULLFILLED({ list: updatedList, lastEvaluatedKey })
      )
    }
  } catch (error) {
    console.log('error: ', error)
  }
}

function* advisorAccountFilter(action) {
  try {
    const advisorsAccountFilter = yield select(
      (state) => state.userManagement.advisorsAccountFilter
    )
    let payload: any = {}
    if (action?.payload?.type === USER_MANAGEMENT_FILTER.STATUS) {
      payload = {
        ...advisorsAccountFilter,
        status: action?.payload?.value === 'all' ? '' : action?.payload?.value,
      }
    } else if (action?.payload?.type === USER_MANAGEMENT_FILTER.SEARCH_QUERY) {
      payload = { ...advisorsAccountFilter, searchQuery: action?.payload?.value || '' }
    }
    yield put(advisorAccountFilterAction.FULLFILLED(payload))

    const user = yield select((state) => state.user.user)
    const tenantId = user.tenantId[0]
    let apiPayload: any = {}
    apiPayload.tenantId = tenantId
    apiPayload.isPaginated = false
    let advisorsAccountDetails = yield call(UserManagement.getAdvisors, apiPayload)
    advisorsAccountDetails = JSON.parse(advisorsAccountDetails?.getAdvisors?.data)

    let list: any = []
    let updatedList: any = []
    let lastEvaluatedKey = ''

    list = advisorsAccountDetails?.list
    lastEvaluatedKey = advisorsAccountDetails?.lastEvaluatedKey

    // filter record & save it in redux
    let searchQuery = payload?.searchQuery?.toLowerCase().trim()
    let status = payload?.status?.toLowerCase().trim()

    if (searchQuery && status) {
      //searchQueryFilter && filter

      updatedList = list.filter((obj) => {
        let firstName = ''
        let lastName = ''
        firstName = obj?.firstName?.toLowerCase()
        lastName = obj?.lastName?.toLowerCase()
        let name = firstName + lastName

        name = name.replace(/\s/g, '')
        searchQuery = searchQuery.replace(/\s/g, '')

        if (obj.status?.toLowerCase().trim() === status && name?.includes(searchQuery)) {
          return obj
        } else {
          return false
        }
      })
    } else if (searchQuery) {
      updatedList = list.filter((obj) => {
        let firstName = ''
        let lastName = ''
        firstName = obj?.firstName?.toLowerCase()
        lastName = obj?.lastName?.toLowerCase()

        let name = firstName + lastName

        name = name.replace(/\s/g, '')
        searchQuery = searchQuery.replace(/\s/g, '')
        if (name?.includes(searchQuery)) {
          return obj
        } else {
          return false
        }
      })
    } else if (status) {
      updatedList = list.filter((obj) => {
        if (obj.status.toLowerCase().trim() === status) {
          return obj
        } else {
          return false
        }
      })
    } else {
      updatedList = list
    }

    if (updatedList.length === 0) {
      yield put(getAdvisorsAccountDetailsAction.FULLFILLED(null))
    } else {
      yield put(getAdvisorsAccountDetailsAction.FULLFILLED({ list: updatedList, lastEvaluatedKey }))
    }
    /* ----------------------------------------- */
  } catch (error) {
    console.log('error: ', error)
  }
}

// BUSINESS_PROFILE
function* updateOwnerProfile(action) {
  const { userId, tenantId } = action.payload
  const form = yield select((state) => state.form)
  try {
    const userProfileForm = hookForms.getForm(FORM.USER_PROFILE_FORM)
    const userManagementBusinessForm = hookForms.getForm(FORM.USER_MANAGEMENT_BUSINESS_FORM)
    const userProfileFormValues = userProfileForm.getValues()
    const userManagementBusinessFormValues = userManagementBusinessForm.getValues()
    const businessProfileFormValues = pick(userManagementBusinessFormValues, [
      'businessName',
      'yearsInBusiness',
    ])
    const initialAssessmentFormValues = omit(
      userManagementBusinessFormValues,
      Object.keys(businessProfileFormValues)
    )

    const apiCalls: any = []
    if (!isSameObject(userProfileFormValues, form.USER_PROFILE_FORM))
      apiCalls.push(
        call(UserApi.updateUserProfile, {
          ...userProfileFormValues,
          userId,
          tenantId,
          advisors: JSON.stringify(userProfileFormValues.advisors),
        })
      )
    if (!isSameObject(userManagementBusinessFormValues, form.USER_MANAGEMENT_BUSINESS_FORM))
      apiCalls.push(
        call(UserApi.updateBusinessProfile, {
          ...businessProfileFormValues,
          id: userId,
          tenantId,
        })
      )
    if (apiCalls.length > 0) yield all(apiCalls)
    let assessmentResponse: any = []
    const assessment = yield select(getBusinessProfileAssessmentsSelector)

    assessmentResponse = getAssessmentResponse({
      keys: initialAssessmentFormValues,
      assessment_questions: assessment,
    })
    yield call(AssessmentApi.saveAssessmentResponse, {
      userId,
      type: 'initial',
      assessmentResponse: JSON.stringify(assessmentResponse),
    })
    yield call(getBusinessesAccountDetails)
    yield put(openUserManagementEditModalAction(false))
    yield put(updateOwnerProfileAction.FULLFILLED())
  } catch (error) {
    console.log('error: ', error)
    yield put(updateOwnerProfileAction.REJECTED())
  }
}
function* getUserProfileAndAdvisors(action) {
  try {
    yield put(checkUserProfileAction(false))
    yield put(getUserProfileAction.FULLFILLED({ userProfile: {} }))
    yield put(openUserManagementEditModalAction(true))
    const res = yield call(
      UserApi.getUserProfile,
      (action?.payload?.tenantId, action?.payload?.userId)
    )
    const user = res?.getMyProfile
    const userProfile = res?.getMyProfile
    yield put(
      getUserProfileAction.FULLFILLED(action.payload === undefined ? { user } : { userProfile })
    )
    const response = yield call(
      CalendarApi.getBoAdvisors,
      action?.payload?.tenantId,
      action?.payload?.userId
    )
    yield put(getBoAdvisorAction.FULLFILLED(get(response, 'getBoAdvisors.data', [])))
    const advisors = yield select(getBoAdvisorsList)

    if (action.payload?.setForms?.length > 0) {
      const { setForms } = action.payload
      if (advisors && advisors.length > 0) {
        res.getMyProfile.advisors = advisors?.map((advisor) => advisor.value)
      }
      const payload = { forms: setForms, profile: res?.getMyProfile }
      yield put(setProfileAction(payload))
      yield put(checkUserProfileAction(true))
    }
  } catch (error) {
    console.log('get user profile error  : ', error)
  }
}
function* getBusinessAndAssessmentResponse(action) {
  const { type, userId } = action.payload
  try {
    yield put(checkBusinessProfileAction(false))
    const activeQuestion = yield select((state) => state.owner.activeQuestion)
    let getAssessments = yield call(AssessmentApi.getAssessments, type)
    getAssessments = getUserAssessments(getAssessments)
    getAssessments = sortBy(getAssessments, (q) => q.previous)
    yield put(getAssessmentQuestionAction.FULLFILLED(getAssessments))
    if (!activeQuestion.id) yield put(setActiveQuestion(getAssessments[0]?.id))

    // Get Business profile
    const res = yield call(UserApi.getBusinessProfile, userId)

    yield put(getUserBusinessProfileAction.FULLFILLED(res?.getBusinessProfile))
    //assessment response
    const assessment = yield select((state) => state.owner.questions)

    const response = yield call(AssessmentApi.getUserAssessmentResponse, type, userId)
    const questions = get(response, 'getUserAssessmentResponse.data', '')

    const assessmentResponse = {}
    questions.assessmentResponse = questions.assessmentResponse
      ? JSON.parse(questions.assessmentResponse)
      : []

    questions.assessmentResponse.forEach((answer) => {
      assessmentResponse[answer.id] = answer.value
    })

    const businessAssessment = {}
    const businessQuestions = assessment.filter((question) => question?.meta?.showToBusinessProfile)

    for (let i = 0; i < businessQuestions.length; i++) {
      businessAssessment[businessQuestions[i].id] = assessmentResponse[businessQuestions[i].id]
    }
    if (action.payload?.setForms?.length > 0) {
      const { setForms } = action.payload
      const businessProfile = pickBy(res.getBusinessProfile, (value, key) =>
        formKeys[setForms[0]].includes(key)
      )

      const businessProfileAndAssessmentFormData = { ...businessAssessment, ...businessProfile }
      yield put(setFormData({ form: setForms[0], data: businessProfileAndAssessmentFormData }))
      yield put(checkBusinessProfileAction(true))
    }
  } catch (error) {
    console.log('get assessment question error : ', error)
  }
}

export const getUserAssessments = (assessments) => {
  let userAssessments = (assessments = get(assessments, 'getAssessments', []))
  userAssessments = userAssessments
    .map((assessment) => ({
      ...assessment,
      meta: assessment?.meta ? JSON.parse(assessment.meta) : null,
      content: assessment?.content ? JSON.parse(assessment.content) : null,
      options: assessment?.options ? JSON.parse(assessment.options) : null,
      previous: assessment?.previous ? JSON.parse(assessment.previous) : [],
    }))
    .filter((assessment) => assessment?.meta?.showToBusinessProfile)
  return userAssessments
}

function* enableDisableUser(action) {
  try {
    yield call(UserApi.updateUserProfile, action.payload)
    const res = yield call(
      UserApi.getUserProfile,
      (action?.payload?.tenantId, action?.payload?.userId)
    )

    const user = res?.getMyProfile
    const userProfile = res?.getMyProfile
    yield put(
      getUserProfileAction.FULLFILLED(action.payload === undefined ? { user } : { userProfile })
    )
    yield put(enableDisableUserAction.FULLFILLED(true))
    yield call(getBusinessesAccountDetails)
    yield call(getBusinessesAdvisorsDetails)
  } catch (error) {
    console.log(error)
    yield put(enableDisableUserAction.REJECTED(true))
  }
}

function* updateAdvisorProfile(action) {
  const userRole = getRoleFromPath()
  const { userId, tenantId } = action.payload
  const userProfileForm = hookForms.getForm(FORM.USER_PROFILE_FORM)
  const advisorAssignedOwner = hookForms.getForm(FORM.OWNERS)
  const advisorAssignedOwnerValues = advisorAssignedOwner.getValues()
  let userProfileFormValues = userProfileForm.getValues()

  const formId = userRole === ROLES.BSO_ADMIN ? FORM.ADVISOR_PROFILE_FORM : FORM.PROFILE_FORM
  userProfileFormValues = pickBy(userProfileFormValues, (value, key) =>
    formKeys[formId].includes(key)
  )
  try {
    yield call(UserApi.updateUserProfile, {
      ...userProfileFormValues,
      userId,
      tenantId,
      owners: JSON.stringify(advisorAssignedOwnerValues.owners),
    })
    yield put(updateAdvisorProfileAction.FULLFILLED())
    yield call(getBusinessesAdvisorsDetails)
    yield put(openUserManagementEditModalAction(false))
    yield put(updateAdvisorProfileAction.FULLFILLED())
  } catch (error) {
    console.log('error: ', error)
    yield put(updateAdvisorProfileAction.REJECTED())
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherUserManagement() {
  yield takeLatest(GET_BUSINESSES_ACCOUNT_DETAILS.STARTED, getBusinessesAccountDetails)
  yield takeLatest(GET_ADVISORS_ACCOUNT_DETAILS.STARTED, getBusinessesAdvisorsDetails)
  yield takeLatest(GET_ADVISORS.STARTED, getAdvisors)
  yield takeLatest(ADD_USER_TO_GROUP.STARTED, addUserToGroup)
  yield takeLatest(BUSINESSES_ACCOUNT_FILTER.STARTED, businessAccountFilter)
  yield takeLatest(ADVISORS_ACCOUNT_FILTER.STARTED, advisorAccountFilter)
  yield takeLatest(GET_USER_PROFILE_AND_ADVISOR.STARTED, getUserProfileAndAdvisors)
  yield takeLatest(UPDATE_OWNER_PROFILE.STARTED, updateOwnerProfile)
  yield takeLatest(GET_BUSINESS_AND_ASSESSMENT_RESPONSE.STARTED, getBusinessAndAssessmentResponse)
  yield takeLatest(ENABLE_DISABLE_USER.STARTED, enableDisableUser)
  yield takeLatest(UPDATE_ADVISOR_PROFILE.STARTED, updateAdvisorProfile)
}
