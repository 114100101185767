import React from 'react'
import ContentLoader from 'react-content-loader'

import { colorsPallete } from 'config'

const ChartLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 250 80"
        backgroundColor={`${colorsPallete.cream.dark}`}
        foregroundColor={`${colorsPallete.grey.lighter}`}
      >
        <rect x="0" y="0" rx="0" ry="0" width="290" height="290" />
      </ContentLoader>
    </>
  )
}

export default ChartLoader
