import React, { Fragment, useEffect, useState } from 'react'
import SortIcon from 'components/Common/SvgIcons/SortDataIcon'
import { isMobileOnly, isTablet } from 'mobile-device-detect'
import { Columns } from 'config'

const SortComponent = ({
  colIndex,
  colField,
  colFieldIndex,
  sortHandler,
  fieldType,
  setSortedColumn,
  sortedColumn,
  tableColumns,
  setTableColumns,
}) => {
  const handleSortingOrder = (sort) => {
    for (let i = 0; i < tableColumns.length; i++) {
      for (let j = 0; j < tableColumns[i].colFields.length; j++) {
        if (colIndex === i && colFieldIndex === j) {
          tableColumns[i].colFields[j].sortingOrder = sort
        } else {
          tableColumns[i].colFields[j].sortingOrder = 'DSC'
        }
      }
    }
    setTableColumns(tableColumns)
  }

  const toggleSortOrderHandler = (order) => {
    if (order === 'DSC') {
      if (sortedColumn) {
        sortHandler({
          sortField: sortedColumn,
          sortOrder: 'DSC',
          fieldType,
        })
      }
      handleSortingOrder('ASC')
      sortHandler({
        sortField: colField.field,
        sortOrder: 'ASC',
        fieldType,
      })
      setSortedColumn(colField.field)
    } else {
      handleSortingOrder('DSC')
      sortHandler({
        sortField: colField.field,
        sortOrder: 'DSC',
        fieldType,
      })
      setSortedColumn(colField.field)
    }
  }

  return (
    <div>
      {colField.sortingOrder === 'ASC' && (
        <div onClick={() => toggleSortOrderHandler('ASC')}>
          <SortIcon className={'fill-primary cursor-pointer'} />
        </div>
      )}

      {colField.sortingOrder !== 'ASC' && (
        <div onClick={() => toggleSortOrderHandler('DSC')}>
          <SortIcon className={'cursor-pointer'} />
        </div>
      )}
    </div>
  )
}

interface ITableHeaderProps {
  columns: Columns
  sortHandler: any
  gridColumns?: number
}

const TableHeader = ({ columns, sortHandler, gridColumns }: ITableHeaderProps) => {
  let [tableColumns, setTableColumns]: any = useState([])
  let [sortedColumn, setSortedColumn]: any = useState('')

  useEffect(() => {
    setTableColumns(columns)
  }, [columns])

  return (
    <thead className="bg-primary-disabled border-b-2 border-grey-light w-full">
      <tr
        className={
          (gridColumns
            ? `grid grid-cols-${gridColumns}`
            : `${tableColumns[0]?.display || 'grid grid-cols-12'}`) + ' rounded-[20px]'
        }
      >
        {tableColumns &&
          tableColumns.map((col, colIndex) => {
            return (
              <Fragment key={colIndex}>
                <th
                  className={`${
                    col.padding ? col.padding : 'p-[14px]'
                  } text-base font-bold tracking-wide text-right first:!rounded-tl-[10px] last:!rounded-tr-[10px] flex ${
                    col.direction ? col.direction : 'items-center '
                  } ${col.colSpan} w-[${col.width}px]`}
                  key={colIndex}
                >
                  <div
                    className={` ${col.paddingLeft ? col.paddingLeft : ''} ${
                      isTablet ? 'whitespace-nowrap' : 'flex gap-2'
                    }
                     ${col?.textDirection === 'right' ? 'justify-end' : 'justify-start'}

                   `}
                  >
                    {col.colFields?.map((colField, index) => {
                      return (
                        <div className={`${isTablet ? colField.paddingTop : ''} flex`} key={index}>
                          {!isTablet && !isMobileOnly && colField.circle && (
                            <span className="font-bold text-gray-700 rounded-full flex items-center justify-center font-mono w-[5px] h-[5px] mt-[11px] mr-2 bg-zinc ml-2"></span>
                          )}
                          <div className="whitespace-nowrap uppercase font-primary">
                            {colField?.headerName}
                          </div>

                          {colField?.sortable && (
                            <div className="mt-1 ml-1">
                              <SortComponent
                                colIndex={colIndex}
                                colFieldIndex={index}
                                colField={colField}
                                sortHandler={sortHandler}
                                fieldType={colField?.type}
                                setSortedColumn={setSortedColumn}
                                sortedColumn={sortedColumn}
                                tableColumns={tableColumns}
                                setTableColumns={setTableColumns}
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </th>
              </Fragment>
            )
          })}
      </tr>
    </thead>
  )
}

export default TableHeader
