import styled from 'styled-components'

export const NotficationPanel = styled.div`
  border: 1px solid #d4d4d8;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
  border-radius: 4px;
  width: 460px;
  background: #fafafa;
  right: -10px;
  top: 74px;
  position: absolute;
  z-index: 100;
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-style: solid;
    border-color: #ccc;
    border-width: 1px 1px 0 0;
    width: 17px;
    height: 17px;
    top: -9px;
    right: 25px;
    background: inherit;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  height: calc(100vh - 200px);
}


@media only screen and (max-width: 1024px) {
  height: calc(100vh - 300px);
}

@media only screen and (max-width: 768px) {
  top:63px;
  height: calc(100vh - 163px);
}
@media only screen and (max-width: 500px) {
  height: calc(100vh - 0px);

}
@media only screen and (max-width: 500px) {
    width:100%;
    top:0;
    right:0;
    z-index: 1025;
    &:after{
    display:none;
  }
}
`

export const ListItem = styled.ul`
  height: calc(100% - 86px);
  @media only screen and (max-width: 500px) {
    height: calc(100% - 160px);
  }
`
