import React from 'react'
import ContentLoader from 'react-content-loader'

import { colorsPallete } from 'config'

const ClientAssignmentLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 215"
        backgroundColor={`${colorsPallete.cream.dark}`}
        foregroundColor={`${colorsPallete.grey.lighter}`}
      >
        <rect x="15" y="10" rx="2" ry="2" width="90" height="15" />
        <rect x="285" y="10" rx="2" ry="2" width="45" height="15" />
        <rect x="335" y="10" rx="2" ry="2" width="10" height="15" />
        <rect x="370" y="10" rx="2" ry="2" width="35" height="15" />
        <rect x="410" y="10" rx="2" ry="2" width="10" height="15" />

        <rect x="15" y="35" rx="2" ry="2" width="50" height="15" />
        <rect x="180" y="35" rx="2" ry="2" width="25" height="15" />
        <rect x="230" y="35" rx="2" ry="2" width="75" height="15" />
        <rect x="370" y="35" rx="2" ry="2" width="50" height="15" />

        <rect x="15" y="55" rx="2" ry="2" width="205" height="25" />
        <rect x="225" y="55" rx="2" ry="2" width="205" height="25" />

        <rect x="15" y="55" rx="2" ry="2" width="205" height="150" />
        <rect x="225" y="55" rx="2" ry="2" width="205" height="150" />
      </ContentLoader>
    </>
  )
}

ClientAssignmentLoader.defaultProps = {
  isMobile: false,
}

export default ClientAssignmentLoader
