import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import CommonApi from 'api/common'

import Progressbar from 'components/Common/ProgressBar'
import {
  totalStepsProgressbarAction,
  updateProgressbarAction,
} from 'store/actions/common/progressbar'
import { logoutAction } from 'store/actions/auth'
import { changePasswordAction } from 'store/actions/common'
import SettingPanel from 'layout/AppLayout/SettingPanel'
import IdleTracking from 'components/Common/IdleTracking'
import ChangePassword from 'components/Common/ChangePassword'
import NotificationPanel from 'components/NotificationPanel'
import Chat from 'components/Chat'
import { getUsersAssessmentResponseByQuestionAction } from 'store/actions/owner/initialAssessment'
import {
  chatUnreadMessageCountAction,
  getChatUsersAction,
  getUnreadNotificationsAction,
  getUserNoticationsAction,
  updateUserNotificationsAction,
  deleteUserNotificationsAction,
} from 'store/actions/user'
import NavBar from 'layout/AppLayout/AppHeader/Navbar'
import Header from 'layout/AppLayout/AppHeader/Header'

/* --------------------------- Progressbar (Index) --------------------------- */

const progressbarProps = (state: any) => {
  const { width } = state.progressbar
  return {
    width: width,
  }
}

function progressbarDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateProgressbarAction: updateProgressbarAction,
      totalStepsProgressbarAction: totalStepsProgressbarAction,
    },
    dispatch
  )
}
export const ProgressbarContainer = connect(progressbarProps, progressbarDispatch)(Progressbar)

/* --------------------------- Idle Tracking (Index) --------------------------- */

const idleTrackingProps = (state: any) => {
  return {}
}

function idleTrackingDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      logoutAction: logoutAction,
    },
    dispatch
  )
}

export const IdleTrackingContainer = connect(idleTrackingProps, idleTrackingDispatch)(IdleTracking)

/* --------------------------- App layout  --------------------------- */
/* --------------------------- Setting Panel --------------------------- */

const settingPanelProps = (state: any) => {
  return {}
}

function settingPanelDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      logoutAction: logoutAction,
    },
    dispatch
  )
}

export const SettingPanelContainer = connect(settingPanelProps, settingPanelDispatch)(SettingPanel)

/* --------------------------- Change Password (Index) --------------------------- */

const changePasswordProps = (state: any) => {
  const { error } = state.auth
  const { disableUpdatePasswordButton } = state.changePassword
  return {
    errorMessage: error,
    disableUpdatePasswordButton: disableUpdatePasswordButton,
  }
}

function changePasswordDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      changePasswordAction: changePasswordAction.STARTED,
    },
    dispatch
  )
}
export const ChangePasswordContainer = connect(
  changePasswordProps,
  changePasswordDispatch
)(ChangePassword)

/* --------------------------- Chat --------------------------- */

const chatProps = (state: any) => {
  let { user, chatUsers } = state.user
  let { usersAssessmentResponseByQuestion } = state.owner
  return {
    user,
    chatUsers,
    token: user?.streamAccessToken,
    usersAssessmentResponseByQuestion,
  }
}

function chatDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      uploadFileAction: (data) => CommonApi.saveUserFile(data),
      getUsersAssessmentResponseByQuestionAction:
        getUsersAssessmentResponseByQuestionAction.STARTED,
      getChatUsersAction: getChatUsersAction.STARTED,
      chatUnreadMessageCountAction: chatUnreadMessageCountAction.STARTED,
    },
    dispatch
  )
}
export const ChatContainer = connect(chatProps, chatDispatch)(Chat)

/* ------------------- NAVBAR ----------------------- */

const navBarProps = (state: any) => {
  let { user } = state.user
  return {
    user: user,
  }
}

function navBarDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const NavbarContainer = connect(navBarProps, navBarDispatch)(NavBar)

/* ------------------- HEADER ----------------------- */

const headerProps = (state: any) => {
  let { user } = state
  return {
    user: user.user,
    chatUnreadMessageCount: user.chatUnreadMessageCount,
    chatUnreadNotificationsCount: user.notifications.totalUnread,
  }
}

function headerDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUnreadNoticationsAction: getUnreadNotificationsAction.STARTED,
      chatUnreadMessageCountAction: chatUnreadMessageCountAction.STARTED,
    },
    dispatch
  )
}
export const HeaderContainer = connect(headerProps, headerDispatch)(Header)

/* ------------------- Notifications ----------------------- */

const notificationProps = (state: any) => {
  let { user } = state
  return {
    notifications: user.notifications.data,
    totalCount: user.notifications.totalCount,
    isLoading: user.notifications.isLoading,
  }
}

const notificationDispatch = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getUnreadNoticationsAction: getUnreadNotificationsAction.STARTED,
      getUserNoticationsAction: getUserNoticationsAction.STARTED,
      updateUserNoticationsAction: updateUserNotificationsAction.STARTED,
      deleteUserNoticationsAction: deleteUserNotificationsAction.STARTED,
    },
    dispatch
  )
}
export const NotificationContainer = connect(
  notificationProps,
  notificationDispatch
)(NotificationPanel)
