import React, { Fragment } from 'react'
import { ArrowRightIcon } from '@heroicons/react/solid'
import { COMPANY_NAME } from 'config'
import CircleWithCheckIcon from 'components/Common/SvgIcons/CircleWithCheckIcon'

export default function PrivacyPolicy() {
  return (
    <Fragment>
      <div className="min-h-min flex items-center justify-center mt-10">
        <div className="xs:w-[290px] sm:w-[650px] md:w-[750px] lg:w-[750px]">
          <div className="flex items-center justify-start content-start">
            <CircleWithCheckIcon className={'h-16 w-16'} />
            <div className="text-2xl font-primary ml-2">
              <mark>
                <h1>{COMPANY_NAME} Solutions</h1>
              </mark>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-0 ">
        <div className="font-primary not-italic mb-24 max-w-[290px] sm:max-w-[650px] md:max-w-[750px] lg:max-w-[750px]">
          <div className="my-5 flex items-center justify-start content-start"></div>
          <div className="text-left text-justify">
            <p>
              <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                <mark className="bg-yellow-lighter">{COMPANY_NAME} , Inc.</mark>
                ONLINE PRIVACY POLICY
              </strong>
            </p>
            <br />
            <br />
            <strong>
              <p className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg font-primary italic">
                Last Revised on December 27, 2022
              </p>
            </strong>
            <br />
            <p>
              This Privacy Policy for {COMPANY_NAME}, Inc., a Delaware Corporation ("
              <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                Company", "we", "us" "our"
              </strong>
              ) describes how we collect, use and disclose information about users of the Company's
              platform (URL or name of platform here), applications, services, tools and features
              (collectively, the{' '}
              <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                "Services"
              </strong>
              ). For the purposes of this Privacy Policy, "<strong>you</strong>" and "
              <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                your
              </strong>
              " means you as the user of the Services.
            </p>
            <br />
            <p>
              PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING, ACCESSING, OR DOWNLOADING ANY OF
              THE SERVICES, YOU AGREE TO THE USE OF YOUR INFORMATION IT DESCRIBES AND TO THE OTHER
              TERMS OF THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO
              NOT ACCESS AND USE THE SERVICES.
            </p>
            <br />
            <ol className="list-decimal list-inside">
              <li>
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  {' '}
                  UPDATING THIS PRIVACY POLICY
                </strong>
              </li>

              <br />
              <p>
                We may modify this Privacy Policy from time to time in which case we will update the
                "Last Revised" date at the top of this Privacy Policy. If we make changes that are
                material, we will use reasonable efforts to attempt to provide notice to you and,
                where required by applicable law, we will obtain your consent. Notice may be by
                email to you at the last email address you provided us, by posting notice of such
                changes on the Services, or by other means, consistent with applicable law. However,
                it is your sole responsibility to review the Privacy Policy from time to time to
                view any such changes. The updated Privacy Policy will be effective as of the time
                of posting, or such later date as may be specified in the updated Privacy Policy.{' '}
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg">
                  IF YOU DO NOT AGREE TO ANY UPDATES TO THIS PRIVACY POLICY PLEASE DO NOT ACCESS OR
                  CONTINUE TO USE THE SERVICES.
                </strong>
              </p>
              <br />
              <li>
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  COMPANY’S COLLECTION AND USE OF INFORMATION
                </strong>
              </li>
              <br />
              <p>
                In order to provide you with particular services, we may ask you to provide us with
                certain details or information about you. Information that you submit through our
                Services may include:
              </p>
              <br />
              <ul className="ml-5">
                <li role={'list'} className="list-disc list-inside">
                  Basic contact details, including name, address and email. We collect basic contact
                  details to respond to your inquiries, provide you with products and services and,
                  with your consent, to market to you.
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  Profile and information used to create an account, including information about
                  your business.
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  Any other information you choose to include in communications with us, for
                  example, when emailing us or engaging with us on one of our social media
                  platforms. We may use this information to share testimonials on the Services.
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  Information we receive about you from third parties. For example, if you access
                  the Services through a small business development center's platform or log-in, we
                  may also collect your email address and other Information associated with that
                  third party, and any Information you have made public in connection with that
                  service or which the third party shares with partner services. See also Section 7
                  "THIRD PARTY WEBSITES AND LINKS" below.
                </li>
              </ul>
              <br />
              <p>
                Some features of the Services may require you to enter certain information about
                yourself. You may elect not to provide this information, but doing so may prevent
                you from using or accessing these features.
              </p>
              <br />
              <p>
                We also automatically collect certain information about your interaction with the
                Services ("<strong>Usage Data</strong>"). To do this, we may use product analytics
                technologies ("
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  Tracking Technologies
                </strong>
                "). Usage Data may include:
              </p>
              <br />
              <ul className="ml-5">
                <li role={'list'} className="list-disc list-inside">
                  [<mark className="bg-yellow-lighter">Unique device identifier</mark>]
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  [
                  <mark className="bg-yellow-lighter">
                    Device type, such as your phone, computer, or tablet
                  </mark>
                  ]
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  [<mark className="bg-yellow-lighter">IP address</mark>]
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  [<mark className="bg-yellow-lighter">Browser type</mark>]
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  [
                  <mark className="bg-yellow-lighter">
                    Date and time stamps, such as the date and time you first accessed the Services
                  </mark>
                  ]
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  [<mark className="bg-yellow-lighter">Operating system</mark>]
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  [<mark className="bg-yellow-lighter">Log data</mark>]
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  [<mark className="bg-yellow-lighter">Precise location</mark>]
                </li>
              </ul>
              <br />
              <p>
                We use the information we collect automatically to<span> </span>
                <mark className="bg-yellow-lighter ">
                  run analytics and better understand user interaction with the Services.
                </mark>
              </p>
              <br />
              <p>
                "We may obtain information about you from outside sources. Such information may
                include:
              </p>
              <br />
              <ul className="ml-5">
                <li role={'list'} className="list-disc list-inside">
                  Information we collect about you from other sources, such as when you choose to
                  interact with us on our social media accounts such as Facebook and Instagram. This
                  information is used to communicate with you.
                </li>
              </ul>
              <br />
              <p>
                Any information we receive from outside sources will be treated in accordance with
                this Privacy Policy. We are not responsible or liable for any third party's policies
                or practices. See Section 6 below for more information.
              </p>
              <br />
              <p>
                In addition to the foregoing, we may use all of the above information to comply with
                any applicable legal obligations, to enforce any applicable terms of service, to
                protect or defend the Services, our rights, the rights of our users, or others and
                for any other lawful purpose.
              </p>
              <br />
              <li>
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  HOW THE COMPANY SHARES YOUR INFORMATION
                </strong>
              </li>
              <br />
              <p>
                In certain circumstances, the Company may share your information with third parties.
                Such circumstances may include:
              </p>
              <br />
              <ul className="ml-5">
                <li role={'list'} className="list-disc list-inside">
                  [
                  <mark className="bg-yellow-lighter">
                    With vendors or other service providers, such as
                  </mark>
                  ]
                </li>
                <ul className="list-disc marker:text-silver-darker list-inside ml-5">
                  <br />
                  <span className="">
                    <li>[Cloud storage providers]</li>
                  </span>
                  <span className="ml-5">
                    <li>[IT service management vendors]</li>
                  </span>

                  <span className="ml-5">
                    <li>[Email marketing services vendors]</li>
                  </span>

                  <span className="ml-5">
                    <li>[Security vendors]</li>
                  </span>
                </ul>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  With the small business development center that has authorized your use of the
                  Services and their employees and subcontractors.
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  To comply with applicable law or any obligations thereunder, including cooperation
                  with law enforcement, judicial orders, and regulatory inquiries
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  In connection with an asset sale, merger, bankruptcy, or other business
                  transaction
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  To enforce any applicable terms of service
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  To ensure the safety and security of the Company and/or its users
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  When you request us to share certain information with third parties, such as
                  through your use of third party applications made available through the Services
                </li>
                <br />
                <li role={'list'} className="list-disc list-inside">
                  With professional advisors, such as auditors, law firms, or accounting firms
                </li>
                <br />
              </ul>

              <p>
                You acknowledge that such sharing of information may occur in all of the
                aforementioned circumstances and is permitted by and subject to this Privacy Policy.
              </p>
              <br />
              <li>
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  {' '}
                  COOKIES AND OTHER TRACKING TECHNOLOGIES
                </strong>
              </li>
              <br />
              <p className="italic">Do Not Track Signals</p>
              <br />
              <p>
                Your browser settings may also allow you to transmit a "Do Not Track" signal when
                you visit various websites. Like many websites, our website is not designed to
                respond to "Do Not Track" signals received from browsers. To learn more about "Do
                Not Track" signals, you can visit{' '}
                <a
                  href="http://www.allaboutdnt.com/"
                  target="_blank"
                  className="text-blue"
                  rel="noreferrer"
                  tabIndex={-1}
                >
                  http://www.allaboutdnt.com/
                </a>
                .
              </p>
              <br />
              <p className="italic">Cookies and Other Tracking Technologies</p>
              <br />
              <p>
                You may control the way in which your devices permit the use of Tracking
                Technologies. If you so choose, you may block or delete our cookies from your
                browser; however, blocking or deleting cookies may cause some of the Services,
                including any portal features and general functionality, to work incorrectly.
              </p>
              <br />
              <p>
                Most browsers accept cookies automatically. However, you may be able to configure
                your browser settings to use the Services without some cookie functionality. You can
                delete cookies manually or set your browser to automatically delete cookies on a
                pre-determined schedule. For example, in the Internet Explorer menu bar, select:
                Tools <ArrowRightIcon className="h-5 w-5 inline-block" />
                Internet Options
                <ArrowRightIcon className="h-5 w-5 inline-block" />
                Browsing History
                <ArrowRightIcon className="h-5 w-5 inline-block" />
                Delete to view manual and automatic options.
              </p>
              <br />
              <p>
                <mark className="bg-yellow-lighter">
                  <span>"To opt out of tracking by Google Analytics, click</span>
                  <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    target="_blank"
                    className="text-blue"
                    rel="noreferrer"
                    tabIndex={-1}
                  >
                    <span> here</span>
                  </a>
                  ."
                </mark>
              </p>
              <br />
              <p>
                We may also use Flash cookies (also known as "persistent identification elements" or
                "local shared objects") on certain pages. Because Flash cookies cannot be controlled
                through your browser settings, you may click here to adjust your preferences. You
                can also identify Flash cookies running on your computer by visiting the Flash
                Player folder. Flash cookies, or LSO files, are typically stored with a ".SOL"
                extension. Please note that if you block cookies, some functions otherwise available
                on the Services may be unavailable, and we may not be able to present you with
                personally-tailored content.
              </p>
              <br />
              <p>
                If you have questions regarding the specific information about you that we process
                or retain, as well as your choices regarding our collection and use practices,
                please contact us using the information listed below.
              </p>
              <br />
              <li>
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  {' '}
                  SOCIAL FEATURES
                </strong>
              </li>
              <br />
              <p>
                Certain features of the Services permit you to initiate interactions between the
                Services and third-party services or platforms, such as social networks ("
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  Social Features
                </strong>
                "). Social Features include features that allow you to click and access the
                Company's pages on certain third-party platforms, such as Facebook and Twitter, and
                from there to "like" or "share" our content on those platforms. Use of Social
                Features may entail a third party's collection and/or use of your data. If you use
                Social Features or similar third-party services, information you post or otherwise
                make accessible may be publicly displayed by the third-party service you are using.
                Both the Company and the third party may have access to information about you and
                your use of both the Services and the third-party service. For more information on
                third-party websites and platforms, see Section 6.
              </p>
              <br />
              <li>
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  {' '}
                  THIRD PARTY WEBSITES AND LINKS
                </strong>
              </li>
              <br />
              <p>
                Our Services may contain links to other online platforms operated by third parties.
                We do not control such other online platforms and are not responsible for their
                content, their privacy policies, or their use of your information. Information you
                provide on public or semi-public venues, including information you share on
                third-party social networking platforms (such as Facebook or Instagram) may also be
                viewable by other users of the Services and/or users of those third-party online
                platforms without limitation as to its use by us or by a third party. Our inclusion
                of such links does not, by itself, imply any endorsement of the content on such
                platforms or of their owners or operators except as disclosed on the Services. We
                expressly disclaim any and all liability for the actions of third parties, including
                but without limitation to actions relating to the use and/or disclosure of personal
                information by third parties. Any information submitted by you directly to these
                third parties is subject to that third party's privacy policy.
              </p>
              <br />
              <li>
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  {' '}
                  CHILDREN'S PRIVACY
                </strong>
              </li>
              <br />
              <p>
                Children under the age of 13 are not permitted to use the Services, and we do not
                seek or knowingly collect any personal information about children under 13 years of
                age. If we become aware that we have unknowingly collected information about a child
                under 13 years of age, we will make commercially reasonable efforts to delete such
                information from our database.
              </p>
              <br />
              <p>
                If you are the parent or guardian of a child under 13 years of age who has provided
                us with their personal information, you may contact us using the below information
                to request that it be deleted.
              </p>
              <br />
              <li>
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  {' '}
                  DATA SECURITY
                </strong>
              </li>
              <br />
              <p>
                Please note that any information you send to us electronically, while using the
                Services or otherwise interacting with us, may not be secure when it is transmitted
                to us. We recommend that you do not use unsecure channels to communicate sensitive
                or confidential information to us. Please be aware though that, despite our best
                efforts, no security measures are perfect or impenetrable, and we cannot guarantee
                "perfect security." Any information you send us through any means is transmitted at
                your own risk.
              </p>
              <br />
              <li>
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  {' '}
                  INTERNATIONAL USERS
                </strong>
              </li>
              <br />
              <p>
                The Services are designed for users in the United States only and are not intended
                for users located outside the United States.
              </p>
              <br />
              <li>
                <strong className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg whitespace-pre">
                  HOW TO CONTACT US
                </strong>
              </li>
              <br />
              <p>
                Should you have any questions about our privacy practices or this Privacy Policy,
                please email us at legal@gologicsolutions.com or contact us at 222 West Merchandise
                Plaza #1212, Chicago, IL 60654.
              </p>
              <br />
            </ol>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
