import UserManagement from 'components/Admin/UserManagement'
import AdvisorsComponent from 'components/Admin/UserManagement/Advisors'
import AdminRightsComponent from 'components/Admin/UserManagement/Advisors/AdminRightsComponent'
import Businesses from 'components/Admin/UserManagement/Businesses'
import EditAccount from 'components/Admin/UserManagement/EditAccount'
import EditBusinessProfile from 'components/Admin/UserManagement/EditAccount/EditBusinessProfile'
import EditUserProfile from 'components/Admin/UserManagement/EditAccount/EditUserProfile'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  enableDisableUserAction,
  getAdvisorsAction,
  getBusinessAndAssessmentResponseAction,
  getUserProfileAndAdvisorsAction,
  updateOwnerProfileAction,
  updateAdvisorProfileAction,
} from 'store/actions/userManagement'
import {
  getAdvisorsAccountDetailsAction,
  getBusinessesAccountDetailsAction,
  businessAccountFilterAction,
  advisorAccountFilterAction,
} from 'store/actions/userManagement'
import {
  getAdvisorsAccountDetails,
  getAllClient,
  getAssessmentsSelector,
  getBusinessesAccountDetails,
  getRemainingClients,
  getSelectedClients,
} from 'store/selectors/userManagement'
import { getAdvisorsList } from 'store/selectors/admin'
import { getClientsAction } from 'store/actions/clients'
import { addUserToGroupAction } from 'store/actions/userManagement'
import { getAdvisorsListSelector } from 'store/selectors/userManagement'
import { openUserManagementEditModalAction } from 'store/actions/userManagement'
import UserManagementList from 'components/Admin/UserManagement/UserManagementList'
import AssignOwnerToAdvisor from 'components/Admin/UserManagement/EditAccount/AssignOwnerToAdvisor'
import AssignOwnerToAdvisorMobileView from 'components/Admin/UserManagement/EditAccount/AssignOwnerToAdvisorMobileView'

/* --------------------------- User Management  --------------------------- */
const userManagementProps = (state: any) => {
  const { openUserManagementEditModal } = state.userManagement
  return {
    openUserManagementEditModal,
  }
}
function userManagementDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      openUserManagementEditModalAction: openUserManagementEditModalAction,
    },
    dispatch
  )
}
export const UserManagementContainer = connect(
  userManagementProps,
  userManagementDispatch
)(UserManagement)

/* --------------------------- User Management (Businesses) --------------------------- */
const businessAccountsProps = (state: any) => {
  let { businessAccountFilter } = state.userManagement
  let { ownerDataLoading } = state.loadingStatus
  return {
    ownerDataLoading,
    businessesAccountDetails: getBusinessesAccountDetails(state),
    advisorsAccountDetails: getAdvisorsAccountDetails(state),
    advisors: getAdvisorsListSelector(state),
    businessAccountFilter: businessAccountFilter,
  }
}
function businessAccountsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getBusinessesAccountDetailsAction: getBusinessesAccountDetailsAction.STARTED,
      getAdvisorsAction: getAdvisorsAction.STARTED,
      businessAccountFilterAction: businessAccountFilterAction.STARTED,
    },
    dispatch
  )
}
export const BusinessAccountsContainer = connect(
  businessAccountsProps,
  businessAccountsDispatch
)(Businesses)

/* --------------------------- User Management (Advisors) --------------------------- */
const advisorsAccountsProps = (state: any) => {
  const { addUserToAdminGroup, advisorsAccountFilter } = state.userManagement
  let { advisorDataLoading } = state.loadingStatus

  return {
    advisorDataLoading,
    addUserToAdminGroup: addUserToAdminGroup,
    advisorsAccountDetails: getAdvisorsAccountDetails(state),
    advisorsAccountFilter: advisorsAccountFilter,
  }
}
function advisorsAccountsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getAdvisorsAccountDetailsAction: getAdvisorsAccountDetailsAction.STARTED,
      addUserToGroupAction: addUserToGroupAction.STARTED,
      advisorAccountFilterAction: advisorAccountFilterAction.STARTED,
      getBusinessesAccountDetailsAction: getBusinessesAccountDetailsAction.STARTED,
    },
    dispatch
  )
}
export const AdvisorsAccountsContainer = connect(
  advisorsAccountsProps,
  advisorsAccountsDispatch
)(AdvisorsComponent)

/* --------------------------- AdminRights --------------------------- */
const adminRightsProps = (state: any) => {
  return {}
}
function adminRightsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      addUserToGroupAction: addUserToGroupAction.STARTED,
    },
    dispatch
  )
}
export const AdminRightsContainer = connect(
  adminRightsProps,
  adminRightsDispatch
)(AdminRightsComponent)

/* --------------------------- EditAccount --------------------------- */
const editAccountProps = (state: any) => {
  const { disableButton, disableUserButton, openUserManagementEditModal, isUserProfileFormSet } =
    state.userManagement
  const { userProfile, user } = state.user
  return {
    disableButton,
    userProfile,
    user,
    disableUserButton,
    advisors: getAdvisorsList(state),
    openUserManagementEditModal,
    isUserProfileFormSet,
  }
}
function editAccountDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateOwnerProfileAction: updateOwnerProfileAction.STARTED,
      enableDisableUserAction: enableDisableUserAction.STARTED,
      getUserProfileAndAdvisorsAction: getUserProfileAndAdvisorsAction.STARTED,
      getClientsAction: getClientsAction.STARTED,
      updateAdvisorProfileAction: updateAdvisorProfileAction.STARTED,
      openUserManagementEditModalAction: openUserManagementEditModalAction,
    },
    dispatch
  )
}
export const EditAccountContainer = connect(editAccountProps, editAccountDispatch)(EditAccount)
/* --------------------------- EditUserProfile --------------------------- */
const editUserProfileProps = (state: any) => {
  return {}
}
function editUserProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const EditUserProfileContainer = connect(
  editUserProfileProps,
  editUserProfileDispatch
)(EditUserProfile)

/* --------------------------- EditBusinessProfile --------------------------- */
const editBusinessProfileProps = (state: any) => {
  const { isBusinessProfileFormSet } = state.userManagement

  return {
    assessment: getAssessmentsSelector(state),
    isBusinessProfileFormSet,
  }
}
function editBusinessProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getBusinessAndAssessmentResponseAction: getBusinessAndAssessmentResponseAction.STARTED,
    },
    dispatch
  )
}
export const EditBusinessProfileContainer = connect(
  editBusinessProfileProps,
  editBusinessProfileDispatch
)(EditBusinessProfile)

/* --------------------------- AssignOwnerToAdvisor --------------------------- */
const assignOwnerToAdvisortProps = (state: any) => {
  const { userProfile } = state.user
  const { isUserProfileFormSet } = state.userManagement

  return {
    isUserProfileFormSet,
    firstName: userProfile?.firstName,
    owners: getAllClient(state),
    assignedOwnerList: getSelectedClients(state),
  }
}
function assignOwnerToAdvisorDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const AssignOwnerToAdvisorContainer = connect(
  assignOwnerToAdvisortProps,
  assignOwnerToAdvisorDispatch
)(AssignOwnerToAdvisor)

/* --------------------------- AssignOwnerToAdvisor Mobile View --------------------------- */
const assignOwnerToAdvisorMobileViewProps = (state: any) => {
  const { userProfile } = state.user

  return {
    firstName: userProfile?.firstName,
    owners: getAllClient(state),
    assignedOwnerList: getSelectedClients(state),
    remainingOwnerList: getRemainingClients(state),
  }
}
function assignOwnerToAdvisorMobileViewDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const AssignOwnerToAdvisorMobileViewContainer = connect(
  assignOwnerToAdvisorMobileViewProps,
  assignOwnerToAdvisorMobileViewDispatch
)(AssignOwnerToAdvisorMobileView)

/* --------------------------- UserManagementList --------------------------- */
const userManagementListProps = (state: any) => {
  const { openUserManagementEditModal } = state.userManagement
  return {
    openUserManagementEditModal,
  }
}
function userManagementListDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      openUserManagementEditModalAction: openUserManagementEditModalAction,
    },
    dispatch
  )
}
export const UserManagementListContainer = connect(
  userManagementListProps,
  userManagementListDispatch
)(UserManagementList)
