import { createSelector } from 'reselect'
import history from 'utils/history'
import { isEmpty } from 'lodash'

export const getAssessmentQuestion = (state) => state.owner.questions
export const getOwnerGoals = (state) => state.owner.goals

export const getAssessmentResponse = (state) => state.owner.assessmentResponse
export const getActiveQuestion = (state) => state.owner.activeQuestion

export const assessmentResponseByQuestion = (state) => state.owner.assessmentQuestion
export const getBigQuery = (state) => state.owner.bigQuery

const getStepUserResponseInfo = (activeQuestion, assessmentResponse, isAnswered) => {
  let id = '',
    value = '',
    next = ''
  const userReponse = assessmentResponse?.assessmentResponse?.find(
    (ar) => ar.id === activeQuestion.id
  )
  if (assessmentResponse?.currentStage === activeQuestion.id || !isAnswered || !userReponse) {
    id = activeQuestion.options[0]?.id
    value = activeQuestion.meta.questionType
    isAnswered = false
    if (assessmentResponse?.currentStage === activeQuestion.id && !isEmpty(userReponse)) {
      let selectedOption = activeQuestion?.options?.find((op) => op.id === userReponse.value)
      next = selectedOption?.action?.next?.default
      id = userReponse.value
      isAnswered = true
    }
  } else {
    let selectedOption = activeQuestion?.options?.find((op) => op.id === userReponse.value)
    if (
      !selectedOption &&
      userReponse &&
      (activeQuestion.optionType === 'upload' || activeQuestion.optionType === 'input')
    ) {
      selectedOption = activeQuestion?.options[0]
    }
    id = selectedOption?.id
    next = selectedOption?.action?.next?.default
    value = activeQuestion?.meta?.questionType
    isAnswered = true
  }
  return { id, value, isAnswered, next }
}

const getUniqueQuestions = (questions, activeQuestion, sum = 1) => {
  const next = activeQuestion?.options?.[0]?.action?.next?.default
  if (next) {
    const nextQuestion = questions?.find((q) => q.id === next)
    return getUniqueQuestions(questions, nextQuestion, sum + 1)
  }
  return sum
}

const getAssessmentProgress = (
  questions,
  activeQuestion,
  assessmentResponse?,
  isAnswered = true,
  sum = 0
) => {
  let stepProgressInfo: any = {}
  if (!questions.length || isEmpty(activeQuestion))
    return {
      stepProgressInfo: [],
      sum,
    }
  stepProgressInfo = getStepUserResponseInfo(activeQuestion, assessmentResponse, isAnswered)
  let next = activeQuestion?.options?.[0]?.action?.next?.default
  if (stepProgressInfo.isAnswered) {
    next = stepProgressInfo.next
  }
  if (next) {
    const nextQuestion = questions?.find((q) => q.id === next)
    const info = getAssessmentProgress(
      questions,
      nextQuestion,
      assessmentResponse,
      stepProgressInfo.isAnswered,
      sum + 1
    )
    info.stepProgressInfo.unshift(stepProgressInfo)
    return info
  } else if (!stepProgressInfo.isAnswered && !next) {
    return { stepProgressInfo: [stepProgressInfo], sum: sum + 1 }
  } else {
    return { stepProgressInfo: [stepProgressInfo], sum }
  }
}

export const getBusinessProfileAssessmentsSelector = createSelector(
  [getAssessmentQuestion],
  (assessment) => {
    return assessment.filter((question) => question.meta.showToBusinessProfile)
  }
)

export const getAssessmentResponseSelector = createSelector(
  [getAssessmentResponse],
  (assessment) => {
    if (assessment?.assessmentResponse) {
      const response = {}
      assessment?.assessmentResponse.forEach((answer) => {
        response[answer.id] = answer.value
      })
      return response
    }
    return {}
  }
)

export const getAssessmentQuestionSelector = createSelector(
  [getAssessmentQuestion, getActiveQuestion],
  (questions, activeQuestion) => {
    let stepIndex = 0,
      questionIndex = 0,
      remainingSteps = 0,
      totalSteps = 0,
      progressBarIndex = 0

    if (questions.length && !isEmpty(activeQuestion)) {
      remainingSteps = getUniqueQuestions(questions, activeQuestion)
      totalSteps = getUniqueQuestions(questions, questions[0])
      progressBarIndex = totalSteps - remainingSteps
    }

    return {
      stepIndex,
      questionIndex,
      progressBarIndex: progressBarIndex > 0 ? progressBarIndex + 1 : 0,
      questions,
      totalSteps,
    }
  }
)

export const getCurrentGoalSelector = createSelector([getOwnerGoals], (goals) => {
  const pathname = history.location.pathname.split('/')
  const currentGoalId = pathname[pathname?.length - 1]
  const currentGoal = goals?.getUserGoals?.find((res) => res.id === currentGoalId)
  return currentGoal
})

export const getAssessmentProgressSelector = createSelector(
  [getAssessmentQuestion, getActiveQuestion, getAssessmentResponse],
  (questions, activeQuestion, assessmentResponse) => {
    const { sum: remainingSteps } = getAssessmentProgress(
      questions,
      activeQuestion,
      assessmentResponse
    )
    let { stepProgressInfo, sum: totalSteps } = getAssessmentProgress(
      questions,
      questions[0],
      assessmentResponse
    )
    const progressBarIndex = totalSteps - remainingSteps
    const ProgressTexts = {}
    stepProgressInfo = stepProgressInfo.filter((info) => {
      if (info.value in ProgressTexts) return false
      else {
        ProgressTexts[info.value] = true
        return true
      }
    })

    return {
      stepIndex: 0,
      stepProgressInfo,
      progressBarIndex: progressBarIndex > 0 ? progressBarIndex + 1 : 0,
      totalSteps,
      questions,
      remainingSteps,
    }
  }
)

export const getAssessmentQuestionOption = createSelector(
  [assessmentResponseByQuestion],
  (assessmentQuestion) => {
    const value = assessmentQuestion?.value

    let parsedQuestion = JSON.parse(assessmentQuestion?.question || '[]')
    const selectedOption = parsedQuestion?.options?.find((opt) => opt.id === value)

    return { icon: selectedOption?.icon, value: selectedOption?.value }
  }
)

export const getBigQuerySelector = createSelector([getBigQuery], (bigQuery) => {
  try {
    let arr: any = []

    if (bigQuery && bigQuery.length > 0) {
      bigQuery.forEach((obj) => {
        let record = {
          name: obj.first_name + ' ' + obj.last_name,
          sales: obj.sales,
          email: obj.email,
          customer_id: obj.customer_id,
        }

        arr.push(record)
      })

      return arr
    } else {
      return []
    }
  } catch (error: any) {
    console.log('error: (getBigQuerySelector): ', error.message)
    return []
  }
})
