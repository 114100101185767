import React from 'react'
import ContentLoader from 'react-content-loader'

import { colorsPallete } from 'config'

const BoxLoader = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 100 100"
      width={200}
      height={200}
      backgroundColor={`${colorsPallete.cream.dark}`}
      foregroundColor={`${colorsPallete.grey.lighter}`}
    >
      <rect x="-10" y="-10" rx="8" ry="8" width="200" height="200" />
    </ContentLoader>
  )
}

export default BoxLoader

export const CardLoader = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 235 290"
      width={235}
      height={290}
      backgroundColor={`${colorsPallete.cream.dark}`}
      foregroundColor={`${colorsPallete.grey.lighter}`}
    >
      <rect x="0" y="20" rx="8" ry="8" width="221" height="15" />
      <rect x="0" y="70" rx="8" ry="8" width="235" height="15" />
      <rect x="0" y="100" rx="8" ry="8" width="210" height="15" />
      <circle cx="110" cy="220" r="52" />
    </ContentLoader>
  )
}
