import React, { useEffect } from 'react'

import InputField from 'components/Common/FormFields/InputField'

const EditUserProfile = ({ checkErrors, register, errors }) => {
  useEffect(() => {
    checkErrors(errors)
    // eslint-disable-next-line
  })
  return (
    <>
      <div className="xs:col-span-12 sm:col-span-6">
        <InputField
          errors={errors.firstName}
          type={'text'}
          label="FIRST NAME"
          defaultValue={''}
          {...register('firstName')}
        />
      </div>
      <div className="xs:col-span-12 sm:col-span-6">
        <InputField
          errors={errors.lastName}
          type={'text'}
          label="LAST NAME"
          defaultValue={''}
          {...register('lastName')}
        />
      </div>
      <div className="xs:col-span-12 sm:col-span-6">
        <InputField
          errors={errors.email}
          type={'email'}
          label="EMAIL"
          defaultValue={''}
          {...register('email')}
          className={'border-primary-outline hover:!border-primary-outline'}
          inputFieldBgColor="bg-black"
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <InputField
          errors={errors.mobileContactNumber}
          type="text"
          mask="(999) 999-9999"
          label="PHONE NUMBER"
          defaultValue={''}
          {...register('mobileContactNumber')}
        />
      </div>
    </>
  )
}
export default EditUserProfile
