import { call, put, takeLatest } from 'redux-saga/effects'
import UserApi from 'api/user'
import AssessmentApi from 'api/assessment'
import history from 'utils/history'

import {
  getUsersAction,
  addBusinessAccountAction,
  setAdvisorActiveStepAction,
  setBusinessOwnerActiveStepAction,
} from 'store/actions/admin'

import {
  ADD_ADVISOR_ACCOUNT,
  ADD_BUSINESS_ACCOUNT,
  GET_USERS,
  RESET_ADVISOR_BUSINESS_FORMS,
} from 'store/types'
import { resetProgressbarAction, updateProgressbarAction } from 'store/actions/common/progressbar'
import { CREATE_BUSINESS_ACCOUNT_STEPS, ERROR_MESSAGE_MAPPING, FORM } from 'config'

import hookForms from 'utils/hookForms'
import { ampli } from 'ampli'

const getAdvisorOrOwnerList = (addUser) => {
  if (addUser && addUser.length > 0) {
    return JSON.stringify(addUser)
  } else {
    return JSON.stringify([])
  }
}

function* getUsers(action) {
  try {
    const users = {}
    const { roles } = action.payload
    for (let i = 0; i < roles.length; i++) {
      const data = yield call(UserApi.getCognitoUsers, { role: roles[i] })
      users[roles[i]] = data.listCognitoUsers
    }
    yield put(getUsersAction.FULLFILLED({ ...users }))
  } catch (error) {
    console.log('error')
  }
}

function* addBusinessAccount(action) {
  const data = history.location.pathname.split('/')
  try {
    let payload = action.payload

    const {
      email,
      firstName,
      lastName,
      businessName,
      mobileContactNumber,
      yearsInBusiness,
      assessmentResponse,
    } = action.payload
    let { addUser } = action.payload
    addUser = getAdvisorOrOwnerList(addUser)
    const tenantId = data[2]

    const userResponse = yield call(UserApi.createUser, {
      firstName,
      lastName,
      email,
      password: 'oldPassword123',
      role: 'BUSINESS_OWNER',
      advisors: addUser,
      tenantId: tenantId,
    })
    payload.userId = userResponse?.createUser.id

    yield call(AssessmentApi.saveAssessmentResponse, {
      userId: payload.userId,
      assessmentResponse,
      type: 'initial',
    })

    const userProfile = yield call(UserApi.updateUserProfile, {
      userId: payload.userId,
      firstName,
      lastName,
      isNewUser: true,
      mobileContactNumber,
    })

    yield call(UserApi.updateBusinessProfile, {
      id: payload.userId,
      businessName,
      yearsInBusiness,
    })

    yield put(addBusinessAccountAction.FULLFILLED({ userProfile }))
    yield put(setBusinessOwnerActiveStepAction('Account Created'))
    yield put(updateProgressbarAction())

    ampli.accountCreated({
      roles: ['BUSINESS_OWNER'],
      id: payload.userId,
      email: email,
      firstName: firstName,
      lastName: lastName,
      supportOrgIds: [tenantId],
    })
  } catch (error: any) {
    const errorMessage = ERROR_MESSAGE_MAPPING[error.message.slice(0, -1)]
    if (errorMessage) error.message = errorMessage
    yield put(addBusinessAccountAction.REJECTED({ error }))
  }
}

function* addAdvisorAccount(action) {
  const data = history.location.pathname.split('/')
  try {
    let payload = action.payload

    const { email, adminAccess, firstName, lastName, mobileContactNumber } = action.payload
    let { addUser } = action.payload
    addUser = getAdvisorOrOwnerList(addUser)
    const tenantId = data[2]

    const userResponse = yield call(UserApi.createUser, {
      firstName,
      lastName,
      email,
      phone: mobileContactNumber,
      password: 'oldPassword123',
      role: 'BSO_ADVISOR',
      owners: addUser,
      tenantId: tenantId,
    })

    payload.userId = userResponse?.createUser.id

    if (adminAccess) {
      yield call(UserApi.addUserToGroup, {
        role: 'BSO_ADMIN',
        userName: payload.userId,
      })
      ampli.accountCreated({
        roles: ['BSO_ADVISOR', 'BSO_ADMIN'],
        id: payload.userId,
        email: email,
        firstName: firstName,
        lastName: lastName,
        supportOrgIds: [tenantId],
      })
    } else {
      ampli.accountCreated({
        roles: ['BSO_ADVISOR'],
        id: payload.userId,
        email: email,
        firstName: firstName,
        lastName: lastName,
        supportOrgIds: [tenantId],
      })
    }

    const userProfile = yield call(UserApi.updateUserProfile, {
      userId: payload.userId,
      firstName,
      lastName,
      mobileContactNumber,
      isNewUser: true,
    })

    yield put(setAdvisorActiveStepAction('Account Created'))
    yield put(addBusinessAccountAction.FULLFILLED({ userProfile }))
    yield put(updateProgressbarAction())
  } catch (error: any) {
    const errorMessage = ERROR_MESSAGE_MAPPING[error.message.slice(0, -1)]

    if (errorMessage) error.message = errorMessage
    yield put(addBusinessAccountAction.REJECTED({ error }))
  }
}

function* resetAdvisorAndBusinessForms(action) {
  yield put(resetProgressbarAction())
  yield put(setAdvisorActiveStepAction(CREATE_BUSINESS_ACCOUNT_STEPS.PROFILE_FORM))
  yield put(setBusinessOwnerActiveStepAction(CREATE_BUSINESS_ACCOUNT_STEPS.PROFILE_FORM))
  const profileForm = hookForms.getForm(FORM.PROFILE_FORM)
  const businessForm = hookForms.getForm(FORM.BUSINESS_PROFILE)
  const businessAssessmentForm = hookForms.getForm(FORM.BUSINESS_ASSESSMENT_FORM)
  const advisorForm = hookForms.getForm(FORM.ADVISOR_FORM)
  const adminAccessForm = hookForms.getForm(FORM.ADMIN_ACCESS)
  const assignOwnersForm = hookForms.getForm(FORM.OWNERS)
  businessAssessmentForm?.reset()
  profileForm?.reset()
  businessForm?.reset()
  advisorForm?.reset()
  adminAccessForm?.reset()
  assignOwnersForm?.reset()
}

/// /////////// Watchers ///////////////////////
export function* watcherAdmin() {
  yield takeLatest(GET_USERS.STARTED, getUsers)
  yield takeLatest(ADD_BUSINESS_ACCOUNT.STARTED, addBusinessAccount)
  yield takeLatest(ADD_ADVISOR_ACCOUNT.STARTED, addAdvisorAccount)
  yield takeLatest(RESET_ADVISOR_BUSINESS_FORMS, resetAdvisorAndBusinessForms)
}
