import _ from 'lodash'
import React, { FC, memo } from 'react'
import Select, { components, MultiValueRemoveProps } from 'react-select'
import { Label, ErrorMessage } from 'components/Common/StyledComponents'
import CrossIcon from 'components/Common/SvgIcons/CrossIcon'

export interface IInputFieldProps {
  name?: string
  label?: string
  placeholder?: string
  error?: { message: string }
  options: Array<{ label: any; value: any }>
  hidden?: boolean
  onChange?: any
  value?: any
  errors?: any
  isMulti?: boolean
  classes?: any
  applyStyle?: any
  onInputChange?: any
  isSearchable?: boolean
  components?: any
  closeMenuOnSelect?: boolean
  blurInputOnSelect?: boolean
  filterOption?: any
  menuPortalTarget?: boolean
  disable?: boolean
}

const MultiValueRemove = (props: MultiValueRemoveProps<any>) => {
  return (
    <components.MultiValueRemove {...props}>
      <CrossIcon className="fill-black/50 hover:fill-black  h-[8px] w-[8px] cursor-pointer" />
    </components.MultiValueRemove>
  )
}

const SelectField: FC<IInputFieldProps> = ({
  name,
  label,
  placeholder,
  error,
  options,
  hidden,
  errors,
  onChange,
  isMulti,
  classes,
  value,
  components,
  applyStyle,
  closeMenuOnSelect,
  blurInputOnSelect,
  menuPortalTarget,
  disable,
  ...rest
}) => {
  const selectHandleChange = (option) => {
    isMulti ? onChange(option.map((options) => options.value)) : onChange(option.value)
  }

  const getSelected = () => {
    if (isMulti) {
      const selectedValue: any = []
      for (let i = 0; i < value?.length; i++) {
        const option = _.find(options, { value: value[i] })
        selectedValue.push(option)
      }
      return selectedValue
    } else {
      return _.find(options, { value: value }) || null
    }
  }

  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        border: '1px solid #e5e7eb;',
        boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      }
    },
    input: (provided, state) => {
      return {
        ...provided,
        fontSize: '16px',
      }
    },
    valueContainer: (provided, state) => {
      return {
        ...provided,
        fontSize: '16px',
      }
    },
    option: (provided, state) => {
      return {
        ...provided,
        fontSize: '16px',
        color: '#3F3F46',
        backgroundColor: state.isSelected ? '#F4F4F5' : 'white',
        '&:hover': {
          backgroundColor: '#F4F4F5',
        },
      }
    },
    menuPortal: (base, state) => {
      return {
        ...base,
        zIndex: 9999,
      }
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: 'rgba(91, 186, 185, 0.5)',
        paddingTop: '3px',
        paddingBottom: '3px',
        paddingLeft: '8px',
        paddingRight: '8px',
        color: '#3F3F46',
        borderRadius: '53px',
        fontFamily: 'Inter',
        fontSize: '16px',
      }
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      ':hover': {
        color: '#000',
      },
    }),
  }

  return (
    <>
      <div className={`${hidden ? 'hidden' : ''} ${classes}`}>
        {label && (
          <Label title={label} className="inline-block mb-[6px]">
            {label}
          </Label>
        )}
        <div className="selectWrapper">
          <Select
            {...rest}
            menuPortalTarget={menuPortalTarget ? document.body : undefined}
            styles={customStyles}
            menuPlacement="auto"
            placeholder={`${placeholder ? placeholder : ''}`}
            value={getSelected()}
            classNamePrefix="select"
            name={name}
            options={options}
            onChange={selectHandleChange}
            isMulti={isMulti}
            className="selectFieldData"
            components={
              components
                ? {
                    ...components,
                    MultiValueRemove,
                  }
                : {
                    IndicatorSeparator: () => null,
                    MultiValueRemove,
                  }
            }
            blurInputOnSelect={blurInputOnSelect}
            closeMenuOnSelect={closeMenuOnSelect}
            isDisabled={disable}
          />
        </div>
      </div>
      {errors && <ErrorMessage id={name}>{errors.message}</ErrorMessage>}
    </>
  )
}

SelectField.defaultProps = {
  menuPortalTarget: true,
}

export default memo(SelectField)
