import React from 'react'

function Book(props) {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 15.1375C9.4986 15.6686 8.788 16 8 16H1.75C0.7835 16 0 15.2165 0 14.25V1.75C0 0.7835 0.7835 0 1.75 0H8C8.788 0 9.4986 0.33145 10 0.86253C10.5014 0.33145 11.212 0 12 0H18.25C19.2165 0 20 0.7835 20 1.75V14.25C20 15.2165 19.2165 16 18.25 16H12C11.212 16 10.5014 15.6686 10 15.1375ZM1.5 1.75V14.25C1.5 14.3881 1.61193 14.5 1.75 14.5H8C8.6904 14.5 9.25 13.9404 9.25 13.25V2.75C9.25 2.05964 8.6904 1.5 8 1.5H1.75C1.61193 1.5 1.5 1.61193 1.5 1.75ZM10.75 13.25C10.75 13.9404 11.3096 14.5 12 14.5H18.25C18.3881 14.5 18.5 14.3881 18.5 14.25V1.75C18.5 1.61193 18.3881 1.5 18.25 1.5H12C11.3096 1.5 10.75 2.05964 10.75 2.75V13.25Z"
        fill="#212121"
      />
    </svg>
  )
}

export default Book
