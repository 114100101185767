import * as yup from 'yup'

export const profileFormSchema = yup.object({
  firstName: yup.string().required('You must Enter First Name'),
  lastName: yup.string().required('You must Enter Last Name'),
  businessName: yup.string().required('You must Enter Business Name'),
  mobileContactNumber: yup
    .string()
    .matches(
      /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{5}$/i,
      'Please enter a valid mobile phone number'
    )
    .required('You must Enter Phone Number'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
  year: yup.number().required('You must enter year'),
})

export const myProfileSchema = yup.object({
  firstName: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed for name ')
    .required('You must Enter First Name'),
  mobileContactNumber: yup
    .string()
    .matches(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i, 'Please enter a valid phone number')
    .required('Please enter a valid phone number'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('You must Enter Email Address'),
})

export const profileSchema = yup.object({
  firstName: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, 'First Name cannot be empty')
    .required('You must Enter Last Name'),
  lastName: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, 'Last Name cannot be empty ')
    .required('You must Enter Last Name'),
  mobileContactNumber: yup
    .string()
    .matches(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i, 'Phone Number is required')
    .required(''),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email Address is required'),
})
export const businessSchema = yup.object({
  businessName: yup.string().required('You must Enter Business Name'),

  yearsInBusiness: yup
    .string()
    .matches(/^(?!00)[0-9]{1,4}$/i, 'year must be between 0 to 1000')
    .required('You must enter year'),
})

export const businessProfileSchema = yup.object({
  businessName: yup.string().required('You must Enter Business Name'),
  yearsInBusiness: yup
    .string()
    .matches(/^(?!00)[0-9]{1,4}$/i, 'year must be between 0 to 1000')
    .required('You must enter year'),
})

export const businessOtherDetailSchema = yup.object({
  businessVision: yup.string().required('You must Enter business Vision'),
  challenges: yup.string().required('You must Enter challenges'),
})
