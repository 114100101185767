import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class Play_API {
  getPlay = (id): Promise<any> => {
    return API.fetch(api['GetPlayDocument'], { id })
  }
  getPlays = (input: api.GetPlaysInput): Promise<any> => {
    return API.fetch(api['GetPlaysDocument'], { input })
  }
  authorizeConnector = (authValue) => {
    return API.fetch(api['AuthorizeConnectorDocument'], { authValue: authValue })
  }
}

// eslint-disable-next-line
export default new Play_API()
