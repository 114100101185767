import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ChannelList } from 'stream-chat-react'
import { ChatIcon } from 'components/Common/SvgIcons'
import InputField from 'components/Common/FormFields/InputField'
import SearchIcon from 'components/Common/SvgIcons/SearchIcon'
import { MessagingChannelList, MessagingChannelPreview } from 'components/Chat/components'
import { useUnreadContext } from 'components/Chat/contexts/UnreadContext'

const ChannelListHeader = ({
  userId,
  usersAssessmentResponseByQuestion,
  setShowChannelCreate,
  showChannelCreate,
  setShowChannelPreview,
  showChannelPreview,
  chatUnreadMessageCountAction,
}) => {
  const { tenantId } = useParams()
  const { chatsUnreadCount, setChatsUnreadCount, channelMessageCount, setChannelMessageCount } =
    useUnreadContext()
  const [channelFilters]: any = useState({
    type: 'messaging',
    $and: [{ team: { $in: [tenantId] } }, { members: { $in: [userId] } }],
  })

  const [searchChannel, setSearchChannel] = useState('')
  const [searchChannelTemp, setSearchChannelTemp] = useState('')
  const [triggerSearch, setTriggerSearch] = useState(false)

  const customRenderFilter = (channels) => {
    try {
      const getTotalChatUnreadCount = channels
        .map((channel) => {
          channelMessageCount[channel.cid] = channel.countUnread()
          return channel.countUnread()
        })
        .reduce((total, count) => total + count, 0)

      setChatsUnreadCount(getTotalChatUnreadCount)
      setChannelMessageCount(channelMessageCount)

      chatUnreadMessageCountAction(getTotalChatUnreadCount)

      return channels
    } catch (err) {
      return channels
    }
  }

  const searchQueryHandler = (e) => {
    if (e.key === 'Enter') {
      setSearchChannelTemp(searchChannel)
    }
  }

  return (
    <div
      className={`messaging__sidebar xs:
    ${showChannelCreate ? 'hidden' : 'col-span-12'} 
    mdl:col-span-4 mdl:block xl:col-span-3 `}
    >
      <span className="flex justify-between items-center">
        <label className="text-3xl font-primary text-primary-text py-3 bg-cream-dark">Chat</label>
        <div className="mdl:hidden">
          <span className="pr-4">
            <button
              onClick={() => {
                setShowChannelCreate(!showChannelCreate)
                setShowChannelPreview(!showChannelPreview)
              }}
            >
              <ChatIcon className={'self-end fill-primary'} />
            </button>
          </span>
        </div>
      </span>

      <div className="pr-5">
        <div className={` flex justify-between my-5 items-center xs:pb-3`}>
          <InputField
            type={'search'}
            placeholder="Search"
            className="w-full xs:pr-0 sm:pr-10 md:pr-10 mdl:pr-0"
            name="search"
            value={searchChannel}
            onChange={(e) => {
              setSearchChannel(e.target.value)
              if (e.target.value === '') {
                setSearchChannelTemp('')
              }
            }}
            onKeyPress={searchQueryHandler}
          />
          <div
            onClick={() => {
              setTriggerSearch(!triggerSearch)
              setSearchChannelTemp(searchChannel)
            }}
          >
            <SearchIcon className={`fill-primary ml-2`} />
          </div>
        </div>

        <div className="flex items-center justify-between mb-3">
          <span className="flex items-center justify-between">
            <label className="text-2xl font-primary text-primary-text mr-3">Messages</label>
            <span className="mt-[1px] inline-flex justify-center items-center bg-primary text-white w-[26px] h-[22px] rounded-3xl text-xs font-bold">
              {chatsUnreadCount}
            </span>
          </span>

          <span
            onClick={() => setShowChannelCreate(!showChannelCreate)}
            className="cursor-pointer hidden  mdl:flex  mdl:relative mdl:items-center mdl:justify-between"
          >
            <ChatIcon
              className={`${showChannelCreate ? 'fill-primary-disabled' : 'fill-primary'}`}
            />
          </span>
        </div>
      </div>

      <span
        className={`xs:${!showChannelPreview ? 'col-span-12' : 'hidden'} mdl:block mdl:col-span-12`}
        onClick={() => setShowChannelPreview(!showChannelPreview)}
      >
        <ChannelList
          channelRenderFilterFn={customRenderFilter}
          filters={channelFilters}
          List={MessagingChannelList}
          Preview={(props) => (
            <MessagingChannelPreview
              {...props}
              usersAssessmentResponseByQuestion={usersAssessmentResponseByQuestion}
              setShowChannelCreate={setShowChannelCreate}
              searchChannelTemp={searchChannelTemp}
              chatUnreadMessageCountAction={chatUnreadMessageCountAction}
            />
          )}
        />
      </span>
    </div>
  )
}

export default ChannelListHeader
