import { call, put, takeLatest, select } from 'redux-saga/effects'
import get from 'lodash/get'
import size from 'lodash/size'
import isNil from 'lodash/isNil'
import { getAuthToken } from 'lib/auth'
import UserApi from 'api/user'
import history from 'utils/history'
import { IUSER_NOTIFICATION_STATUS } from 'components/NotificationPanel/types'

import {
  getChatUsersAction,
  getUserBusinessProfileAction,
  getUserProfileAction,
  updateUserBusinessProfileAction,
  updateUserProfileAction,
  chatUnreadMessageCountAction,
  getUserNoticationsAction,
  updateUserNotificationsAction,
  deleteUserNotificationsAction,
  getClientsSummaryAction,
} from 'store/actions/user'
import {
  CHANGE_PASSWORD,
  GET_CHAT_USERS,
  GET_USER_BUSINESS_PROFILE,
  GET_USER_PROFILE,
  LOG_USER_ACTIVITY,
  UPDATE_USER_BUSINESS_PROFILE,
  UPDATE_USER_PROFILE,
  CHAT_UNREAD_MESSAGE_COUNT,
  GET_UNREAD_NOTIFICATIONS,
  GET_USER_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS,
  DELETE_USER_NOTIFICATIONS,
  CLIENTS_SUMMARY,
} from 'store/types'

import { getIsAllowCookies, saveUser } from 'utils/user'
import {
  showMyProfileAction,
  showBussinessProfileEditModeAction,
} from 'store/actions/owner/account'
import { setActiveStepAction } from 'store/actions/common/stepper'
import pickBy from 'lodash/pickBy'
import filter from 'lodash/filter'
import { USER_ROLES, ROLES, formKeys } from 'config'
import { logoutAction } from 'store/actions/auth'
import { changePasswordAction } from 'store/actions/common'
import { setFormData, setProfileAction } from 'store/actions/form'
import CalendarApi from 'api/calendar'
import ClientsApi from 'api/clients'

function* getUserProfile(action) {
  try {
    const res = yield call(
      UserApi.getUserProfile,
      (action?.payload?.tenantId, action?.payload?.userId)
    )

    const user = res?.getMyProfile
    const userProfile = res?.getMyProfile
    yield put(
      getUserProfileAction.FULLFILLED(action.payload === undefined ? { user } : { userProfile })
    )
    if (action.payload?.setForms?.length > 0) {
      const { setForms } = action.payload
      const payload = { forms: setForms, profile: res?.getMyProfile }
      yield put(setProfileAction(payload))
    }
  } catch (error) {
    console.log('get user profile error  : ', error)
  }
}

function* updateUserProfile(action) {
  const pathname = history.location.pathname
  const tenantId = action.payload.tenantId
  const steps = yield select((state) => state.stepper.steps)
  const activeStep = yield select((state) => state.stepper.activeStep)
  try {
    const { nextStep } = action.payload
    delete action.payload.nextStep
    yield call(UserApi.updateUserProfile, action.payload)
    const userProfile = yield call(UserApi.getUserProfile)
    if (nextStep === 'updateStepper') {
      const indexofActiveStep = steps.findIndex((step) => step?.value === activeStep?.value)
      history.replace(`/advisor/${tenantId}/onbording/${steps[indexofActiveStep + 1].value}`)
      yield put(setActiveStepAction(steps[indexofActiveStep + 1]))
    } else if (pathname === `/owner/${tenantId}/account`) {
      yield put(getUserProfileAction.STARTED(action.payload.id))
      yield put(showMyProfileAction(false))
    } else {
      const isAllow = getIsAllowCookies()
      if (isAllow) {
        saveUser(userProfile)
      }
      if (get(userProfile, 'getMyProfile.isNewUser', false)) {
        history.push('/welcome')
      } else {
        if (get(userProfile, 'getMyProfile.roles[0]', '')) {
          yield put(showMyProfileAction(false))
          const role = userProfile.getMyProfile.roles[0].toUpperCase()
          const path = `/${ROLES[`${role}`]}/${tenantId}/dashboard`
          history.push(path)
        }
      }
    }
    yield put(updateUserProfileAction.FULLFILLED(userProfile))
  } catch (error: any) {
    console.log('error: ', error.message)
    yield put(updateUserProfileAction.REJECTED(error.message))
  }
}

function* getUserBusinessProfile(action) {
  try {
    const res = yield call(UserApi.getBusinessProfile, action.payload.userId)
    yield put(getUserBusinessProfileAction.FULLFILLED(res?.getBusinessProfile))
    if (action.payload?.setForms?.length > 0) {
      const { setForms } = action.payload
      yield put(
        setFormData({
          form: setForms[0],
          data: pickBy(res.getBusinessProfile, (value, key) => formKeys[setForms[0]].includes(key)),
        })
      )
    }
  } catch (error) {
    console.log('error : ', error)
  }
}

function* updateUserBusinessProfile(action) {
  try {
    const response = yield call(UserApi.updateBusinessProfile, action.payload)
    if (response) {
      yield put(getUserBusinessProfileAction.STARTED({ userId: action.payload.id }))
      yield put(showBussinessProfileEditModeAction(false))
    }
    yield put(updateUserBusinessProfileAction.FULLFILLED())
  } catch (error: any) {
    console.log('error :', error)
    yield put(updateUserBusinessProfileAction.REJECTED(error.message))
  }
}

function* changePassword(action) {
  try {
    yield call(UserApi.updatePassword, action.payload)
    yield put(changePasswordAction.FULLFILLED(false))
    yield put(logoutAction())
  } catch (error) {
    yield put(changePasswordAction.REJECTED())
    console.log(' user password update error  : ', error)
  }
}

function* getChatUsers(action) {
  try {
    let { tenantId } = action.payload

    const userProfile = yield select((state) => state.user.user)
    const role = userProfile.roles[0]

    let payload: any = {}
    payload.tenantId = tenantId

    let users: any = []
    if (role === USER_ROLES.BUSINESS_OWNER) {
      let advisors = yield call(CalendarApi.getBoAdvisors)
      advisors = get(advisors, 'getBoAdvisors.data', [])

      if (advisors.length > 0) {
        users.push(...advisors)
      }
    } else if (role === USER_ROLES.BSO_ADVISOR) {
      payload.advisorId = userProfile.id
      let res = yield call(ClientsApi.getClients, payload)
      res = get(res, 'getClients.data')

      if (res) {
        users = [...res]
      }
    }

    let usersPayload = {
      glRole: role,
      users,
    }

    yield put(getChatUsersAction.FULLFILLED(usersPayload))
  } catch (error) {
    yield put(changePasswordAction.REJECTED())
    console.log(' user password update error  : ', error)
  }
}

function* logUserActivity(action) {
  try {
    const authToken = yield getAuthToken()

    yield call(UserApi.logActivity, {
      action: get(action, 'payload.action', ''),
      logStatus: get(action, 'payload.logStatus', ''),
      accessToken: authToken,
      tenantId: get(action, 'payload.tenantId', ''),
      showClientActivity: get(action, 'payload.showClientActivity', false),
    })
  } catch (err) {
    console.log(err)
  }
}

// chat unread message count
function* chatUnreadMessageCount(action) {
  try {
    yield put(chatUnreadMessageCountAction.FULLFILLED(action.payload))
  } catch (err) {
    console.log(err)
  }
}

// chat unread message count
function* getUnreadNotifications() {
  try {
    const user = yield select((state) => ({
      tenantId: state.user.user.tenantId[0],
    }))

    const payload = {
      tenantId: user.tenantId,
      isPaginated: false,
      filter: IUSER_NOTIFICATION_STATUS.UNREAD,
    }

    let data = yield call(UserApi.getUserNotifications, payload)

    if (get(data, 'getUserNotifications.data')) {
      const notifications = get(data, 'getUserNotifications.data')
      yield put(getUserNoticationsAction.FULLFILLED({ totalUnread: size(notifications) }))
    }
  } catch (error) {
    console.log('get User Notifications error : ', error)
  }
}

function* getUserNotifications(payloadData?: any) {
  try {
    const user = yield select((state) => ({
      tenantId: state.user.user.tenantId[0],
      data: state.user.notifications.data,
      pagination: state.user.notifications.pagination,
    }))

    let lastEvaluatedKey: any = undefined
    if (
      get(user, 'pagination') &&
      payloadData &&
      payloadData?.payload &&
      payloadData?.payload?.viewMore
    ) {
      const pagination = JSON.parse(get(user, 'pagination'))
      if (!get(pagination, 'lastEvaluatedKey')) return
      lastEvaluatedKey = JSON.stringify(get(pagination, 'lastEvaluatedKey'))
    }
    const payload = {
      tenantId: user.tenantId,
      pageSize: 10,
      isPaginated: true,
      lastEvaluatedKey,
    }

    let data = yield call(UserApi.getUserNotifications, payload)

    if (get(data, 'getUserNotifications.data')) {
      const notifications = get(data, 'getUserNotifications.data')
      const pagination = get(data, 'getUserNotifications.pagination')

      if (payloadData?.payload?.viewMore) {
        yield put(
          getUserNoticationsAction.FULLFILLED({
            data: [...get(user, 'data', []), ...get(data, 'getUserNotifications.data', [])],
            pagination,
          })
        )
      } else {
        const totalCount = get(JSON.parse(pagination), 'totalCount')
        yield put(
          getUserNoticationsAction.FULLFILLED({ data: notifications, pagination, totalCount })
        )
      }
    } else {
      yield put(getUserNoticationsAction.FULLFILLED({ data: [], pagination: '' }))
    }
  } catch (error) {
    console.log('get User Notifications error : ', error)
  }
}

function* updateUserNotifications(payload) {
  try {
    const user = yield select((state) => ({
      tenantId: state.user.user.tenantId[0],
    }))
    const apiData = {
      id: payload?.id,
      status: payload.status || 'read',
      updateAll: isNil(payload?.id),
      tenantId: user.tenantId,
    }
    yield call(UserApi.updateUserNotifications, apiData)
    yield put(updateUserNotificationsAction.FULLFILLED())
    yield call(getUnreadNotifications)
  } catch (error) {
    console.log('updateUserNotifications error : ', error)
  }
}

function* deleteUserNotifications(payload) {
  const user = yield select((state) => ({
    tenantId: state.user.user.tenantId[0],
    data: state.user.notifications.data,
    totalCount: state.user.notifications.totalCount,
  }))
  const apiData = {
    id: payload?.payload?.id,
    deleteAll: isNil(payload?.payload?.id),
    tenantId: user.tenantId,
  }
  if (apiData.id) {
    const payload = {
      data: filter(user.data, (data) => data.id !== apiData.id),
      totalCount: user.totalCount - 1,
    }
    yield put(deleteUserNotificationsAction.FULLFILLED(payload))
    yield call(UserApi.deleteUserNotifications, apiData)
    return
  }
  yield call(UserApi.deleteUserNotifications, apiData)
  yield call(getUserNotifications)
}

function* getClientsSummary() {
  try {
    const user = yield select((state) => ({
      tenantId: state.user.user.tenantId[0],
    }))
    let data = yield call(UserApi.getClientsSummary, { tenantId: user.tenantId })
    if (get(data, 'getClientsSummary.data')) {
      yield put(getClientsSummaryAction.FULLFILLED(JSON.parse(get(data, 'getClientsSummary.data'))))
    }
  } catch (error) {
    console.log('get Client Summary error : ', error)
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherUser() {
  yield takeLatest(GET_USER_PROFILE.STARTED, getUserProfile)
  yield takeLatest(UPDATE_USER_PROFILE.STARTED, updateUserProfile)
  yield takeLatest(GET_USER_BUSINESS_PROFILE.STARTED, getUserBusinessProfile)
  yield takeLatest(UPDATE_USER_BUSINESS_PROFILE.STARTED, updateUserBusinessProfile)
  yield takeLatest(CHANGE_PASSWORD.STARTED, changePassword)
  yield takeLatest(GET_CHAT_USERS.STARTED, getChatUsers)
  yield takeLatest(LOG_USER_ACTIVITY, logUserActivity)
  yield takeLatest(CHAT_UNREAD_MESSAGE_COUNT.STARTED, chatUnreadMessageCount)
  yield takeLatest(GET_UNREAD_NOTIFICATIONS.STARTED, getUnreadNotifications)
  yield takeLatest(GET_USER_NOTIFICATIONS.STARTED, getUserNotifications)
  yield takeLatest(UPDATE_USER_NOTIFICATIONS.STARTED, updateUserNotifications)
  yield takeLatest(DELETE_USER_NOTIFICATIONS.STARTED, deleteUserNotifications)
  yield takeLatest(CLIENTS_SUMMARY.STARTED, getClientsSummary)
}
