import {
  GET_USER_CONNECTORS,
  GET_USER_CALENDAR_EVENTS,
  GET_OAUTH_URL,
  RESET_OAUTH_URL,
  GET_CALENDLY_USER,
  DELETE_UPCOMING_EVENTS,
  GET_BO_ADVISOR,
  GET_USER_MEETING,
  SUBSCRIBE_CALENDLY_WEBHOOK,
  HANDLE_CYCLR_OAUTH,
  UPDATE_CALENDAR_MEETING,
  DELETE_GOOGLE_CALENDAR_EVENT,
  UPDATE_GOOGLE_CALENDAR_EVENT,
  SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK,
  CANCEL_OUTLOOK_MEETING,
} from 'store/types'

export const getUserConnectorsAction = {
  STARTED: (payload) => ({
    type: GET_USER_CONNECTORS.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: GET_USER_CONNECTORS.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_USER_CONNECTORS.REJECTED, payload: error }),
}

export const getUserCalendarEventsAction = {
  STARTED: (payload) => ({
    type: GET_USER_CALENDAR_EVENTS.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: GET_USER_CALENDAR_EVENTS.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_USER_CALENDAR_EVENTS.REJECTED, payload: error }),
}

export const getOAuthUrlAction = {
  STARTED: (payload) => ({
    type: GET_OAUTH_URL.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: GET_OAUTH_URL.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_OAUTH_URL.REJECTED, payload: error }),
}

export const getCalendlyUserAction = {
  STARTED: (payload) => ({
    type: GET_CALENDLY_USER.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: GET_CALENDLY_USER.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_CALENDLY_USER.REJECTED, payload: error }),
}

// delete upcoming events of calender
export const deleteUpcomingEventAction = {
  STARTED: (payload) => ({
    type: DELETE_UPCOMING_EVENTS.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: DELETE_UPCOMING_EVENTS.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: DELETE_UPCOMING_EVENTS.REJECTED, payload: error }),
}

export const resetOAuthUrlAction = () => ({ type: RESET_OAUTH_URL })

export const getBoAdvisorAction = {
  STARTED: (payload) => ({ type: GET_BO_ADVISOR.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_BO_ADVISOR.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_BO_ADVISOR.REJECTED, payload }),
}

export const subscribeCalendlyWebhookAction = {
  STARTED: (payload) => ({ type: SUBSCRIBE_CALENDLY_WEBHOOK.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: SUBSCRIBE_CALENDLY_WEBHOOK.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: SUBSCRIBE_CALENDLY_WEBHOOK.REJECTED, payload }),
}
export const subscribeGoogleCalendarWebhookAction = {
  STARTED: (payload) => ({ type: SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK.REJECTED, payload }),
}

export const getUserMeetingAction = {
  STARTED: (payload: any) => ({ type: GET_USER_MEETING.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_USER_MEETING.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_USER_MEETING.REJECTED, payload }),
}

export const handleCyclrOAuthAction = {
  STARTED: (payload: any) => ({ type: HANDLE_CYCLR_OAUTH.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: HANDLE_CYCLR_OAUTH.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: HANDLE_CYCLR_OAUTH.REJECTED, payload }),
}

export const updateCalendarMeetingAction = {
  STARTED: (payload: any) => ({ type: UPDATE_CALENDAR_MEETING.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: UPDATE_CALENDAR_MEETING.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: UPDATE_CALENDAR_MEETING.REJECTED, payload }),
}

export const deleteGoogleCalendarEventAction = {
  STARTED: (payload: any) => ({ type: DELETE_GOOGLE_CALENDAR_EVENT.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: DELETE_GOOGLE_CALENDAR_EVENT.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: DELETE_GOOGLE_CALENDAR_EVENT.REJECTED, payload }),
}
export const updateGoogleCalendarEventAction = {
  STARTED: (payload: any) => ({ type: UPDATE_GOOGLE_CALENDAR_EVENT.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: UPDATE_GOOGLE_CALENDAR_EVENT.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: UPDATE_GOOGLE_CALENDAR_EVENT.REJECTED, payload }),
}

export const cancelOutLookMeetingAction = {
  STARTED: (payload: any) => ({ type: CANCEL_OUTLOOK_MEETING.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: CANCEL_OUTLOOK_MEETING.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: CANCEL_OUTLOOK_MEETING.REJECTED }),
}
