import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'

import { businessSchema } from 'pages/Owner/validation'
import InputField from 'components/Common/FormFields/InputField'

import { FORM } from 'config'
import SelectField from 'components/Common/FormFields/SelectField'
import useFormContainer from 'components/Common/useFormContainer'
import useIndustryContainer from 'components/Common/ProfileComponents/useIndustryContainer'
import Loader from 'components/Loaders'
import useSubIndustryContainer from 'components/Common/ProfileComponents/useSubIndustryContainer'
import hookForms from 'utils/hookForms'
import { checkIndustry } from 'utils/helper'

const EditBusinessProfile = ({
  assessment,
  userId,
  onSubmit,
  checkErrors,
  isIndustryChanged,
  setIsSubIndustrySelected,
  setIndustryChanged,
  isBusinessProfileFormSet,
  getBusinessAndAssessmentResponseAction,
}) => {
  let { tenantId } = useParams()

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(businessSchema),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = methods

  useEffect(() => {
    getBusinessAndAssessmentResponseAction({
      setForms: [FORM.USER_MANAGEMENT_BUSINESS_FORM],
      userId,
      tenantId,
      type: 'initial',
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    checkErrors(errors)
    // eslint-disable-next-line
  }, [Object.keys(errors).length])

  useFormContainer(FORM.USER_MANAGEMENT_BUSINESS_FORM, methods)

  useIndustryContainer({
    methods,
    assessment,
    setIndustryChanged,
    title: 'industry',
    titlePath: 'ownerDetails.title',
  })

  const { industryQuestion, foodIndustryQuestionOption, subIndustryQuestion, profileFormValues } =
    useSubIndustryContainer({
      assessment,
      profileForm: hookForms.getForm(FORM.USER_MANAGEMENT_BUSINESS_FORM),
      reset,
      isIndustryChanged,
      setIsSubIndustrySelected,
    })

  return (
    <>
      {!isBusinessProfileFormSet ? (
        <Loader loader="EditBusinessProfile" />
      ) : (
        <div className="bg-cream-dark pb-6 pt-5 px-7">
          <label className="text-2xl font-primary text-primary-text inline-block pb-2">
            Business Profile
          </label>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-12">
                <InputField
                  errors={errors.businessName}
                  type={'text'}
                  label={'BUSINESS NAME'}
                  defaultValue={''}
                  {...register('businessName')}
                />
              </div>
              <div className="xs:col-span-12 sm:col-span-6 md:col-span-6 mdl:col-span-6 mt-3">
                <InputField
                  errors={errors.yearsInBusiness}
                  min={0}
                  type={'number'}
                  defaultValue={''}
                  label={'YEARS IN BUSINESS'}
                  {...register('yearsInBusiness')}
                />
              </div>
              {assessment?.map((question, index) => {
                const isSubIndustry = checkIndustry(
                  question,
                  profileFormValues,
                  subIndustryQuestion,
                  foodIndustryQuestionOption,
                  industryQuestion
                )
                return (
                  isSubIndustry && (
                    <div
                      className="xs:col-span-12 sm:col-span-6 md:col-span-6 mdl:col-span-6 mt-3"
                      key={index}
                    >
                      <Controller
                        defaultValue={''}
                        name={`${question.id}`}
                        control={control}
                        render={({ field }) => (
                          <SelectField
                            placeholder="Select"
                            {...field}
                            applyStyle
                            isMulti={question?.meta?.allowMultiSelect}
                            label={question?.content?.businessProfile?.description}
                            options={question.options.map((opt) => ({
                              label: opt.value,
                              value: opt.id,
                            }))}
                            classes="font-primary text-silver-darker text-base font-normal"
                          />
                        )}
                      />
                    </div>
                  )
                )
              })}
            </div>
          </form>
        </div>
      )}
    </>
  )
}
export default EditBusinessProfile
