import React from 'react'
import { AdminRightsContainer } from 'pages/Admin/container/UserManagement'
import UserTableHeader from 'components/Common/StyledComponents/UserTableHeader'
import TableActionsComponent from 'components/Admin/UserManagement/TableActions'

export type Columns = IColumn[]

export interface IColumn {
  text?: string
  field?: string
  width?: number
  tableType?: string
  headerName?: string
  direction?: string
  targets?: number
  colSpan?: string
  textDirection?: string
  colFields: IColumnField[]
  renderCell?: (params: any) => JSX.Element
}

interface IColumnField {
  field?: string
  type?: string
  circle?: boolean
  paddingTop?: string
  headerName?: string
  sortable?: boolean
}

export const advisorDataTableColumns: Columns = [
  {
    text: 'records',
    tableType: 'user',
    targets: 1,
    colSpan: 'col-span-3',
    textDirection: 'left',
    colFields: [
      {
        field: 'name',
        type: 'string',
        headerName: 'Name',
        sortable: true,
      },
    ],
    renderCell: (params) => {
      return <UserTableHeader className="col-span-3">{params?.name}</UserTableHeader>
    },
  },
  {
    targets: 2,
    textDirection: 'left',
    colSpan: 'col-span-3',
    colFields: [
      {
        field: 'created',
        type: 'date',
        headerName: 'Created',
        sortable: true,
      },
      {
        field: 'activated',
        type: 'string',
        headerName: 'Activated',
        circle: true,
        sortable: true,
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className="col-span-3">
          <td> {params?.created} </td>
          <td className="capitalize">
            {' '}
            {params?.activated === 'active' ? params?.updated : params?.activated}
          </td>
        </UserTableHeader>
      )
    },
  },
  {
    targets: 1,
    field: '',
    headerName: 'Admin',
    textDirection: 'left',
    colSpan: 'col-span-2',
    colFields: [
      {
        field: '',
        type: 'string',
        headerName: 'Admin',
        paddingTop: 'pt-4',
      },
    ],
    renderCell: (params) => {
      return (
        <td className="p-3 text-sm text-grey whitespace-nowrap col-span-2">
          <AdminRightsContainer params={params} />
        </td>
      )
    },
  },
  {
    targets: 1,
    textDirection: 'right',
    colSpan: 'col-span-4',
    direction: 'justify-end',
    colFields: [
      {
        field: 'action',
        headerName: 'Actions',
        paddingTop: 'justify-end pt-4',
      },
    ],
    renderCell: (params) => {
      return (
        <td className="p-3 text-sm text-grey whitespace-nowrap col-span-4">
          <TableActionsComponent userData={params} />
        </td>
      )
    },
  },
]

export const bigQueryDataTableColumns: Columns = [
  {
    text: 'user',
    tableType: 'user',
    width: 120,
    textDirection: 'left',
    colSpan: 'col-span-4',
    colFields: [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className="col-span-4">
          <td className="font-semibold"> {params?.name} </td>
        </UserTableHeader>
      )
    },
  },
  {
    width: 120,
    textDirection: 'left',
    colSpan: 'col-span-4',
    colFields: [
      {
        field: 'email',
        headerName: 'Email',
        type: 'string',
      },
    ],
    renderCell: (params) => {
      return <UserTableHeader className="col-span-4">{params?.email}</UserTableHeader>
    },
  },
  {
    field: 'sales',
    headerName: 'Sales',
    width: 120,
    textDirection: 'left',
    colSpan: 'col-span-4',

    colFields: [
      {
        field: 'sales',
        headerName: 'Sales',
        type: 'string',
      },
    ],
    renderCell: (params) => {
      return <UserTableHeader className="col-span-4">{params?.sales}</UserTableHeader>
    },
  },
]

export const businessDataTableColumns: Columns = [
  {
    text: 'records',
    tableType: 'user',
    width: 260,
    textDirection: 'left',
    colSpan: 'col-span-3',
    direction: 'items-center',
    colFields: [
      {
        field: 'name',
        type: 'string',
        headerName: 'Name',
        sortable: true,
      },
      {
        field: 'businessName',
        type: 'string',
        headerName: 'Business Name',
        circle: true,
        sortable: true,
      },
    ],
    renderCell: (params) => {
      return (
        // todo - 2 fixes
        // 1) <td> elements are not allowed to be children of divs, and this causes a console error
        // 2) Fallback content when name or businessName is undefined. It's currently possible to see
        // "undefined undefined" in the cell
        <UserTableHeader className="col-span-3">
          <td className="font-semibold"> {params?.name} </td>
          <td> {params?.businessName} </td>
        </UserTableHeader>
      )
    },
  },
  {
    width: 160,
    textDirection: 'left',
    colSpan: 'col-span-3',
    direction: 'items-center',
    colFields: [
      {
        field: 'created',
        type: 'date',
        headerName: 'Created',
        sortable: true,
      },
      {
        field: 'activated',
        type: 'string',
        headerName: 'Activated',
        circle: true,
        sortable: true,
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className="col-span-3">
          <td> {params?.created} </td>
          <td className="capitalize">
            {params?.activated === 'active' ? params?.updated : params?.activated}
          </td>
        </UserTableHeader>
      )
    },
  },
  {
    width: 200,
    textDirection: 'left',
    colSpan: 'col-span-2',
    direction: 'items-center',
    colFields: [
      {
        field: 'advisors',
        type: 'string',
        headerName: 'Advisor',
        paddingTop: 'pt-4',
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className={'!whitespace-pre-wrap col-span-2'}>
          {params?.activated === 'inactive' ? '' : params?.advisors}
        </UserTableHeader>
      )
    },
  },
  {
    textDirection: 'right',
    colSpan: 'col-span-4',
    direction: 'justify-end',
    colFields: [
      {
        field: 'action',
        headerName: 'Actions',
        paddingTop: 'justify-end pt-4',
      },
    ],
    renderCell: (params) => {
      return (
        <td className="p-3 text-sm text-grey whitespace-nowrap  text-right col-span-4">
          <TableActionsComponent userData={params} />
        </td>
      )
    },
  },
]
