import React from 'react'

const LabelValue = ({ title, children }) => {
  return (
    <div className="mt-6">
      <p className="text-silver-darker mb-1 text-sm font-primary font-normal">{title}</p>
      <p className="text-primary-text text-base  font-primary">{children}</p>
    </div>
  )
}

export default LabelValue
