import React from 'react'

const UserCreationContainer = ({ bgColor = 'bg-white', children }) => {
  return (
    <div className={`min-h-full w-full flex justify-center ${bgColor}`}>
      <div className={`xs:p-4 md:p-0 max-w-[700px] w-full justify-center flex-col ${bgColor}`}>
        {children}
      </div>
    </div>
  )
}

export default UserCreationContainer
