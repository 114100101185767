import React from 'react'
import { TabWraper } from './style'

export interface ITabsProps {
  options: ITabOption[]
  selectedTab: ITabOption
  setSelectedTab: (opt: ITabOption) => void
  fontSize?: string
  border?: string
  width?: string
  size?: string
}

interface ITabOption {
  label: string
  link?: string
}

const ButtonTabs = ({
  options,
  setSelectedTab,
  selectedTab,
  size = '',
  width = '',
  border = 'border-[1px] border-white !rounded-tl-[15px] !rounded-tr-[15px]',
  fontSize = '',
}: ITabsProps) => {
  // const getClasses = (opt) => {
  //   let classes = `text-lg cursor-pointer flex border-solid font-normal font-primary xs:w-[50%] sm:w-[45%] mdl:!w-[40%] ${
  //     size === 'xl' ? 'lg:!w-[16%]' : size === 'md' ? 'lg:!w-[20%]' : 'lg:!w-[28%] xl:!w-[21%]'
  //   }`
  //   if (selectedTab.label === opt.label) {
  //     classes = `${classes} bg-primary tabStyle ${fontSize} ${border} ${
  //       size === 'md'
  //         ? 'py-[30px] px-[18px]'
  //         : size === 'xs'
  //         ? `pt-[11px] px-[60px] pb-[27px]`
  //         : size === 'lg' || size === 'xl'
  //         ? 'py-[30px] px-[20px]'
  //         : 'py-[25px] px-[35px]'
  //     } text-white`
  //   } else {
  //     classes = `${classes} bg-cream-dark text-primary-text tabSecStyle ${border} ${fontSize} ${
  //       size === 'md'
  //         ? 'py-[30px] px-[18px]'
  //         : size === 'xs'
  //         ? `pt-[8px] px-[49px] pb-[30px]`
  //         : size === 'lg' || size === 'xl'
  //         ? 'py-[30px] px-[20px]'
  //         : 'py-[25px] px-[35px]'
  //     }`
  //   }
  //   return classes
  // }
  const getClasses = (opt) => {
    let classes = `xs:text-lg sm:text-2xl cursor-pointer flex border-solid font-normal font-primary xs:w-[50%] xs:py-6 sm:px-14 sm:py-8 sm:w-max`
    if (selectedTab.label === opt.label) {
      classes = `${classes} bg-primary tabStyle ${border} text-white`
    } else {
      classes = `${classes} bg-cream-dark text-primary-text tabSecStyle ${border}`
    }
    return classes
  }
  return (
    <TabWraper>
      {options.map((opt, index) => {
        return (
          <span className={getClasses(opt)} onClick={() => setSelectedTab(opt)} key={index}>
            {opt.label}
          </span>
        )
      })}
    </TabWraper>
  )
}

export default ButtonTabs
