import './App.css'
import './index.css'

import React from 'react'
import { Provider } from 'react-redux'
import { Amplify } from 'aws-amplify'
import { Router } from 'react-router-dom'

import Routes from 'routes'
import history from 'utils/history'
import configureStore from 'store'
import awsconfig from 'aws-exports'
import 'nprogress/nprogress.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

Amplify.configure(awsconfig)
export const store = configureStore()

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  )
}

export default App
