import React from 'react'
import { isMobileOnly } from 'mobile-device-detect'
import history from 'utils/history'
import { useParams } from 'react-router-dom'
import { getRoleFromPath } from 'utils/helper'

import ButtonField from 'components/Common/FormFields/ButtonField'

const Analytics = ({ isBusinessTab = false }) => {
  const { tenantId } = useParams()
  const userRole = getRoleFromPath()

  return (
    <>
      <div className={`${isMobileOnly ? 'mb-2 text-center' : 'pl-8 mb-2'} `}>
        <label className={`${isMobileOnly ? 'text-xl' : 'text-3xl'} font-primary primary-text`}>
          Manage {isBusinessTab ? 'Business' : 'Advisors'} Accounts
        </label>
      </div>
      <div className="bg-cream-dark">
        <div className={`${isMobileOnly ? 'py-6 px-5' : 'grid grid-cols-5 my-4 px-8 py-5'} `}>
          <div className="col-span-2">
            <ButtonField
              name={isBusinessTab ? '+ NEW BUSINESS ACCOUNT' : '+ NEW ADVISOR ACCOUNT'}
              variant="secondary"
              className={'bg-white'}
              onClick={() =>
                history.push(
                  `${
                    isBusinessTab
                      ? `/${userRole}/${tenantId}/create-business-owner`
                      : `/${userRole}/${tenantId}/create-advisor`
                  }`
                )
              }
            />
          </div>
          {/* <div
            className={`${isBusinessTab ? 'grid-cols-3' : 'grid-cols-2'} ${
              isMobileOnly ? 'my-4 px-2' : ''
            }  grid col-span-3 `}
          >
            {list &&
              list.map((license) => {
                return (
                  <div className="flex flex-col justify-center font-primary">
                    <label
                      className={`${
                        isMobileOnly ? 'text-center text-lg' : 'text-right text-2xl'
                      }  font-bold font-primary`}
                    >
                      {license.count}
                    </label>
                    <label
                      className={`${
                        isMobileOnly ? 'text-xs whitespace-pre text-center' : 'text-sm text-right '
                      } font-primary`}
                    >
                      {isBusinessTab ? license.seatsAvailable.split(' ').join('\n') : license.role}
                    </label>
                  </div>
                )
              })}
          </div> */}
        </div>
      </div>
    </>
  )
}
export default Analytics
