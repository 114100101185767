import React, { Fragment, useEffect, useState } from 'react'
import { StreamChat } from 'stream-chat'
import { useParams } from 'react-router-dom'
import history from 'utils/history'
import Logo from 'assets/images/Tackle-Logo.png'
import { getRoleFromPath, handleSelectedTab } from 'utils/helper'
import { NAVIGATION, APP_STREAM_KEY } from 'config'
import { isMobile, isTablet } from 'mobile-device-detect'
import { NotificationContainer } from 'pages/Common/container'
import { HeaderStyle } from 'layout/AppLayout/AppHeader/style'

export default function Header({
  chatUnreadMessageCount,
  chatUnreadNotificationsCount,
  user,
  chatUnreadMessageCountAction,
  getUnreadNoticationsAction,
}) {
  const [openNotifications, setOpenNotifications] = useState(false)
  const { tenantId } = useParams()
  const path = history.location.pathname
  const userRole = getRoleFromPath()

  const [client, setClient]: any = useState()

  const [chatUser] = useState({ id: user?.id })

  useEffect(() => {
    if (user) {
      const newClient = new StreamChat(APP_STREAM_KEY, {
        enableInsights: true,
        enableWSFallback: true,
      })

      newClient
        .connectUser(chatUser, user?.streamAccessToken)
        .catch((e) => {
          console.error(`Failed to connect user`, e)
        })
        .then(() => {
          setClient(newClient)
        })
      getUnreadNoticationsAction()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getUnreadNoticationsAction])

  if (client) {
    client.on((event) => {
      if (event.total_unread_count !== undefined) {
        if (
          event?.team === tenantId &&
          event?.channel?.members?.find((obj) => obj?.user_id === user?.id)
        ) {
          chatUnreadMessageCountAction(event?.total_unread_count)
        }
      }
    })
  }

  const HighlightIcon = ({ list }) => {
    const isSelected = handleSelectedTab(
      list.route || list.label.toLowerCase(),
      userRole,
      path,
      tenantId,
      '',
      openNotifications
    )

    return (
      <div
        className={`flex flex-col items-center relative font-normal w-auto font-semibold cursor-pointer`}
      >
        <div
          className={`inline-flex justify-center ${
            isSelected ? 'bg-primary/60 rounded-[50%] h-[44px] w-[44px] z-[-10] ' : ''
          } cursor-pointer`}
        >
          <list.icon
            className={`
        ${isSelected ? 'fill-grey' : ''}
      
      ${list.label === 'Fire' ? '' : 'mt-2'} fill-silver svg-icon  hover:fill-grey z-20
      
    }`}
          />
        </div>
      </div>
    )
  }
  return (
    <HeaderStyle>
      <div className="header-logo">
        <div>
          <img src={Logo} alt="Logo" />
        </div>
      </div>
      <div className="header-navigation">
        <div>
          {NAVIGATION[userRole].map((list, index) => {
            let listPath = list.path
            if (listPath) {
              listPath = listPath.replace(':tenantId', tenantId)
            }
            return (
              <Fragment key={index}>
                {(isTablet
                  ? list.isHeader
                  : isMobile
                  ? list.xs && list.isHeader
                  : list.isHeader) && (
                  <div className="sm:flex inline-flex gap-6 relative w-[48px] h-[48px]">
                    <div
                      className={`${
                        list.label === 'Fire' ? 'mt-2' : ''
                      } cursor-pointer relative w-[48px]`}
                      onClick={() => {
                        if (list.label === 'Bell') setOpenNotifications(!openNotifications)

                        listPath && history.push(listPath)
                      }}
                    >
                      <HighlightIcon list={list} />

                      {list?.notification &&
                        list?.path?.includes('chat') &&
                        chatUnreadMessageCount > 0 && (
                          <div className="absolute top-[9px] right-[2px] z-[11]">
                            <list.notification.icon className="fill-gerberared" />
                          </div>
                        )}

                      {list.label === 'Bell' && chatUnreadNotificationsCount > 0 && (
                        <div className="absolute top-[9px] right-[2px] z-[11]">
                          <list.notification.icon className="fill-gerberared" />
                        </div>
                      )}
                    </div>
                    {list.label === 'Bell' && openNotifications && (
                      <NotificationContainer handleClose={() => setOpenNotifications(false)} />
                    )}
                  </div>
                )}
              </Fragment>
            )
          })}
        </div>
      </div>
    </HeaderStyle>
  )
}
