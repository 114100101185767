import { UPGRADE_CURRENT_ASSESSMENT_NUMBER, DOWNGRADE_ASSESSMENT_NUMBER } from 'store/types'

const initialState = {
  saleAssessments: [
    {
      queries: [
        {
          heading: 'Hello I’m Patrice, Nice to Meet you!',
          text: 'I need to ask you a few questions to learn more about your business so I can provide you with the best recommendations.',
          icon: '',
          type: 'chat',
        },
      ],
      options: {
        type: 'button',
        list: [
          {
            text: 'Awesome, Let’s get started!',
          },
        ],
      },
    },
    {
      queries: [
        {
          heading: 'What kind of industry is your business in?',
          helper: 'Select the industry that matches your business the most:',
          icon: '',
        },
      ],
      options: {
        type: 'cardSelect',
        optionType: 'industry',
        list: [
          {
            text: 'Food and Restaurant',
            icon: '',
          },
          {
            text: 'Retail',
            icon: '',
          },
          {
            text: 'E-commerce',
            icon: '',
          },
          {
            text: 'Day Care Services',
            icon: '',
          },
          {
            text: 'Product-Based Business',
            icon: '',
          },
          {
            text: 'Arts, Entertainment & Recreation',
            icon: '',
          },
        ],
      },
    },
    {
      queries: [
        {
          text: 'ok great! What is your current POS tool of choice?',
          icon: '',
        },
      ],
      options: {
        type: 'button',
        webType: 'multipleOptions',
        list: [
          {
            text: 'Yes',
            icon: '',
          },
          {
            text: 'No',
            icon: '',
          },
        ],
      },
    },
    {
      queries: [
        {
          text: 'POS tools are beneficial for your business because they allow you to provide your clients easy and secure ways to pay you for your services.',
          icon: '',
        },
        {
          text: 'Let’s get you started with a POS for your business. ',
          icon: '',
        },
        {
          text: 'Do you have a budget in mind for this?',
          icon: '',
        },
      ],
      options: {
        type: 'button',
        webType: 'multipleOptions',
        list: [
          {
            text: 'Yes, I’d like to set a budget',
            icon: '',
          },
          {
            text: 'No, I’m open to any recommendation',
            icon: '',
          },
        ],
      },
    },
    {
      queries: [
        {
          text: 'Select a budget that best aligns with your goals, and I will make recommendations based on your answer',
          icon: '',
        },
      ],
      options: {
        type: 'button',
        list: [
          {
            text: '$0-$20/month',
            icon: '',
          },
          {
            text: '$40-$80/month',
            icon: '',
          },
          {
            text: '$20-$40/month',
            icon: '',
          },
          {
            text: '$90+ month',
            icon: '',
          },
        ],
      },
    },
    {
      queries: [
        {
          text: 'Ok. I found a POS system that matches your budget.',
          icon: '',
        },
      ],
      options: {
        type: 'recommendationButton',
        list: [
          {
            text: 'View Recommendation',
            icon: '',
          },
        ],
      },
    },
  ],
  currentAssessmentNumber: 0,
  responses: [],
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case UPGRADE_CURRENT_ASSESSMENT_NUMBER:
      return {
        ...state,
        currentAssessmentNumber: state.currentAssessmentNumber + 1,
        responses: [...state.responses, action.payload],
      }
    case DOWNGRADE_ASSESSMENT_NUMBER:
      return {
        ...state,
        currentAssessmentNumber: state.currentAssessmentNumber - 1,
      }
    default:
  }
  return state
}

export default reducer
