import React, { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { CheckIcon, ChevronLeftIcon } from '@heroicons/react/solid'

import InputField from 'components/Common/FormFields/InputField'
import ButtonField from 'components/Common/FormFields/ButtonField'
import { NUMBER_REGEX, SPECIAL_CHARACTER_REGEX, UPPER_CASE_REGEX } from 'config'
import { yupResolver } from '@hookform/resolvers/yup'
import { resetPasswordSchema } from 'pages/Auth/validation'
import { scrollToTop } from 'utils/helper'
import InputTextButton from 'components/Common/StyledComponents/InputTextButton'
import AuthCardContainer from 'components/Common/StyledComponents/AuthCardContainer'

const ChangePassword = ({ changePasswordAction, disableUpdatePasswordButton }) => {
  const [showPasswordFields, setShowPasswordFields] = useState({
    newPassword: false,
    confirmNewPassword: false,
  })

  useEffect(() => {
    scrollToTop()
  }, [])

  let history = useHistory()

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(resetPasswordSchema, { abortEarly: false }),
  })
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods

  const passwordCheck = watch('password')

  const togglePasswordFields = (passwordField) => {
    setShowPasswordFields({
      ...showPasswordFields,
      [passwordField]: !showPasswordFields[passwordField],
    })
  }
  const onSubmit = (password) => {
    delete password.confirmpassword
    changePasswordAction(password)
  }

  return (
    <>
      <div className="grid-flow-row auto-rows-max pt-6 w-full h-screen">
        <div className="xs:ml-0 md:ml-12">
          <div className="flex items-center cursor-pointer" onClick={() => history.goBack()}>
            <div className="flex-initial">
              <ChevronLeftIcon className={`h-7 w-7 inline mt-1 mr-2 text-primary`} />
            </div>
            <div className="flex-initial pl-2">
              <p className="text-[18px] mt-1 text-primary font-primary">ACCOUNT SETTINGS</p>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="max-w-[400px] items-center justify-center bg-transparent sm:bg-transparent md:bg-transparent">
            <div className="w-full">
              <div className="text-[36px] text-center mt-[42px] text-purple-dark font-primary">
                Change Password
              </div>
              <div className="mt-[19px] w-full mb-8">
                <div className="ml-5 font-primary text-[17.3px] w-[300px]">
                  <div className="flex flex-column">
                    <span>
                      <CheckIcon className={`h-5 w-5 inline invisible mr-2`} />
                    </span>
                    <span>Password Rules:</span>
                  </div>
                  <div className="flex flex-column">
                    <span>
                      <CheckIcon
                        className={`${
                          passwordCheck && passwordCheck.match(NUMBER_REGEX)
                            ? 'visible'
                            : 'invisible'
                        } h-5 w-5 inline mr-2 fill-[#000000]`}
                      />
                    </span>
                    <span>Must contain numbers</span>
                  </div>

                  <div className="flex flex-column">
                    <span>
                      <CheckIcon
                        className={`${
                          passwordCheck && passwordCheck.match(UPPER_CASE_REGEX)
                            ? 'visible'
                            : 'invisible'
                        } h-5 w-5 inline mr-2`}
                      />
                    </span>
                    <span>Must contain uppercase letters</span>
                  </div>

                  <div className="flex flex-column">
                    <span>
                      <CheckIcon
                        className={`${
                          passwordCheck && passwordCheck.match(SPECIAL_CHARACTER_REGEX)
                            ? 'visible'
                            : 'invisible'
                        } h-5 w-5 inline mr-2`}
                      />
                    </span>
                    <span>Must have at least one ! $ @ # % & symbol</span>
                  </div>
                </div>
              </div>

              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <AuthCardContainer>
                      <div className="pb-3">
                        <InputTextButton
                          title={showPasswordFields.newPassword ? 'HIDE' : 'SHOW'}
                          action={() => togglePasswordFields('newPassword')}
                        />

                        <InputField
                          errors={errors.password}
                          type="password"
                          label="NEW PASSWORD"
                          showPassword={showPasswordFields.newPassword}
                          defaultValue=""
                          {...register('password')}
                        />
                        <InputTextButton
                          title={showPasswordFields.confirmNewPassword ? 'HIDE' : 'SHOW'}
                          action={() => togglePasswordFields('confirmNewPassword')}
                        />
                        <InputField
                          errors={errors.confirmpassword}
                          showPassword={showPasswordFields.confirmNewPassword}
                          type={'password'}
                          label={'CONFIRM NEW PASSWORD'}
                          defaultValue=""
                          {...register('confirmpassword')}
                        />

                        <div className="mt-3">
                          <ButtonField
                            disable={false}
                            type="submit"
                            name={disableUpdatePasswordButton ? '...' : 'Update Password'}
                            variant={'primary'}
                          />
                        </div>
                        <div className="mt-[6px]">
                          <ButtonField
                            disable={false}
                            onClick={() => history.goBack()}
                            type="button"
                            name={'Cancel'}
                            variant="secondary"
                          />
                        </div>
                        {/* <div className="right-[28%] relative">
                          <InputTextButton title={'Forgot password?'} action={() => ''} />
                        </div> */}
                      </div>
                    </AuthCardContainer>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ChangePassword
