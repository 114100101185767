import React, { useCallback, useState } from 'react'
import size from 'lodash/size'
import { ChatAutoComplete, EmojiPicker, useMessageInputContext } from 'stream-chat-react'

import './MessagingInput.css'
import { LightningBoltSmall } from '../../assets'
import type { StreamChatGenerics } from '../../types'
import { useGiphyContext } from '../../Giphy'
import ModalBox from 'components/Common/ModalBox'
import FileUploadIcon from 'components/Common/SvgIcons/FileUploadIcon'
import SendIcon from 'components/Common/SvgIcons/SendIcon'
import EmojiIcon from 'components/Common/SvgIcons/EmojiIcon'
import UploadFiles from 'components/Chat/components/MessagingInput/UploadFiles'

const GiphyIcon = () => (
  <div className="giphy-icon__wrapper">
    <LightningBoltSmall />
    <p className="giphy-icon__text">GIPHY</p>
  </div>
)

const MessagingInput = () => {
  const [showUploadModal, setShowUploadModal] = useState(false)
  const { giphyState, setGiphyState } = useGiphyContext()

  const messageInput = useMessageInputContext<StreamChatGenerics>()

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      const { value } = event.target

      const deletePressed =
        event.nativeEvent instanceof InputEvent &&
        event.nativeEvent.inputType === 'deleteContentBackward'

      if (messageInput.text.length === 1 && deletePressed) {
        setGiphyState(false)
      }

      if (!giphyState && messageInput.text.startsWith('/giphy') && !messageInput.numberOfUploads) {
        event.target.value = value.replace('/giphy', '')
        setGiphyState(true)
      }

      messageInput.handleChange(event)
    },
    [giphyState, messageInput.numberOfUploads, messageInput.text] // eslint-disable-line
  )

  const messageUploads = { ...messageInput.imageUploads, ...messageInput.fileUploads }
  const handleClose = (e) => {
    if (size(messageUploads)) {
      messageInput.handleSubmit(e)
    }
    setShowUploadModal(false)
  }

  return (
    <>
      <div className="bg-secondary">
        <div className="flex !bg-secondary ">
          <div className="mt-4 ml-4 cursor-pointer" onClick={() => setShowUploadModal(true)}>
            <FileUploadIcon className={''} />
          </div>

          <div className="grow">
            <div className="">
              {giphyState && !messageInput.numberOfUploads && <GiphyIcon />}

              <ChatAutoComplete onChange={onChange} rows={1} placeholder="Write a message..." />
            </div>
          </div>
          <div
            className="emoji-button mr-2 mt-3"
            role="button"
            aria-roledescription="button"
            onClick={
              messageInput.emojiPickerIsOpen
                ? messageInput.closeEmojiPicker
                : messageInput.openEmojiPicker
            }
            ref={messageInput.emojiPickerRef}
          >
            <EmojiIcon />
          </div>
          <div className="relative inline-block">
            <EmojiPicker />
          </div>

          {/* send message */}
          <div
            className="mr-3 mt-3"
            role="button"
            aria-roledescription="button"
            onClick={messageInput.handleSubmit}
          >
            <SendIcon />
          </div>
        </div>
      </div>
      {showUploadModal && (
        <ModalBox onClose={handleClose} width={''} title="Upload Files">
          <UploadFiles
            handleClose={handleClose}
            showUploadModal={showUploadModal}
            uploadedImage={{ ...messageInput.imageUploads, ...messageInput.fileUploads }}
            uploadFileAction={(data) => {
              messageInput.uploadNewFiles(data.files)
            }}
          />
        </ModalBox>
      )}
    </>
  )
}

export default MessagingInput
