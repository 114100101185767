import { CHANGE_PASSWORD } from 'store/types'

const initialState = {
  uploadedImage: '',
  disableUpdatePasswordButton: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD.STARTED:
      return {
        ...state,
        disableUpdatePasswordButton: true,
      }
    case CHANGE_PASSWORD.FULLFILLED:
      return {
        ...state,
        disableUpdatePasswordButton: false,
      }
    case CHANGE_PASSWORD.REJECTED:
      return {
        ...state,
        disableUpdatePasswordButton: false,
      }
    default:
  }
  return state
}

export default reducer
