import React from 'react'

const LinkText = ({ title, action }) => {
  return (
    <span
      onClick={action}
      className="font-primary text-primary text-sm font-semibold cursor-pointer border-b border-transparent hover:border-b hover:border-primary"
    >
      {title}
    </span>
  )
}

export default LinkText
