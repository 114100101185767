import React, { useEffect, useState } from 'react'
import { isMobileOnly, isTablet } from 'mobile-device-detect'

import { advisorDataTableColumns } from 'config'
import ModalBox from 'components/Common/ModalBox'
import Filters from 'components/Admin/UserManagement/Filters'
import Analytics from 'components/Admin/UserManagement/Analytics'
import DataTable from 'components/Common/DataTable'
import { IpadViewPort } from 'components/Admin/UserManagement/style'
import UserManagementHeading from 'components/Admin/UserManagement/StyledComponents/UserManagementHeading'
import { UserManagementListContainer } from 'pages/Admin/container/UserManagement'
import Loader from 'components/Loaders'

const AdvisorsComponent = ({
  advisorDataLoading,
  advisorsAccountDetails,
  getAdvisorsAccountDetailsAction,
  advisorAccountFilterAction,
  advisorsAccountFilter,
  getBusinessesAccountDetailsAction,
}) => {
  const [showFilters, setShowFilters] = useState(false)
  const [pageSize, setPageSize] = useState(20)

  const closemodal = () => {
    setShowFilters(false)
  }
  useEffect(() => {
    getBusinessesAccountDetailsAction()
    getAdvisorsAccountDetailsAction({ isPaginated: false })
    // eslint-disable-next-line
  }, [])
  const nextPaginatedDataHandler: any = (params) => {
    getAdvisorsAccountDetailsAction({ ...params, pageSize })
  }
  return (
    <>
      <IpadViewPort>
        <div className="xs:col-span-12 lg:col-span-12 lg:block hideInIpadProView mdl:px-8 xl:px-0">
          {/* desktop & ipad */}
          <div className="w-full overflow-auto p-2">
            <UserManagementHeading title={'Advisor'} />

            <div className="hidden mdl:block pb-6">
              <Filters
                isBusinessTab={false}
                setShowFilters={setShowFilters}
                showFilters={showFilters}
                filterAction={advisorAccountFilterAction}
                filter={advisorsAccountFilter}
              />
            </div>
            {/* data-table */}
            {advisorDataLoading ? (
              <Loader loader="UserManagementLoader" />
            ) : (
              <div className="w-full min-w-[800px]">
                <DataTable
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  columns={advisorDataTableColumns}
                  rows={advisorsAccountDetails}
                  pagination={true}
                />
              </div>
            )}
          </div>

          {isMobileOnly && <div>Businesses</div>}
        </div>
      </IpadViewPort>
      <>
        <IpadViewPort>
          <div className="col-span-12 hideInIpadView">
            {!isMobileOnly && <div></div>}
            {/* mobile */}
            {(isMobileOnly || isTablet) && (
              <div>
                <Analytics />
                <Filters
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                  filterAction={advisorAccountFilterAction}
                  filter={advisorsAccountFilter}
                />

                <UserManagementListContainer
                  pageSize={pageSize}
                  isBusinessTab={false}
                  list={advisorsAccountDetails}
                  nextPaginatedDataHandler={nextPaginatedDataHandler}
                />
              </div>
            )}
            {showFilters && (
              <ModalBox onClose={closemodal} width={'w-full'} title="Filter, Sort, Search">
                <Filters
                  isBusinessTab={false}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                  filterAction={advisorAccountFilterAction}
                  filter={advisorsAccountFilter}
                />
              </ModalBox>
            )}
          </div>
        </IpadViewPort>
      </>
    </>
  )
}

export default AdvisorsComponent
