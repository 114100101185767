import { FORGOTPASSWORD, FORGOTPASSWORDSUBMIT, LOGIN } from 'store/types'

const initialState = {
  userState: {},
  user: null,
  error: '',
  disableLoginSubmit: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN.STARTED:
      return {
        ...state,
        disableLoginSubmit: true,
      }
    case LOGIN.REJECTED:
      return {
        ...state,
        disableLoginSubmit: false,
        error: action.payload.error.message,
      }
    case LOGIN.FULLFILLED:
      return {
        ...state,
        user: action.payload.user,
        disableLoginSubmit: false,
        error: '',
      }
    case FORGOTPASSWORD.STARTED:
      return {
        ...state,
        disableLoginSubmit: true,
      }
    case FORGOTPASSWORD.REJECTED:
      return {
        ...state,
        disableLoginSubmit: false,
        error: action.payload.error.message,
      }
    case FORGOTPASSWORD.FULLFILLED:
      return {
        ...state,
        disableLoginSubmit: false,
      }
    case FORGOTPASSWORDSUBMIT.STARTED:
      return {
        ...state,
        disableLoginSubmit: true,
      }
    case FORGOTPASSWORDSUBMIT.REJECTED:
      return {
        ...state,
        disableLoginSubmit: false,
        error: action.payload.error.message,
      }
    case FORGOTPASSWORDSUBMIT.FULLFILLED:
      return {
        ...state,
        disableLoginSubmit: false,
      }

    default:
  }
  return state
}

export default reducer
