import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import history from 'utils/history'

import TermsCondition from 'components/TermsAndCondition'
import PrivacyPolicy from 'components/PrivacyPolicy'
import { getRoleFromPath } from 'utils/helper'
import Lock from 'components/Common/SvgIcons/Lock'
import Book from 'components/Common/SvgIcons/Book'
import { BellIcon } from 'components/Common/SvgIcons'
import ButtonField from 'components/Common/FormFields/ButtonField'
import ModalBox from 'components/Common/ModalBox'
import { COMPANY_NAME } from 'config'

const SettingItem = ({ Icon, heading, subHeading, clickAction = () => {} }) => {
  return (
    <div className="max-w-full h-auto rounded-[10px] bg-white cursor-pointer">
      <div className="grid grid-cols-5 " onClick={clickAction}>
        <div className="grid place-items-center">
          <Icon />
        </div>
        <div className="col-span-4 p-2">
          <p className="font-normal font-normal text-base text-purple-dark">{heading}</p>
          <p className="font-normal font-normal text-xs text-silver-darker">{subHeading}</p>
        </div>
      </div>
    </div>
  )
}

export default function SettingPanel({ logoutAction }) {
  const { tenantId } = useParams()
  const [showPrivacy, setShowPrivacy] = useState(false)
  const [showTerms, setShowTerms] = useState(false)

  const closemodal = () => {
    setShowTerms(false)
    setShowPrivacy(false)
  }
  return (
    <div className="w-full">
      <div className="h-auto h-full right-0">
        <div className="grid grid-cols-1 gap-4 lg:max-w-full md:max-w-full font-primary h-[fit-content] xs:w-full sm:w-full md:w-full">
          <p className="font-normal max-w-[355px] h-[32px] font-normal mb-4 text-2xl text-purple-dark">
            Settings
          </p>

          <SettingItem
            Icon={Lock}
            heading={'Change Password'}
            subHeading="it’s a good idea to use a strong password that you’re not using elsewhere"
            clickAction={() => history.push(`/${getRoleFromPath()}/${tenantId}/change-password`)}
          />

          <SettingItem
            Icon={BellIcon}
            heading={'Notification Preferences'}
            subHeading={`${COMPANY_NAME} may still send you important notifications about your account outside of your preferred notification settings.`}
          />

          <SettingItem
            Icon={Book}
            heading={'View Data Privacy Policy'}
            subHeading="Information about how we protect your data."
            clickAction={() => setShowPrivacy(!showPrivacy)}
          />

          <SettingItem
            Icon={Book}
            heading={'View Terms & Conditions'}
            subHeading="Information about your participation on the dashboard."
            clickAction={() => setShowTerms(!showTerms)}
          />
          <div className="mb-2">
            <ButtonField name={'Sign Out'} variant="secondary" onClick={() => logoutAction()} />
          </div>
        </div>
        {showTerms && (
          <ModalBox onClose={closemodal} width={'w-full'} height="h-max" showCancelButton={true}>
            <TermsCondition />
          </ModalBox>
        )}
        {showPrivacy && (
          <ModalBox onClose={closemodal} width={'w-full'} height="h-max" showCancelButton={true}>
            <PrivacyPolicy />
          </ModalBox>
        )}
      </div>
    </div>
  )
}
