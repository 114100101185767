import React, { useState } from 'react'
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer'
import Modal from './Modal'

const IdleTracking = ({ logoutAction }) => {
  const [showModal, setShowModal] = useState(false)

  const handleOnActive = () => {
    reset()
  }
  const closemodal = () => {
    setShowModal(false)
  }

  const { reset } = useIdleTimer({
    onActive: handleOnActive,
  })
  const onIdle = () => {
    setShowModal(true)

    setTimeout(() => {
      setShowModal((showModal) => {
        if (showModal) logoutAction()
        return showModal
      })
    }, 1000 * 60)
    reset()
  }
  return (
    <>
      <IdleTimerProvider timeout={1000 * 60 * 15} onIdle={onIdle}>
        {showModal && (
          <Modal
            cancelAction={closemodal}
            heading={'Session Time-Out'}
            text={'You are about to be logged out of the platform due to inactivity.'}
            confirmText="Log-Out"
            cancelText="Extend"
            confirmAction={logoutAction}
          />
        )}
      </IdleTimerProvider>
    </>
  )
}

export default IdleTracking
