import React from 'react'
import Dropzone from 'react-dropzone'
import { UploadFileWrapper } from 'components/Assessment/style'
import UploadImage from 'components/Common/SvgIcons/UploadImage'

const DropzoneComponent = ({ handleChange }) => {
  return (
    <div className="col-span-6 px-5 pb-12 mb-12">
      <Dropzone onDrop={handleChange}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              <div className="flex flex-col justify-center items-center pt-10  border-[2px] border-primary-outline border-dashed rounded-[10px] px-7 relative">
                <div className="pb-8 pt-10 ">
                  <UploadImage className="fill-primary" />
                  <div className=" w-[158px] h-[112px] absolute top-[88px] left-3 opacity-0"></div>
                </div>
                <div className="text-center">
                  <label className="text-3xl font-primary">Drop Files Here to Upload</label>
                </div>
                <div className="relative flex py-8 items-center w-[100%]">
                  <div className="flex-grow border-t border-secondary-text"></div>
                  <span className="flex-shrink mx-2 text-gray-400 text-silver-darker font-semibold text-base font-primary">
                    or
                  </span>
                  <div className="flex-grow border-t border-secondary-text"></div>
                </div>
                <div className="pb-10 mb-8">
                  <UploadFileWrapper>
                    <label
                      className={`bg-primary px-3 hover:bg-primary-brand active:bg-primary-active focus:bg-primary outline-0 focus:border-zinc font-primary py-3 leading-snug relative w-full rounded-[4px] flex justify-center border border-transparent text-lg font-semibold text-white cursor-pointer`}
                    >
                      Browse Files
                    </label>
                  </UploadFileWrapper>
                </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}

export default DropzoneComponent
