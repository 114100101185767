import React from 'react'
import styled from 'styled-components'

const Loader = styled.div`
  border: 3px solid transparent;
  border-top: 3px solid rgb(91, 186, 185);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  display: inline-block;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const CircularLoading = ({ className }) => {
  return <Loader className={`${className}`}></Loader>
}

CircularLoading.defaultProps = {
  className: '',
}

export default CircularLoading
