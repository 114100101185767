import React from 'react'

function ChatIcon({ className }) {
  return (
    <svg
      className={className}
      width="38"
      height="30"
      viewBox="0 0 38 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle', overflow: 'hidden' }}
    >
      <g clip-path="url(#clip0_7898_75989)">
        <path
          d="M33.9429 7.5H26.4V9.375H33.9429C34.9824 9.375 35.8286 10.2164 35.8286 11.25V22.5C35.8286 23.5336 34.9824 24.375 33.9429 24.375H30.1714V27.2795L25.0093 24.375H18.8571C17.8176 24.375 16.9714 23.5336 16.9714 22.5V20.625L15.0857 20.6248V22.4986C15.0857 24.564 16.7799 26.1959 18.8041 26.1959L24.5143 26.25L30.9316 29.8611C31.0613 29.959 31.2086 30 31.35 30C31.7213 30 32.0571 29.7129 32.0571 29.2969V26.2482H33.9429C36.0201 26.2482 37.7143 24.5637 37.7143 22.551V11.1973C37.7143 9.18164 36.023 7.5 33.9429 7.5ZM24.5143 15V3.69727C24.5143 1.68457 22.823 0 20.7429 0H3.77143C1.6942 0 0 1.68457 0 3.69727V14.9473C0 17.0684 1.6942 18.75 3.77143 18.75L5.65714 18.7506V21.7969C5.65714 22.2129 5.99893 22.5 6.36429 22.5C6.50424 22.5 6.65156 22.4561 6.78415 22.3608L13.2 18.75L20.7429 18.7456C22.823 18.7441 24.5143 17.0684 24.5143 15ZM12.705 16.875L7.54286 19.7813V16.875H3.77143C2.73193 16.875 1.88571 16.0313 1.88571 15V3.75C1.88571 2.71641 2.73193 1.875 3.77143 1.875H20.7429C21.7824 1.875 22.6286 2.71641 22.6286 3.75V15C22.6286 16.0336 21.7824 16.875 20.7429 16.875H12.705Z"
          // fill="#5BBAB9"
        />
        <path
          d="M18.125 9.375C18.125 9.8534 17.7375 10.2407 17.2596 10.2407H13.3654V14.1349C13.3654 14.6133 12.9779 15 12.5 15C12.0221 15 11.6346 14.6133 11.6346 14.1349V10.2407H7.74038C7.26253 10.2407 6.875 9.8534 6.875 9.375C6.875 8.8966 7.26253 8.50989 7.74038 8.50989H11.6346V4.61566C11.6346 4.13726 12.0221 3.75 12.5 3.75C12.9779 3.75 13.3654 4.13726 13.3654 4.61566V8.50989H17.2596C17.7383 8.50962 18.125 8.89633 18.125 9.375Z"
          // fill="#5BBAB9"
        />
      </g>
      <defs>
        <clipPath id="clip0_7898_75989">
          <rect width="37.7143" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ChatIcon
