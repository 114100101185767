import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_GOAL_DETAILS } from 'store/types'
import goalAPi from 'api/goal'
import { getGoalDetailsAction } from 'store/actions/goal'

function* getGoalDetails(action) {
  try {
    const getGoalDetailsRespone = yield call(goalAPi.getGoalDetails, action.payload)
    yield put(getGoalDetailsAction.FULLFILLED(getGoalDetailsRespone?.getGoalDetails))
  } catch (error) {
    console.log('get Goal Details error : ', error)
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherGoal() {
  yield takeLatest(GET_GOAL_DETAILS.STARTED, getGoalDetails)
}
