import React, { useEffect, useState, useMemo } from 'react'

import TableHeader from 'components/Common/DataTable/TableHeader'
import TableBody from 'components/Common/DataTable/TableBody'
import TablePagination from 'components/Common/DataTable/TablePagination'
import { sortHandlerHelper } from 'utils/helper'
import { isMobileOnly } from 'mobile-device-detect'
import { Columns } from 'config'
let emptyFunction = (obj) => {}

export interface IDataTableProps {
  columns: Columns
  rows: any[]
  /** todo: remove "pagination" prop. Instead, infer that pagination should be used if "pageSize" and/or "setPageSize" props are defined. */
  pagination: boolean
  setPageSize?: (value: any) => void
  getRecords?: (...args: any[]) => void
  pageSize: number
  ExpandedComponent: any
  /** The number of equally spaced grid columns used in conjuction with "display: grid" to style the table. */
  gridColumns?: number
  /** The name of the business concept / data type that populates each table row. (e.g. "user", "play", etc.) */
  tableType?: string
}

const DataTable = ({
  columns,
  rows,
  pagination,
  setPageSize = emptyFunction,
  getRecords = emptyFunction,
  pageSize,
  ExpandedComponent,
  gridColumns,
  tableType,
}: IDataTableProps) => {
  const [noOfItemsPerPage, setNoOfItemsPerPage] = useState(pageSize)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(0)
  const [tableData, setTableData]: any = useState([])

  useEffect(() => {
    getRecords({ filter: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (rows && rows.length > 0) {
      setTotalItems(rows.length)
      setTableData(rows)
    } else {
      setTotalItems(0)
      setTableData([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setCurrentPage(1)
    }
  }, [rows])

  // Need to Check if we need this or not

  // useEffect(() => {
  //   if (currentPage < totalPages && rows.length !== totalItems) {
  //     setCurrentPage(currentPage + 1)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [rows])

  const moveNextHandler = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    } else {
      getRecords()
    }
  }

  const movePreviousHandler = () => {
    if (currentPage - 1 > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const updateNoOfItemPerPageHandler = (value) => {
    if (value > 0 && value <= rows.length) {
      setNoOfItemsPerPage(value)
      setPageSize(value)
    }
  }

  const sortHandler = ({ sortField, sortOrder, fieldType }) => {
    let sortedTableData = sortHandlerHelper({
      sortField,
      sortOrder,
      fieldType,
      data: tableData,
    })

    setTableData(sortedTableData)
  }

  // tableData
  const commentsData = useMemo(() => {
    let computedComments = tableData
    let length = rows.length

    if (startIndex + 1 === length) {
      let updatedCurrentPage = currentPage - 1

      if (currentPage > 1) {
        // startIndex & endIndex
        let start = (updatedCurrentPage - 1) * noOfItemsPerPage
        let end = (updatedCurrentPage - 1) * noOfItemsPerPage + noOfItemsPerPage
        setStartIndex(start)

        if (rows.length < end) {
          setEndIndex(rows.length)
        } else {
          setEndIndex(end)
        }
        // updateTotalPages
        let total: number = rows.length
        let pages: number = Math.ceil(total / noOfItemsPerPage)

        setCurrentPage(updatedCurrentPage)
        setTotalPages(pages)

        return computedComments.slice(start, end)
      } else {
        // note: for one record
        setStartIndex(0)
        setEndIndex(1)
        setCurrentPage(1)
        setTotalPages(1)
        return computedComments
      }
    } else {
      // startIndex & endIndex
      let start = (currentPage - 1) * noOfItemsPerPage
      let end = (currentPage - 1) * noOfItemsPerPage + noOfItemsPerPage
      setStartIndex(start)

      if (rows.length < end) {
        setEndIndex(rows.length)
      } else {
        setEndIndex(end)
      }

      // updateTotalPages
      let total: number = rows.length
      // let pages: number = Math.ceil(total / noOfItemsPerPage)
      let pages: number = Math.ceil(total / noOfItemsPerPage)

      setTotalPages(pages)

      return computedComments.slice(start, end)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, currentPage, noOfItemsPerPage, tableData])

  return (
    <div>
      <div className="rounded-t-lg overflow-hidden  xs:w-max md:w-full overflow-x-auto">
        <table className="w-full">
          <TableHeader gridColumns={gridColumns} columns={columns} sortHandler={sortHandler} />
          <TableBody
            gridColumns={gridColumns}
            columns={columns}
            rows={commentsData}
            ExpandedComponent={ExpandedComponent}
          />
        </table>

        {pagination && tableData.length > 0 && !isMobileOnly && (
          <TablePagination
            noOfItemsPerPage={noOfItemsPerPage}
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            startIndex={startIndex}
            endIndex={endIndex}
            moveNextHandler={moveNextHandler}
            movePreviousHandler={movePreviousHandler}
            updateNoOfItemPerPageHandler={updateNoOfItemPerPageHandler}
            type={tableType || columns[0].tableType || 'record'}
          />
        )}
      </div>
    </div>
  )
}

DataTable.defaultProps = {
  ExpandedComponent: React.Fragment,
  getRecords: () => {},
}

export default DataTable
