import { getIsAllowCookies, saveUser } from 'utils/user'
import {
  ROLES,
  USER_ROLES,
  PLAY_STEPS,
  PLAY_INTEGRATION_QUERY_PARAMS,
  FORM,
  MEDIA_BUCKET,
} from 'config'
import { scroller } from 'react-scroll'
import history from 'utils/history'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'
import find from 'lodash/find'
import moment from 'moment'
import hookForms from 'utils/hookForms'
import set from 'lodash/set'
import { getMultipleRecommendations } from 'utils/assessments'
import { filter, includes } from 'lodash'

export const API_TYPE = (action: string) => ({
  STARTED: `${action}_STARTED`,
  FULLFILLED: `${action}_FULLFILLED`,
  REJECTED: `${action}_REJECTED`,
})

export const userInitialRouting = (userProfile) => {
  const tenantId = get(userProfile, 'getMyProfile.tenantId[0]', '')

  const isAllow = getIsAllowCookies()
  if (isAllow) {
    saveUser(userProfile)
  }
  if (get(userProfile, 'getMyProfile.roles[0]', '')) {
    const role = userProfile.getMyProfile.roles[0].toUpperCase()
    if (role === 'BUSINESS_OWNER' && get(userProfile, 'getMyProfile.isNewUser', false)) {
      history.push(`/owner/${tenantId}/assessment`)
    } else if (
      (role === 'BSO_ADVISOR' || role === 'BSO_ADMIN') &&
      get(userProfile, 'getMyProfile.isNewUser', false)
    ) {
      history.push(`/${ROLES[`${role}`]}/${tenantId}/onbording/welcome-advisor`)
    } else {
      const path = `/${ROLES[`${role}`]}/${tenantId}/dashboard`
      history.push(path)
    }
  } else {
    history.push('/auth/login')
  }
}

export const isActiveStep = (activeStep, title) => {
  return activeStep === title ? 'block' : 'hidden'
}

export const getRoleFromPath = () => {
  const pathname = history.location.pathname.split('/')
  return pathname[1]
}
export const getfullPath = () => {
  const pathname = history.location.pathname.split('/')
  return pathname[1] + '/' + pathname[2]
}

export const scrollToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}
export const handleSelectedTab = (
  tabName,
  userRole,
  path,
  tenantId = '',
  isSelectedTab = '',
  openNotifications = false
) => {
  if (openNotifications && tabName.toLowerCase() === 'bell') return true
  if (isSelectedTab) {
    path = path.replace(`/${isSelectedTab}`, '')
  }
  return path === `/${userRole}/${tenantId}/${tabName}` ? true : false
}

export const copy = (data) => {
  return JSON.parse(JSON.stringify(data))
}

export const getAssessmentResponse = ({ keys, assessment_questions }) => {
  const assessmentResponse: any = []
  Object.keys(keys).forEach((key, index) => {
    if (keys[key]) {
      // find recommendation object is available in selected option of assestion question
      let activeQuestion = assessment_questions.find((question) => question.id === key)
      let answer
      answer = activeQuestion?.options.find((option_id) => option_id.id === keys[key])

      if (activeQuestion?.meta?.allowMultiSelect) {
        answer = filter(activeQuestion?.options, (op) => includes(keys[key], op.id))
      }

      assessmentResponse.push({
        id: key,
        value: keys[key],
        isTopGoal: activeQuestion?.meta.isTopGoal || undefined,
        recommendations: activeQuestion?.meta?.allowMultiSelect
          ? getMultipleRecommendations(answer)
          : answer?.action?.recommendations || undefined,
        isIndustry: activeQuestion?.meta?.isIndustry || undefined,
        isSubIndustry: activeQuestion?.meta?.isSubIndustry || undefined,
      })
    }
  })

  return assessmentResponse
}

export const getActiveStepAndNavigationLink = ({ playStage, stepName, id, tenantId }) => {
  let activeStep = ''
  let link = ''
  let activeStepIndex = 0
  if (!playStage?.guide?.isCompleted) {
    activeStep = PLAY_STEPS.GUIDE
    link = `/owner/${tenantId}/plays/${id}/${PLAY_STEPS.GUIDE}`
  } else if (!playStage?.setUp?.isCompleted) {
    activeStep = PLAY_STEPS.SETUP
    link = `/owner/${tenantId}/plays/${id}/${PLAY_STEPS.SETUP}`

    playStage?.setUp?.steps?.every((step, index) => {
      if (!step.isCompleted) {
        activeStepIndex = index
        return false
      }
      return true
    })
  } else if (!playStage?.use?.isCompleted) {
    activeStep = PLAY_STEPS.USE
    link = `/owner/${tenantId}/plays/${id}/${PLAY_STEPS.USE}`
  } else if (
    (playStage?.use?.isCompleted && !playStage.isCompleted) ||
    (playStage && playStage.isCompleted)
  ) {
    activeStep = PLAY_STEPS.GUIDE
    link = `/owner/${tenantId}/plays/${id}/${PLAY_STEPS.GUIDE}`
  }

  if (history.location.search.includes(PLAY_INTEGRATION_QUERY_PARAMS.SUCCESS)) {
    link = link + PLAY_INTEGRATION_QUERY_PARAMS.SUCCESS
  }

  return {
    activeStep,
    link,
    activeStepIndex,
  }
}

export const getPlayStageAndAssessmentResponse = ({ assessments, play }) => {
  let playStage
  if (assessments) {
    playStage = JSON.parse(assessments?.playStage)

    assessments.assessmentResponse = assessments.assessmentResponse
      ? JSON.parse(assessments.assessmentResponse)
      : []
  }

  if (!playStage && !play.playTabs) {
    // get play steps
    let getPlaySteps = play?.setup?.steps?.map((step) => {
      return {
        isCompleted: false,
        id: step?.id,
        icon: step?.icon,
        title: step?.title,
      }
    })

    // create play-state
    playStage = {
      isCompleted: false,
      guide: {
        isCompleted: false,
      },
      setUp: {
        isCompleted: false,
        steps: getPlaySteps,
      },
      use: {
        isCompleted: false,
      },
    }
  } else if (!playStage && play.playTabs) {
    playStage = []
    for (let i = 0; i < play.playTabs.length; i++) {
      for (let j = 0; j < play.playTabs[i].tabData.steps.length; j++) {
        set(playStage, `[${i}].steps[${j}].isCompleted`, false)
      }
    }
  }
  return { playStage, assessmentResponse: assessments }
}

export const sortHandlerHelper = ({ sortField, sortOrder, fieldType, data }) => {
  try {
    if (data?.length === 0) {
      return []
    }

    let sortedData = [...data]

    if (sortField !== null) {
      sortedData.sort((a, b) => {
        if (fieldType === 'date') {
          if (sortOrder === 'ASC') {
            let dateA: any = new Date(a[sortField])
            let dateB: any = new Date(b[sortField])
            return dateA - dateB
          } else {
            let dateA: any = new Date(a[sortField])
            let dateB: any = new Date(b[sortField])
            return dateB - dateA
          }
        } else {
          if (a[sortField].toLowerCase() < b[sortField].toLowerCase()) {
            return sortOrder === 'ASC' ? -1 : 1
          }
          if (a[sortField] > b[sortField]) {
            return sortOrder === 'ASC' ? 1 : -1
          }
        }

        return 0
      })
    }

    return sortedData
  } catch (err: any) {
    console.log('error: sortHandlerHelper: ', err.message)
    return []
  }
}

export const getChatUsersHelper = ({ all_users, chatUsers }) => {
  try {
    let chatUsersList: any = []
    let { glRole } = chatUsers

    // skip those users in which glRoles key is not available
    all_users = all_users.filter((user) => {
      if (user?.glRoles) {
        return user
      } else {
        return false
      }
    })

    if (glRole === USER_ROLES.BUSINESS_OWNER) {
      if (chatUsers?.users && chatUsers?.users.length > 0) {
        chatUsers?.users.forEach((chatUser) => {
          all_users.find((user: any) => {
            if (user.id === chatUser.id) {
              chatUsersList.push(user)
              return true
            } else {
              return false
            }
          })
        })
      }
    } else if (glRole === USER_ROLES.BSO_ADMIN) {
      all_users.forEach((user) => {
        if (user?.glRoles) {
          chatUsersList.push(user)
        }
      })
    } else if (glRole === USER_ROLES.BSO_ADVISOR) {
      if (chatUsers?.users && chatUsers?.users.length > 0) {
        chatUsers?.users.forEach((chatUser) => {
          all_users.some((user: any) => {
            if (user.id === chatUser.id) {
              chatUsersList.push(user)
              return true
            }
            return false
          })
        })

        // add advisors & admins in array
        all_users.forEach((user: any) => {
          if (
            user.glRoles[0] === USER_ROLES.BSO_ADMIN ||
            user.glRoles[0] === USER_ROLES.BSO_ADVISOR
          ) {
            chatUsersList.push(user)
          }
        })
      }
    }

    return chatUsersList
  } catch (err: any) {
    console.log('error: getChatUsersHelper: ', err.message)
    return []
  }
}

export const dateFormatterHelper = (date) => {
  try {
    let updatedDate = ''

    if (!date) {
      return ''
    }

    if (moment().diff(moment(date), 'days') === 0) {
      updatedDate = 'Today'
    } else if (moment().diff(moment(date), 'days') === 1) {
      updatedDate = 'Yesterday'
    } else {
      updatedDate = moment(date).format('LL')
    }
    return updatedDate
  } catch (err) {
    console.log(err)
    return ''
  }
}

export const isImgLink = (url: string) => {
  if (typeof url !== 'string') return false
  return url.match(/^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/) != null
}

export const getFormData = () => {
  const userProfileForm = hookForms.getForm(FORM.USER_PROFILE_FORM)
  const BusinessForm = hookForms.getForm(FORM.USER_MANAGEMENT_BUSINESS_FORM)

  const otherBusinessForm = hookForms.getForm(FORM.BUSINESS_OTHER_DETAILS)

  const userProfileFormData = userProfileForm?.getValues()

  const BusinessFormData = BusinessForm?.getValues()

  const otherBusinessFormData = otherBusinessForm?.getValues()
  const data = { ...userProfileFormData, ...BusinessFormData, ...otherBusinessFormData }
  return data
}

export const formatNumber = (val: number) => {
  if (val) return val.toLocaleString('en-US')
  return val
}

export const timeDuration = (data: string) => {
  const inst = moment(data)
  if (Math.abs(inst.diff(moment(), 'weeks')) > 0) return inst.format('MMMM DD, YYYY')
  return inst.fromNow()
}

export const isSameObject = (data, orgData) => {
  const diffVal = find(data, (value, key) => {
    const refValue = get(orgData, key)
    if ((value || refValue) && !isEqual(value, refValue)) {
      return true
    }
    return false
  })
  return isNil(diffVal)
}

export const openNewWindow = (url, isLaunchType) => {
  const h = isLaunchType ? window.outerWidth : window.outerWidth / 2
  const w = isLaunchType ? window.outerHeight : window.outerHeight / 2
  const y = window.outerHeight / 2 + window.screenY - h / 2
  const x = window.outerWidth / 2 + window.screenX - w / 2

  window.open(
    url,
    'newWindow',
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyHistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  )
}
export const getImageUrl = (url) => {
  return `${MEDIA_BUCKET}/${url}`
}

export const checkPlayCompletion = ({ play, playStage }) => {
  let playCompleted = false
  if (play.isCompleted) {
    for (let i = 0; i < play.playTabs.length; i++) {
      for (let j = 0; j < play.playTabs[i].tabData.steps.length; j++) {
        set(playStage, `[${i}].isCompleted`, false)
      }
    }
  }
  playCompleted = play.isCompleted
  return { updatedPlayStage: playStage, playCompleted }
}

export const scrollToSpecificAssessment = (list, openAssessment, tenantId) => {
  scroller.scrollTo(`assessment-${list.assessmentType}`, {
    duration: 1500,
    delay: 100,
    smooth: true,
    containerId: 'containerElement',
    offset: -80,
  })
  if (openAssessment) openAssessment()
  if (get(list, 'type', '') === 'play') history.push(`/owner/${tenantId}/plays/${list.id}/guide`)
}

export const checkIndustry = (
  question,
  profileFormValues,
  subIndustryQuestion,
  foodIndustryQuestionOption,
  industryQuestion
) => {
  if (
    profileFormValues &&
    subIndustryQuestion &&
    question?.id === subIndustryQuestion?.id &&
    profileFormValues[industryQuestion.id] !== foodIndustryQuestionOption?.id
  ) {
    return false
  } else return true
}
