import {
  ADD_ADVISOR_ACCOUNT,
  ADD_BUSINESS_ACCOUNT,
  GET_USERS,
  RESET_ACCOUNT_ERROR,
  RESET_ADVISOR_BUSINESS_FORMS,
  SET_ADVISOR_ACTIVE_STEP,
  SET_BUSINESS_OWNER_ACTIVE_STEP,
} from 'store/types'

export const setBusinessOwnerActiveStepAction = (step: string) => ({
  type: SET_BUSINESS_OWNER_ACTIVE_STEP,
  payload: step,
})

export const setAdvisorActiveStepAction = (step: string) => ({
  type: SET_ADVISOR_ACTIVE_STEP,
  payload: step,
})

export const resetAccountErrorsAction = () => ({
  type: RESET_ACCOUNT_ERROR,
})

export const getUsersAction = {
  STARTED: (payload: any) => ({ type: GET_USERS.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: GET_USERS.FULLFILLED, payload }),
  REJECTED: () => ({ type: GET_USERS.REJECTED }),
}

export const addBusinessAccountAction = {
  STARTED: (payload: any) => ({ type: ADD_BUSINESS_ACCOUNT.STARTED, payload }),
  FULLFILLED: (account) => ({ type: ADD_BUSINESS_ACCOUNT.FULLFILLED, payload: account }),
  REJECTED: (error: any) => ({ type: ADD_BUSINESS_ACCOUNT.REJECTED, payload: error }),
}

export const addAdvisorAccountAction = {
  STARTED: (payload: any) => ({ type: ADD_ADVISOR_ACCOUNT.STARTED, payload }),
  FULLFILLED: (account) => ({ type: ADD_ADVISOR_ACCOUNT.FULLFILLED, payload: account }),
  REJECTED: (error: any) => ({ type: ADD_ADVISOR_ACCOUNT.REJECTED, payload: error }),
}
export const resetAdvisorAndBusinessFormsAction = () => ({
  type: RESET_ADVISOR_BUSINESS_FORMS,
})

// export const getBusinessesAccountDetailsAction = {
//   STARTED: (payload: any) => ({ type: GET_BUSINESSES_ACCOUNT_DETAILS.STARTED, payload }),
//   FULLFILLED: (payload) => ({ type: GET_BUSINESSES_ACCOUNT_DETAILS.FULLFILLED, payload }),
//   REJECTED: (error: any) => ({ type: GET_BUSINESSES_ACCOUNT_DETAILS.REJECTED, payload: error }),
// }
// export const getAdvisorsAccountDetailsAction = {
//   STARTED: (payload: any) => ({ type: GET_ADVISORS_ACCOUNT_DETAILS.STARTED, payload }),
//   FULLFILLED: (payload) => ({ type: GET_ADVISORS_ACCOUNT_DETAILS.FULLFILLED, payload }),
//   REJECTED: (error: any) => ({ type: GET_ADVISORS_ACCOUNT_DETAILS.REJECTED, payload: error }),
// }

// export const getAdvisorsAction = {
//   STARTED: (payload: any) => ({ type: GET_ADVISORS.STARTED, payload }),
//   FULLFILLED: (payload) => ({ type: GET_ADVISORS.FULLFILLED, payload }),
//   REJECTED: (error: any) => ({ type: GET_ADVISORS.REJECTED, payload: error }),
// }
