import React, { useMemo } from 'react'
import { compact, join, get } from 'lodash'
import { timeDuration } from 'utils/helper'
import { IUSER_NOTIFICATION_TYPES } from 'components/NotificationPanel/types'

const Message = ({ notification }) => {
  const userName = useMemo(() => {
    const data = JSON.parse(notification.sender)
    return join(compact([get(data, 'firstName'), get(data, 'lastName')]), ' ')
  }, [notification])
  return (
    <div className="font-primary text-primary-text text-base">
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING && (
        <>
          <span className="font-semibold"> {userName}</span> has scheduled a meeting
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING_RESCHEDULED && (
        <>
          <span className="font-semibold"> {userName}</span> rescheduled a meeting
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING_CANCELLED && (
        <>
          <span className="font-semibold"> {userName}</span> cancelled a meeting
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CHAT && (
        <>
          <span className="font-semibold"> {userName}</span> sent a chat
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CLIENT_PROFILE_UPDATE && (
        <>
          <span className="font-semibold"> {userName}</span> has updated his profile details
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.NEW_CLIENT_ADDED && (
        <>
          <span className="font-semibold"> {userName}</span> has been assigned to you.
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CLIENT_REMOVED && (
        <>
          <span className="font-semibold"> {userName}</span> has been removed.
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.MOTHLY_ATTESTATION_TIME && (
        <>
          <span className="font-semibold"> {userName}</span> sent a chat
        </>
      )}
      {notification.createdAt && (
        <div className="font-normal leading-[10px] text-[10px]">
          {timeDuration(notification.createdAt)}
        </div>
      )}
    </div>
  )
}

export default Message
