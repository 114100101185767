import {
  GET_ALL_TOOLS,
  GET_CLIENT_PROFILE,
  GET_CLIENT_PROFILE_DETAILS,
  GET_MY_TOOLS,
  CLIENTS_SUMMARY,
  GET_RECOMMENDED_PLAYS_ASSSESSMENTS,
  GET_USER_CALENDAR_EVENTS,
  GET_BIG_QUERY,
  GET_SALES_DATA,
  GET_FINANCE_DATA,
  GET_PRODUCT_DATA,
  GET_CLIENT_EDIT_PROFILE_DETAIL,
  GET_CLIENT_ACTIVITY_SCORE,
  GET_CLIENT_ACTIVITY_LOGS,
  GET_CLIENT_ASSESSMENT_LIST,
  GET_CLIENT_CONNECTED_TOOLS,
  GET_BUSINESSES_ACCOUNT_DETAILS,
  GET_ADVISORS_ACCOUNT_DETAILS,
  GET_TACKLE_MEETING_EVENTS,
  GET_USER_BUSINESS_PROFILE,
} from 'store/types'

const initialState = {
  bigQuery: false,
  recommendations: false,
  clientsSummary: false,
  myToolsLoading: false,
  allToolsLoading: false,
  upcomingEventLoading: false,
  clientDetailsLoading: false,
  clientBusinessProfileLoading: false,
  salesDataLoading: false,
  financeDataLoading: false,
  productDataLoading: false,
  detailsLoading: false,
  scoreCardLoading: false,
  clientActivityLogLoading: false,
  clientConnectedToolsLoading: false,
  ownerDataLoading: false,
  advisorDataLoading: false,
  meetingDataLoading: false,
  businessProfileLoading: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECOMMENDED_PLAYS_ASSSESSMENTS.STARTED:
      return {
        ...state,
        recommendations: true,
      }
    case GET_RECOMMENDED_PLAYS_ASSSESSMENTS.FULLFILLED:
      return {
        ...state,
        recommendations: false,
      }
    case CLIENTS_SUMMARY.STARTED:
      return {
        ...state,
        clientsSummary: true,
      }
    case CLIENTS_SUMMARY.FULLFILLED:
      return {
        ...state,
        clientsSummary: false,
      }
    case GET_MY_TOOLS.STARTED:
      return {
        ...state,
        myToolsLoading: true,
      }
    case GET_MY_TOOLS.FULLFILLED:
      return {
        ...state,
        myToolsLoading: false,
      }
    case GET_ALL_TOOLS.STARTED:
      return {
        ...state,
        allToolsLoading: true,
      }
    case GET_ALL_TOOLS.FULLFILLED:
      return {
        ...state,
        allToolsLoading: false,
      }
    case GET_USER_CALENDAR_EVENTS.STARTED:
      return {
        ...state,
        upcomingEventLoading: true,
      }
    case GET_USER_CALENDAR_EVENTS.FULLFILLED:
      return {
        ...state,
        upcomingEventLoading: false,
      }
    case GET_CLIENT_PROFILE.STARTED:
      return {
        ...state,
        clientDetailsLoading: true,
      }
    case GET_CLIENT_PROFILE.FULLFILLED:
      return {
        ...state,
        clientDetailsLoading: false,
      }
    case GET_CLIENT_PROFILE_DETAILS.STARTED:
      return {
        ...state,
        clientBusinessProfileLoading: true,
      }
    case GET_CLIENT_PROFILE_DETAILS.FULLFILLED:
      return {
        ...state,
        clientBusinessProfileLoading: false,
      }
    case GET_BIG_QUERY.STARTED:
      return {
        ...state,
        bigQuery: true,
      }
    case GET_BIG_QUERY.REJECTED:
    case GET_BIG_QUERY.FULLFILLED:
      return {
        ...state,
        bigQuery: false,
      }
    case GET_SALES_DATA.STARTED:
      return {
        ...state,
        salesDataLoading: true,
      }
    case GET_SALES_DATA.FULLFILLED:
      return {
        ...state,
        salesDataLoading: false,
      }
    case GET_FINANCE_DATA.STARTED:
      return {
        ...state,
        financeDataLoading: true,
      }
    case GET_FINANCE_DATA.FULLFILLED:
      return {
        ...state,
        financeDataLoading: false,
      }
    case GET_PRODUCT_DATA.STARTED:
      return {
        ...state,
        productDataLoading: true,
      }
    case GET_PRODUCT_DATA.FULLFILLED:
      return {
        ...state,
        productDataLoading: false,
      }
    case GET_CLIENT_EDIT_PROFILE_DETAIL.STARTED:
      return {
        ...state,
        detailsLoading: true,
      }
    case GET_CLIENT_EDIT_PROFILE_DETAIL.FULLFILLED:
      return {
        ...state,
        detailsLoading: false,
      }
    case GET_CLIENT_ACTIVITY_SCORE.STARTED:
      return {
        ...state,
        scoreCardLoading: true,
      }
    case GET_CLIENT_ACTIVITY_SCORE.FULLFILLED:
      return {
        ...state,
        scoreCardLoading: false,
      }
    case GET_CLIENT_ACTIVITY_LOGS.STARTED:
      return {
        ...state,
        clientActivityLogLoading: true,
      }
    case GET_CLIENT_ACTIVITY_LOGS.FULLFILLED:
      return {
        ...state,
        clientActivityLogLoading: false,
      }
    case GET_CLIENT_ASSESSMENT_LIST.STARTED:
      return {
        ...state,
        clientAssessmentLoading: true,
      }
    case GET_CLIENT_ASSESSMENT_LIST.FULLFILLED:
      return {
        ...state,
        clientAssessmentLoading: false,
      }
    case GET_CLIENT_CONNECTED_TOOLS.STARTED:
      return {
        ...state,
        clientConnectedToolsLoading: true,
      }
    case GET_CLIENT_CONNECTED_TOOLS.FULLFILLED:
      return {
        ...state,
        clientConnectedToolsLoading: false,
      }
    case GET_BUSINESSES_ACCOUNT_DETAILS.STARTED:
      return {
        ...state,
        ownerDataLoading: true,
      }
    case GET_BUSINESSES_ACCOUNT_DETAILS.FULLFILLED:
      return {
        ...state,
        ownerDataLoading: false,
      }
    case GET_ADVISORS_ACCOUNT_DETAILS.STARTED:
      return {
        ...state,
        advisorDataLoading: true,
      }
    case GET_ADVISORS_ACCOUNT_DETAILS.FULLFILLED:
      return {
        ...state,
        advisorDataLoading: false,
      }
    case GET_TACKLE_MEETING_EVENTS.STARTED:
      return {
        ...state,
        meetingDataLoading: true,
      }
    case GET_TACKLE_MEETING_EVENTS.FULLFILLED:
      return {
        ...state,
        meetingDataLoading: false,
      }
    case GET_USER_BUSINESS_PROFILE.STARTED:
      return {
        ...state,
        businessProfileLoading: true,
      }
    case GET_USER_BUSINESS_PROFILE.FULLFILLED:
      return {
        ...state,
        businessProfileLoading: false,
      }
    default:
  }
  return state
}

export default reducer
