import React, { memo, FC } from 'react'
interface IButtonFieldProps {
  name: string
  onClick?: any
  type?: any
  className?: any
  disable?: any
  loading?: any
  testId?: any
  variant?: any
  width?: any
  height?: any
  color?: any
  isFullWidth?: boolean
  icon?: any
}

const ButtonField: FC<IButtonFieldProps> = ({
  name,
  onClick,
  type,
  className,
  disable,
  loading,
  testId,
  variant,
  width,
  height,
  isFullWidth,
  icon,
}) => {
  const getClassess = () => {
    let classes = ` font-primary py-3 leading-snug relative items-center ${
      isFullWidth ? 'w-full' : ''
    } rounded-[4px] flex justify-center border border-transparent text-lg font-semibold text-white`
    if (disable) {
      classes = `${classes} bg-primary-disabled px-3`
    } else if (variant === 'primary') {
      classes = `${classes} bg-primary px-3 hover:bg-primary-brand active:bg-primary-active focus:bg-primary outline-0 focus:border-zinc`
    } else if (variant === 'secondary') {
      classes = `${classes} bg-tranparent text-primary outline outline-1 outline-primary hover:outline-primary-brand hover:text-primary-brand active:outline-active active:text-active focus:outline-primary focus:text-primary focus:border-zinc`
    }

    return classes
  }

  return (
    <>
      <button
        name={name}
        onClick={onClick}
        type={type}
        data-testid={testId}
        disabled={disable || loading}
        style={{ width, height }}
        className={getClassess() + ' ' + className}
      >
        {loading ? '...' : name}
        {icon && <img className="pl-2" src={icon} alt="icon" />}
      </button>
    </>
  )
}

ButtonField.defaultProps = {
  isFullWidth: true,
}

export default memo(ButtonField)
