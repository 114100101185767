import { GET_USER_FILES, RESET_IMAGE_URL, TOTAL_UPCOMING_MEETINGS, UPLOAD_FILE } from 'store/types'

const initialState = {
  uploadedImage: '',
  uploadFileLoading: false,
  userFiles: [],
  uploadFileErrors: '',
  totalUpComingMeeting: 0,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE.STARTED:
      return {
        ...state,
        uploadedImage: '',
        uploadFileErrors: '',
        uploadFileLoading: true,
      }
    case UPLOAD_FILE.FULLFILLED:
      return {
        ...state,
        ...action.payload,
        uploadFileLoading: false,
      }
    case GET_USER_FILES.FULLFILLED:
      return {
        ...state,
        userFiles: action.payload,
      }
    case TOTAL_UPCOMING_MEETINGS:
      return {
        ...state,
        totalUpComingMeeting: action.payload,
      }
    case RESET_IMAGE_URL:
      return {
        ...state,
        uploadedImage: '',
      }
    default:
  }
  return state
}

export default reducer
