import { combineReducers } from 'redux'
import { RESET_STATES } from 'store/types'
import user from 'store/reducers/user'
import auth from 'store/reducers/auth'
import goal from 'store/reducers/goal'
import play from 'store/reducers/play'
import form from 'store/reducers/form'
import owner from 'store/reducers/owner'
import admin from 'store/reducers/admin'
import common from 'store/reducers/common'
import clients from 'store/reducers/clients'
import account from 'store/reducers/account'
import stepper from 'store/reducers/stepper'
import calendar from 'store/reducers/calendar'
import playBook from 'store/reducers/playBook'
import assessment from 'store/reducers/assessment'
import progressbar from 'store/reducers/progressbar'
import changePassword from 'store/reducers/changePassword'
import userManagement from 'store/reducers/userManagement'
import loadingStatus from 'store/reducers/loadingStatus'
import managePlays from 'store/reducers/managePlays'

const appReducer = combineReducers({
  user,
  auth,
  goal,
  form,
  play,
  owner,
  admin,
  common,
  account,
  clients,
  stepper,
  calendar,
  playBook,
  assessment,
  progressbar,
  managePlays,
  changePassword,
  userManagement,
  loadingStatus,
})

const rootReducer = (state, action) => {
  if (action.type === RESET_STATES) {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
