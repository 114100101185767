import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import '@aws-amplify/ui-react/styles.css'

import { ampli, Environment } from './ampli'

ampli.load({
  environment: (process.env.REACT_APP_AMPLI_ENVIRONMENT || 'development') as Environment,
})

Sentry.init({
  dsn: 'https://b0bc65fe14a24b829828fc08ee5a5677@o4504536900894720.ingest.sentry.io/4504578052718592',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV || 'development',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
