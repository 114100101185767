import React, { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { Channel } from 'stream-chat'
import get from 'lodash/get'
import { ChannelPreviewUIComponentProps, ChatContextValue, useChatContext } from 'stream-chat-react'
import { useUnreadContext } from 'components/Chat/contexts/UnreadContext'
import { ArrowRightIcon, VerticalBarIcon, RedDotIcon, ElipseIcon } from 'components/Common/SvgIcons'
import type { StreamChatGenerics } from 'components/Chat/types'
import { getChannelName, getBusinessName } from 'components/Chat/helper'
import { assessmentIcons } from 'config'
import 'components/Chat/components/MessagingChannelPreview/MessagingChannelPreview.css'
import isEmpty from 'lodash/isEmpty'
import { isMobileOnly, isTablet } from 'mobile-device-detect'

type Props = ChannelPreviewUIComponentProps & {
  channel: Channel
  searchChannelTemp: any
  usersAssessmentResponseByQuestion: any
  chatUnreadMessageCountAction: any
  setShowChannelCreate: Dispatch<SetStateAction<boolean>>
  setActiveChannel?: ChatContextValue['setActiveChannel']
}

const MessagingChannelPreview = (props: Props) => {
  const {
    channel,
    setActiveChannel,
    setShowChannelCreate,
    usersAssessmentResponseByQuestion,
    unread,
    searchChannelTemp,
  } = props
  const { channel: activeChannel, client } = useChatContext<StreamChatGenerics>()
  const { channelMessageCount, setChatsUnreadCount, setChannelMessageCount } = useUnreadContext()
  const [members, setMembers]: any = useState([])

  const isActiveChannel = channel?.id === activeChannel?.id
  const [isChannelDisplay, setIsChannelDisplay] = useState(true)

  let icon: any = null
  if (members.length === 1) {
    icon = assessmentIcons.find(
      (icons) => icons.iconLabel === usersAssessmentResponseByQuestion[members[0].user.id]?.icon
    )
  }

  const channelMessageCounts = !isEmpty(channelMessageCount)
    ? channelMessageCount?.[channel.cid]
    : 0

  useEffect(() => {
    const findMembers = Object.values(channel.state.members).filter(
      ({ user }) => user?.id !== client.userID
    )
    if (findMembers) {
      setMembers(findMembers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUnreadCounts = () => {
    if (unread) {
      setChatsUnreadCount((chatsUnreadCount) => chatsUnreadCount - unread)
    }
  }

  const removeUnreadMessage = () => {
    setChannelMessageCount((state) => ({
      ...state,
      [channel.cid]: 0,
    }))
  }

  useEffect(() => {
    if (channel) {
      let isFound = false

      for (const property in channel?.state?.members) {
        let searchQuery = searchChannelTemp.toLowerCase().replace(/\s/g, '')
        let name: any = ''

        const user: any = get(channel, `state.${members[property]}.user`)
        if (user?.name) {
          name = user.name.toLowerCase().replace(/\s/g, '')
          searchQuery = searchQuery.toLowerCase().replace(/\s/g, '')

          if (name?.includes(searchQuery)) {
            isFound = true
          }
        } else {
          let firstName = get(user, 'firstName', '').toLowerCase().replace(/\s/g, '')
          let lastName = get(user, 'lastName', '').toLowerCase().replace(/\s/g, '')
          name = firstName + lastName
          if (name?.includes(searchQuery)) {
            isFound = true
          }
        }
      }

      if (isFound) {
        setIsChannelDisplay(true)
      } else {
        setIsChannelDisplay(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchChannelTemp])

  return (
    <>
      {isChannelDisplay && (
        <div
          className={`${
            isActiveChannel ? 'selected !bg-white' : ' '
          } border-t-[1px] border-secondary-text py-4 flex  cursor-pointer justify-between items-centerw-full  bg-cream-dark`}
          onClick={async () => {
            setShowChannelCreate(false)
            handleUnreadCounts()
            setActiveChannel?.(channel)
            removeUnreadMessage()

            await channel.markRead()
          }}
        >
          <div className="flex flex-row items-center">
            <span className="mdl:block lg:block xl:block hidden">
              <VerticalBarIcon className={`${isActiveChannel ? '' : 'hidden'}`} />
            </span>
            <span
              className={`${
                channel?.id === activeChannel?.id ? 'ml-[21px]' : 'xs:ml-[21px] mdl:ml-[27px]'
              } inline-flex `}
            >
              <span className={`${isActiveChannel ? 'ml-21' : 'ml-27'} flex items-center `}>
                {icon ? (
                  <icon.value className="bg-blue-curious rounded-full h-[40px] w-[67px] fill-white" />
                ) : (
                  <ElipseIcon className={'fill-blue-curious  h-[40px] w-[54px]'} />
                )}

                {channelMessageCounts > 0 && (
                  <span className="ml-[-8px]">
                    <RedDotIcon />
                  </span>
                )}
                <div className="channel-preview__content-wrapper !ml-6 mr-4">
                  <div className="channel-preview__content-top">
                    {members && members.length > 0 && (
                      <p className="channel-preview__content-name">
                        {getChannelName(members.filter((user) => user?.user_id !== client.userID))}
                      </p>
                    )}
                  </div>
                  <div className="text-sm font-primary text-primary-text">
                    {getBusinessName(members)}
                  </div>
                </div>
              </span>
            </span>
          </div>
          {(isMobileOnly || isTablet) && (
            <div className="flex self-end mr-5 mb-2 mdl:hidden">
              <ArrowRightIcon className={'h-[25px] w-[12px] fill-silver'} />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default MessagingChannelPreview
