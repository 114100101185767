import React from 'react'
import ContentLoader from 'react-content-loader'

import { colorsPallete } from 'config'

const AssessmentLoader = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 350 260"
      backgroundColor={`${colorsPallete.cream.dark}`}
      foregroundColor={`${colorsPallete.grey.lighter}`}
    >
      <rect x="60" y="50" rx="8" ry="8" width="221" height="15" />
      <rect x="60" y="90" rx="8" ry="8" width="221" height="15" />
      <rect x="60" y="130" rx="8" ry="8" width="221" height="15" />
      <rect x="245" y="225" rx="8" ry="8" width="85" height="12" />
      <rect x="2" y="225" rx="8" ry="8" width="85" height="12" />
      <rect x="1" y="215" rx="8" ry="8" width="331" height="1" />
    </ContentLoader>
  )
}

export default AssessmentLoader
