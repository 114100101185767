import React from 'react'

function Lock(props) {
  return (
    <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 0C11.4852 0 13.5 2.01472 13.5 4.5V6.75H15.4688C16.8667 6.75 18 7.88328 18 9.28125V19.9688C18 21.3667 16.8667 22.5 15.4688 22.5H2.53125C1.13328 22.5 0 21.3667 0 19.9688V9.28125C0 7.88328 1.13328 6.75 2.53125 6.75H4.5V4.5C4.5 2.01472 6.51472 0 9 0ZM15.4688 8.4375H2.53125C2.06526 8.4375 1.6875 8.81526 1.6875 9.28125V19.9688C1.6875 20.4347 2.06526 20.8125 2.53125 20.8125H15.4688C15.9347 20.8125 16.3125 20.4347 16.3125 19.9688V9.28125C16.3125 8.81526 15.9347 8.4375 15.4688 8.4375ZM9.00011 12.9375C9.93217 12.9375 10.6876 13.6931 10.6876 14.625C10.6876 15.5569 9.93217 16.3125 9.00011 16.3125C8.06816 16.3125 7.31261 15.5569 7.31261 14.625C7.31261 13.6931 8.06816 12.9375 9.00011 12.9375ZM9 1.6875C7.44671 1.6875 6.1875 2.9467 6.1875 4.5V6.75H11.8125V4.5C11.8125 2.9467 10.5533 1.6875 9 1.6875Z"
        fill="#212121"
      />
    </svg>
  )
}

export default Lock
