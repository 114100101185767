import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class OWNER_DATA_API {
  getSalesChartData = (businessId: string, chartName: string): Promise<any> => {
    return API.fetch(api['GetChartDataDocument'], { input: { businessId, chartName } })
  }
  getOwnerBusinessData = (input: any): Promise<any> => {
    return API.fetch(api['GetOwnerBusinessDataDocument'], { input })
  }
}

// eslint-disable-next-line
export default new OWNER_DATA_API()
