import {
  GET_USER_CONNECTORS,
  GET_OAUTH_URL,
  RESET_OAUTH_URL,
  GET_CALENDLY_USER,
  DELETE_UPCOMING_EVENTS,
  GET_USER_CALENDAR_EVENTS,
  GET_BO_ADVISOR,
  GET_USER_MEETING,
  HANDLE_CYCLR_OAUTH,
  UPDATE_CALENDAR_MEETING,
} from 'store/types'
import { CYCLR_CALENDAR } from 'config'

const initialState = {
  userConnectors: [],
  oAuthUrl: '',
  calendarEvents: [],
  calendlyCalendar: {},
  boAdvisors: [],
  googleOAuthLoading: false,
  outlookOAuthLoading: false,
  calendlyOAuthLoading: false,
  cancelMeetingLoading: false,
  isScheduled: false,
  isConnected: false,
  updatedCalendar: '',
}

const getOAuthLoading = (state, payload, isLoading) => {
  let googleOAuthLoading = state.googleOAuthLoading
  let outlookOAuthLoading = state.outlookOAuthLoading
  let calendlyOAuthLoading = state.calendlyOAuthLoading
  if (payload.calendar === CYCLR_CALENDAR.CALENDLY) {
    calendlyOAuthLoading = isLoading
  }
  if (payload.calendar === CYCLR_CALENDAR.GOOGLE_CALENDAR) {
    googleOAuthLoading = isLoading
  }
  if (payload.calendar === CYCLR_CALENDAR.MICROSOFT_OFFICE_365) {
    outlookOAuthLoading = isLoading
  }
  return { calendlyOAuthLoading, googleOAuthLoading, outlookOAuthLoading }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_CONNECTORS.FULLFILLED:
      return {
        ...state,
        userConnectors: action.payload,
      }
    case GET_OAUTH_URL.STARTED:
      return {
        ...state,
        ...getOAuthLoading(state, action.payload, true),
      }
    case GET_OAUTH_URL.FULLFILLED:
      return {
        ...state,
        oAuthUrl: action.payload.url,
        ...getOAuthLoading(state, action.payload, false),
      }
    case GET_OAUTH_URL.REJECTED:
      return {
        ...state,
        ...getOAuthLoading(state, action.payload, false),
      }
    case GET_USER_CALENDAR_EVENTS.FULLFILLED:
      return {
        ...state,
        calendarEvents: action.payload,
      }
    case DELETE_UPCOMING_EVENTS.REJECTED:
    case DELETE_UPCOMING_EVENTS.FULLFILLED:
      return {
        ...state,
        cancelMeetingLoading: false,
      }
    case DELETE_UPCOMING_EVENTS.STARTED:
      return {
        ...state,
        cancelMeetingLoading: true,
      }
    case GET_CALENDLY_USER.FULLFILLED:
      return {
        ...state,
        calendlyCalendar: action.payload,
      }
    case RESET_OAUTH_URL:
      return {
        ...state,
        oAuthUrl: '',
      }
    case GET_BO_ADVISOR.FULLFILLED:
      return {
        ...state,
        boAdvisors: action.payload,
      }

    case GET_USER_MEETING.FULLFILLED:
      return {
        ...state,
        isScheduled: action.payload,
      }
    case HANDLE_CYCLR_OAUTH.FULLFILLED:
      return {
        ...state,
        isConnected: action.payload.isConnected,
        oAuthUrl: action.payload.url,
      }
    case UPDATE_CALENDAR_MEETING.FULLFILLED:
      return {
        ...state,
        updatedCalendar: action.payload,
      }
    default:
  }
  return state
}

export default reducer
