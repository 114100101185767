import get from 'lodash/get'
import map from 'lodash/map'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'

const getArrData = (data, key) => {
  const arrData = compact(map(data, key))
  if (isEmpty(arrData)) return undefined
  return arrData
}

const getData = (recommendations, receivedKey) => {
  if (isEmpty(recommendations)) return undefined
  let data: any = []
  recommendations.forEach((recommendation) => {
    if (recommendation[receivedKey] === undefined) return
    recommendation[receivedKey].forEach((item) => {
      data.push(item)
    })
  })
  const recommendation = data.filter((item, pos, self) => {
    return self.indexOf(item) === pos
  })
  return recommendation
}

export const getMultipleRecommendations = (answers) => {
  const recommendations = getArrData(answers, 'action.recommendations')
  if (!recommendations) return undefined
  return {
    playRecommendedAt: get(recommendations, '[0].playRecommendedAt'),
    plays: getData(recommendations, 'plays'),
    assessments: getData(recommendations, 'assessments'),
  }
}

export const createAssessmentRespnse = (activeQuestion, answers, allowMultiSelect) => {
  const responsePayload = {
    id: activeQuestion.id,
    value: allowMultiSelect ? map(answers, 'id') : answers.id,
    recommendations: allowMultiSelect
      ? getMultipleRecommendations(answers)
      : answers?.action?.recommendations,
  }
  return [responsePayload]
}
