import { CREATE_ADVISOR_ACCOUNT_STEPS, CREATE_BUSINESS_ACCOUNT_STEPS } from 'config'
import {
  ADD_ADVISOR_ACCOUNT,
  ADD_BUSINESS_ACCOUNT,
  GET_USERS,
  RESET_ACCOUNT_ERROR,
  SET_ADVISOR_ACTIVE_STEP,
  SET_BUSINESS_OWNER_ACTIVE_STEP,
} from 'store/types'
const initialState = {
  businessOwnerStepper: {
    steps: [
      {
        title: CREATE_BUSINESS_ACCOUNT_STEPS.PROFILE_FORM,
      },
      {
        title: CREATE_BUSINESS_ACCOUNT_STEPS.ADITIONAL_DETAILS,
      },
      {
        title: CREATE_BUSINESS_ACCOUNT_STEPS.ASSIGN_AN_ADVISOR,
      },
      {
        title: CREATE_BUSINESS_ACCOUNT_STEPS.CREATE_ACCOUNT,
      },
      {
        title: CREATE_BUSINESS_ACCOUNT_STEPS.ACCOUNT_CREATED,
      },
    ],
    activeStep: CREATE_BUSINESS_ACCOUNT_STEPS.PROFILE_FORM,
  },
  advisorStepper: {
    steps: [
      {
        title: CREATE_ADVISOR_ACCOUNT_STEPS.PROFILE_FORM,
      },
      {
        title: CREATE_ADVISOR_ACCOUNT_STEPS.ASSIGN_A_CLIENT,
      },
      {
        title: CREATE_ADVISOR_ACCOUNT_STEPS.ADMIN_ACCESS,
      },
      {
        title: CREATE_ADVISOR_ACCOUNT_STEPS.CREATE_ACCOUNT,
      },
      {
        title: CREATE_ADVISOR_ACCOUNT_STEPS.ACCOUNT_CREATED,
      },
    ],
    activeStep: CREATE_ADVISOR_ACCOUNT_STEPS.PROFILE_FORM,
  },
  disableCreateButton: false,
  users: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_BUSINESS_OWNER_ACTIVE_STEP:
      return {
        ...state,
        businessOwnerStepper: { ...state.businessOwnerStepper, activeStep: action.payload },
      }
    case SET_ADVISOR_ACTIVE_STEP:
      return {
        ...state,
        advisorStepper: { ...state.advisorStepper, activeStep: action.payload },
      }
    case GET_USERS.FULLFILLED:
      return {
        ...state,
        users: action.payload,
      }
    case ADD_BUSINESS_ACCOUNT.STARTED:
      return {
        ...state,
        disableCreateButton: true,
      }
    case ADD_BUSINESS_ACCOUNT.REJECTED:
      return {
        ...state,
        disableCreateButton: false,
        error: action.payload.error.message,
      }
    case ADD_BUSINESS_ACCOUNT.FULLFILLED:
      return {
        ...state,
        disableCreateButton: false,
      }
    case ADD_ADVISOR_ACCOUNT.STARTED:
      return {
        ...state,
        disableCreateButton: true,
      }
    case ADD_ADVISOR_ACCOUNT.REJECTED:
      return {
        ...state,
        disableCreateButton: false,
        error: action.payload.error.message,
      }
    case ADD_ADVISOR_ACCOUNT.FULLFILLED:
      return {
        ...state,
        disableCreateButton: false,
      }
    case RESET_ACCOUNT_ERROR:
      return {
        ...state,
        error: null,
      }
    default:
  }
  return state
}

export default reducer
