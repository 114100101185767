import React, { useEffect, useMemo, useState } from 'react'
import { isTablet } from 'mobile-device-detect'
import history from 'utils/history'
import { actionOption, ADVISOR_STATUS } from 'config'
import { useParams } from 'react-router-dom'

import SelectField from 'components/Common/FormFields/SelectField'
import ButtonField from 'components/Common/FormFields/ButtonField'
import ButtonFields from 'components/Common/FormFields/ButtonFields'
import ModalBox from 'components/Common/ModalBox'
import { EditAccountContainer } from 'pages/Admin/container/UserManagement'

const TableActionsComponent = ({ userData }) => {
  let { isSelectedTab, tenantId } = useParams()

  let [activatedStatus, setActivatedStatus] = useState('')
  let [actions, setActions] = useState('')
  const [openUsermanagementModal, setOpenUsermanagementModal] = useState(false)

  useEffect(() => {
    if (userData && userData?.activated) {
      setActivatedStatus(userData?.activated)
    }
  }, [userData])

  const title = useMemo(() => {
    return isSelectedTab === 'businesses' ? 'Edit Business Account' : 'Edit Advisor Account'
  }, [isSelectedTab])

  return (
    <div className="flex flex-row-reverse items-center gap-3">
      <div>
        <ButtonFields
          name={'Edit'}
          variant="secondary"
          width="50px"
          height="38px"
          className="font-bold"
          onClick={() => {
            setOpenUsermanagementModal(true)
          }}
        />
      </div>
      {!isTablet && (
        <>
          {activatedStatus === ADVISOR_STATUS.PENDING && (
            <div>
              <ButtonField
                name={'Resend Invitation'}
                variant="secondary"
                width="180px"
                height="40px"
                className="pt-2 m-0"
              />
            </div>
          )}

          {activatedStatus === ADVISOR_STATUS.INACTIVE && (
            <div>
              <ButtonField
                name={'Send Invitation'}
                variant="secondary"
                width="180px"
                height="40px"
                className="pt-2 m-0"
              />
            </div>
          )}

          {activatedStatus === ADVISOR_STATUS.ACTIVE && (
            <>
              <div>
                <ButtonField
                  name={'Chat'}
                  variant="secondary"
                  width="80px"
                  height="40px"
                  className="pt-2 m-0 text-[14px] !font-bold"
                  onClick={() => {
                    history.push(`/admin/${tenantId}/chat/${userData.userId}`)
                  }}
                />
              </div>
              <div>
                <ButtonField
                  name={'Reset Password'}
                  variant="secondary"
                  width="180px"
                  height="40px"
                  className="pt-2 m-0 text-[14px] !font-bold"
                />
              </div>
            </>
          )}
        </>
      )}
      {isTablet && (
        <span>
          <SelectField
            classes={'min-w-[110px]'}
            name={'action'}
            placeholder="Actions"
            options={actionOption
              .filter((o) => o.action === activatedStatus)
              .map((opt) => {
                return { label: opt.label, value: opt.value }
              })}
            onChange={(value) => {
              setActions(value)
            }}
            value={actions}
          />
        </span>
      )}
      {openUsermanagementModal && (
        <div className="pt-4">
          <ModalBox
            onClose={() => setOpenUsermanagementModal(false)}
            title={title}
            classes="h-full rounded-[10px]"
            height={'h-max'}
          >
            <EditAccountContainer
              userId={userData.userId}
              isBusiness={isSelectedTab === 'businesses' ? true : false}
              setOpenUsermanagementModal={setOpenUsermanagementModal}
              openUsermanagementModal={openUsermanagementModal}
            />
          </ModalBox>
        </div>
      )}
    </div>
  )
}

export default TableActionsComponent
