import { DISABLE_PLAY, GET_ASSESSMENT_PLAY_LISTING, GET_DASHBOARD_LISTING } from 'store/types'

const initialState = {
  playData: {},
  dashboardList: [],
  assessmentPlayList: {},
  disablePlay: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_LISTING.FULLFILLED:
      return {
        ...state,
        dashboardList: action.payload,
      }
    case GET_ASSESSMENT_PLAY_LISTING.FULLFILLED:
      return {
        ...state,
        assessmentPlayList: action.payload,
      }
    case DISABLE_PLAY:
      return {
        ...state,
        disablePlay: action.payload,
      }
    default:
  }
  return state
}

export default reducer
