import React from 'react'

function CrossIcon({ className, id = '' }) {
  return (
    <svg
      id={`${id}-svg`}
      className={className}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.220366 15.2796C0.513474 15.5727 0.987708 15.5727 1.28115 15.2796L7.50005 9.05932L13.7204 15.2796C14.0135 15.5727 14.4877 15.5727 14.7811 15.2796C15.0743 14.9865 15.0743 14.5123 14.7811 14.2189L8.55943 7.99995L14.7802 1.77964C15.0733 1.48653 15.0733 1.01229 14.7802 0.718855C14.4871 0.425746 14.0129 0.425746 13.7194 0.718855L7.50005 6.94057L1.27974 0.720262C0.986629 0.427152 0.512397 0.427152 0.218959 0.720262C-0.0741501 1.01337 -0.0741501 1.48761 0.218959 1.78104L6.44068 7.99995L0.220366 14.2203C-0.0749474 14.5109 -0.0749474 14.989 0.220366 15.2796Z"
        // fill="#3F3F46"
        id={id}
      />
    </svg>
  )
}
export default CrossIcon
