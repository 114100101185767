import React from 'react'

function NotficationIcon({ className }) {
  return (
    <svg
      className={className}
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="24.9688" cy="25.5" rx="24.9688" ry="25.5" fill="#27272A" />
      <g clip-path="url(#clip0_8227_77510)">
        <path
          d="M21.0154 16.1523C21.9619 16.1523 22.7353 15.3843 22.7353 14.4324C22.7353 13.4805 21.9619 12.707 21.0154 12.707C20.0635 12.707 19.29 13.4805 19.29 14.4324C19.29 15.3843 20.0635 16.1523 21.0154 16.1523ZM21.0154 13.5237C21.513 13.5237 21.924 13.9348 21.924 14.4378C21.924 14.9408 21.513 15.3464 21.0154 15.3464C20.5124 15.3464 20.1013 14.9408 20.1013 14.4378C20.1013 13.9348 20.5124 13.5237 21.0154 13.5237Z"
          fill="#FAFAFA"
        />
        <path
          d="M21.0154 32.887C20.3609 32.887 19.8255 32.3515 19.8255 31.6971C19.8255 31.4753 19.6416 31.2914 19.4198 31.2914C19.1981 31.2914 19.0142 31.4753 19.0142 31.6971C19.0142 32.6598 19.7011 33.4657 20.6097 33.655V34.3527C20.6097 34.5745 20.7936 34.7583 21.0154 34.7583C21.2371 34.7583 21.421 34.5745 21.421 34.3527V33.6604C22.3297 33.4711 23.0166 32.6652 23.0166 31.7025C23.0166 30.5991 22.1187 29.7013 21.0154 29.7013C20.3609 29.7013 19.8255 29.1658 19.8255 28.5114C19.8255 27.8569 20.3555 27.3215 21.0154 27.3215C21.6752 27.3215 22.2053 27.8515 22.2053 28.5114C22.2053 28.7331 22.3892 28.917 22.6109 28.917C22.8327 28.917 23.0166 28.7331 23.0166 28.5114C23.0166 27.5486 22.3297 26.7427 21.421 26.5534V25.8557C21.421 25.634 21.2371 25.4501 21.0154 25.4501C20.7936 25.4501 20.6097 25.634 20.6097 25.8557V26.5534C19.7011 26.7427 19.0142 27.5486 19.0142 28.5114C19.0142 29.6147 19.912 30.5126 21.0154 30.5126C21.6698 30.5126 22.2053 31.048 22.2053 31.7025C22.2053 32.3569 21.6698 32.887 21.0154 32.887Z"
          fill="#FAFAFA"
        />
        <path
          d="M27.0728 21.7506H14.952C14.8439 21.7506 14.7411 21.7939 14.6654 21.8696C14.5897 21.9453 14.5464 22.0535 14.5464 22.1563V38.0631C14.5464 38.1712 14.5897 38.274 14.6654 38.3497C14.7411 38.4254 14.8439 38.4687 14.952 38.4687H27.0728C27.181 38.4687 27.2837 38.4254 27.3595 38.3497C27.4352 38.274 27.4784 38.1712 27.4784 38.0631V22.1563C27.4784 22.0535 27.4352 21.9453 27.3595 21.8696C27.2837 21.7939 27.181 21.7506 27.0728 21.7506ZM26.6671 37.652H15.3577V22.5619H26.6671V37.652Z"
          fill="#FAFAFA"
        />
        <path
          d="M40.0374 33.1735L32.1462 14.5137C31.8595 13.8376 31.3024 13.3021 30.6209 13.0371L22.9515 10.084L22.8974 10.0299C22.389 9.54314 21.7183 9.27271 21.0098 9.27271C20.3067 9.27271 19.636 9.54314 19.1276 10.0299L12.1288 16.7853C11.6042 17.2937 11.3013 18.0077 11.3013 18.7378V38.9986C11.3013 40.4968 12.5182 41.7137 14.011 41.7137H28.0086C29.0849 41.7137 30.0043 41.0809 30.4479 40.1723L38.5933 36.7324C39.5993 36.3051 40.2483 35.3261 40.2483 34.2336C40.2483 33.8658 40.178 33.5142 40.0374 33.1735ZM29.9124 38.9986C29.9124 40.0479 29.0578 40.9024 28.0086 40.9024H14.011C12.9671 40.9024 12.1126 40.0479 12.1126 38.9986V18.7324C12.1126 18.2186 12.3235 17.721 12.6913 17.364L19.6901 10.6195C20.047 10.2787 20.5176 10.0894 21.0098 10.0894C21.5074 10.0894 21.9779 10.2787 22.3349 10.6195L22.4268 10.706C22.4377 10.7168 22.4485 10.7276 22.4647 10.7384L29.3337 17.364C29.7015 17.721 29.9124 18.2186 29.9124 18.7324V38.9986ZM38.2742 35.986L30.7075 39.1825C30.7129 39.1176 30.7237 39.0581 30.7237 38.9986V18.7324C30.7237 18.0022 30.4208 17.2883 29.8962 16.7799L24.4551 11.5335L30.3289 13.7943C30.8102 13.9782 31.1997 14.3568 31.3998 14.8328L39.2856 33.4926C39.3883 33.7306 39.437 33.9794 39.437 34.2336C39.437 34.9908 38.9827 35.6885 38.2742 35.986Z"
          fill="#FAFAFA"
        />
      </g>
      <defs>
        <clipPath id="clip0_8227_77510">
          <rect width="44.625" height="44.625" fill="white" transform="translate(3.1875 3.1875)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NotficationIcon
