import React from 'react'

const Container = ({ children, className }) => {
  return (
    <div
      className={`w-full xs:px-3 sm:px-6 lg:px-12 xs:py-3 sm:py-6 md:py-8 mdl:py-7 ${className}`}
    >
      {children}
    </div>
  )
}
Container.defaultProps = {
  className: '',
}
export default Container
