import { GET_SALES_DATA, GET_PRODUCT_DATA, GET_FINANCE_DATA, GET_MARKETING_DATA } from 'store/types'

export const getSalesChartDataAction = {
  STARTED: (payload: any) => ({ type: GET_SALES_DATA.STARTED, payload }),
  FULLFILLED: (questions: any) => ({
    type: GET_SALES_DATA.FULLFILLED,
    payload: questions,
  }),
  REJECTED: () => ({ type: GET_SALES_DATA.REJECTED }),
}

export const getProductDataAction = {
  STARTED: (payload: any) => ({ type: GET_PRODUCT_DATA.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: GET_PRODUCT_DATA.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_PRODUCT_DATA.REJECTED }),
}

export const getFinanceDataAction = {
  STARTED: (payload: any) => ({ type: GET_FINANCE_DATA.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: GET_FINANCE_DATA.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_FINANCE_DATA.REJECTED }),
}

export const getMarketingChartDataAction = {
  STARTED: (payload: any) => ({ type: GET_MARKETING_DATA.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: GET_MARKETING_DATA.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_MARKETING_DATA.REJECTED }),
}
