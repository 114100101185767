import React from 'react'

function ElipseIcon({ className }) {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="1" cx="14" cy="14" r="14" />
    </svg>
  )
}

export default ElipseIcon
