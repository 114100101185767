import { call, put, takeLatest, select } from 'redux-saga/effects'
import { getAuthToken } from 'lib/auth'
import UserApi from 'api/user'
import history from 'utils/history'
import {
  GET_USER_CONNECTORS,
  GET_USER_CALENDAR_EVENTS,
  GET_OAUTH_URL,
  GET_CALENDLY_USER,
  DELETE_UPCOMING_EVENTS,
  GET_BO_ADVISOR,
  GET_USER_MEETING,
  SUBSCRIBE_CALENDLY_WEBHOOK,
  HANDLE_CYCLR_OAUTH,
  UPDATE_CALENDAR_MEETING,
  DELETE_GOOGLE_CALENDAR_EVENT,
  SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK,
  UPDATE_GOOGLE_CALENDAR_EVENT,
  CANCEL_OUTLOOK_MEETING,
} from 'store/types'
import CalendarApi from 'api/calendar'
import get from 'lodash/get'
import {
  getUserConnectorsAction,
  getUserCalendarEventsAction,
  getOAuthUrlAction,
  getCalendlyUserAction,
  getBoAdvisorAction,
  deleteUpcomingEventAction,
  getUserMeetingAction,
  subscribeCalendlyWebhookAction,
  handleCyclrOAuthAction,
  updateCalendarMeetingAction,
  subscribeGoogleCalendarWebhookAction,
} from 'store/actions/calendar'
import ClientsApi from 'api/clients'

import { integrationLogActions, PLAY_INTEGRATION_QUERY_PARAMS } from 'config'

const getUserEvents = (events) => {
  events = events.map((event) => ({
    ...event,
    end: event?.end ? JSON.parse(event.end) : null,
    start: event?.start ? JSON.parse(event.start) : null,
  }))
  return events
}
function* getUserConnectors(action) {
  try {
    const getUserConnectorsRespone = yield call(CalendarApi.getUserConnectors, action.payload)

    yield put(
      getUserConnectorsAction.FULLFILLED(
        get(getUserConnectorsRespone, 'getUserConnectors.data', [])
      )
    )
  } catch (error) {
    yield put(getUserConnectorsAction.REJECTED(error))
    console.log('get Goal Details error : ', error)
  }
}

function* getUserCalendarEvents(action) {
  try {
    let { calendarName, timeMax, timeMin, tenantId } = action.payload

    const getUserCalendarEventsRes = yield call(
      CalendarApi.getUserCalendarEvents,
      calendarName,
      timeMax,
      timeMin,
      tenantId
    )

    const events = getUserEvents(get(getUserCalendarEventsRes, 'getUserCalendarEvents.data', []))
    yield put(getUserCalendarEventsAction.FULLFILLED(events))
  } catch (error) {
    yield put(getUserCalendarEventsAction.REJECTED(error))
    console.log('get Goal Details error : ', error)
  }
}

function* getOAuthUrl(action) {
  try {
    const { calendar, targetOrigin } = action.payload
    const getOAuthUrlRes = yield call(CalendarApi.getOAuthUrl, calendar)
    const url = get(getOAuthUrlRes, 'getOAuthUrl.data', '').replace(
      'targetOrigin=https://www.google.com',
      `targetOrigin=${targetOrigin}`
    )

    yield put(getOAuthUrlAction.FULLFILLED({ url, calendar }))
  } catch (error) {
    const { calendar } = action.payload
    yield put(getOAuthUrlAction.REJECTED({ error, calendar }))
    console.log('get Goal Details error : ', error)
  }
}

function* getBoAdvisors(action) {
  try {
    const res = yield call(CalendarApi.getBoAdvisors, action.payload)
    yield put(getBoAdvisorAction.FULLFILLED(get(res, 'getBoAdvisors.data', [])))
  } catch (error) {
    yield put(getBoAdvisorAction.REJECTED(error))
  }
}

function* subscribeCalendlyWebhook(action) {
  try {
    const res = yield call(CalendarApi.subscribeCalendlyWebhook, action.payload)
    yield put(
      subscribeCalendlyWebhookAction.FULLFILLED(get(res, 'subscribeCalendlyWebhook.data', []))
    )
  } catch (error) {
    yield put(subscribeCalendlyWebhookAction.REJECTED(error))
  }
}

function* subscribeGoogleCalendarWebhook(action) {
  try {
    const res = yield call(CalendarApi.subscribeGoogleCalendarWebhook, action.payload)
    yield put(
      subscribeGoogleCalendarWebhookAction.FULLFILLED(
        get(res, 'subscribeGoogleCalendarWebhook.data', [])
      )
    )
  } catch (error) {
    yield put(subscribeGoogleCalendarWebhookAction.REJECTED(error))
  }
}

function* deleteUserCalendarUpcomingEvent(action) {
  try {
    const payload = {
      tenantId: action.payload.tenantId,
      eventId: action.payload.eventId,
      ownerId: action.payload.ownerId,
      advisorId: action.payload.advisorId,
    }
    yield call(ClientsApi.cancelTackleMeeting, payload)

    let { timeMax, timeMin, calendarName, tenantId } = action.payload

    const getUserCalendarEventsRes = yield call(
      CalendarApi.getUserCalendarEvents,
      calendarName,
      timeMax,
      timeMin,
      tenantId
    )
    const events = getUserEvents(get(getUserCalendarEventsRes, 'getUserCalendarEvents.data', []))
    yield put(getUserCalendarEventsAction.FULLFILLED(events))
    yield put(deleteUpcomingEventAction.FULLFILLED(events))
  } catch (error) {
    yield put(deleteUpcomingEventAction.REJECTED(error))
    console.log('error: ', error)
  }
}

function* getCalendlyUser() {
  try {
    const getCalendlyUserRes = yield call(CalendarApi.getCalendlyUser)
    yield put(getCalendlyUserAction.FULLFILLED(get(getCalendlyUserRes, 'getCalendlyUser.data', {})))
  } catch (error) {
    yield put(getCalendlyUserAction.REJECTED(error))
    console.log('get Goal Details error : ', error)
  }
}

function* getUserMeeting(action) {
  try {
    const boAdvisors = yield select((state) => state.calendar?.boAdvisors)

    let advisor = boAdvisors?.find((obj) => obj.schedulingUrl === action?.payload?.trim())

    if (advisor) {
      let advisorId = advisor.id

      const record = yield call(CalendarApi.getUserMeeting, advisorId)

      let { isScheduled } = JSON.parse(record?.getUserMeeting?.data)

      yield put(getUserMeetingAction.FULLFILLED(isScheduled))
    }
  } catch (error) {
    yield put(getCalendlyUserAction.REJECTED(error))
    console.log('get Goal Details error : ', error)
  }
}

function* handleCyclrOAuth(action) {
  const { connectorName, connector } = action.payload
  try {
    yield put(handleCyclrOAuthAction.FULLFILLED({ url: '', isConnected: false }))
    const getUserConnectorsRespone = yield call(CalendarApi.getUserConnectors, undefined)
    const userConnectors = get(getUserConnectorsRespone, 'getUserConnectors.data', [])
    const isConnected = userConnectors?.find((con) => con.name === connector)?.authenticated
    if (!isConnected) {
      const getOAuthUrlRes = yield call(CalendarApi.getOAuthUrl, connectorName)
      const url = `${get(getOAuthUrlRes, 'getOAuthUrl.data', '').replace(
        'targetOrigin=https://www.google.com',
        `targetOrigin=${window.location.href}${PLAY_INTEGRATION_QUERY_PARAMS.SUCCESS}`
      )}`

      yield put(handleCyclrOAuthAction.FULLFILLED({ url, isConnected }))
    } else {
      if (history.location.search.includes(PLAY_INTEGRATION_QUERY_PARAMS.SUCCESS)) {
        const user = yield select((state) => state.user.user)
        const play = yield select((state) => state.play.play)
        let tenantId = user.tenantId[0]
        const authToken = yield getAuthToken()

        yield call(UserApi.logActivity, {
          action: integrationLogActions[connectorName],
          logStatus: '',
          accessToken: authToken,
          tenantId,
          showClientActivity: true,
          type: play.id,
        })

        var newLink = history.location.pathname.split('?')

        history.replace(newLink[0])
      }

      yield put(handleCyclrOAuthAction.FULLFILLED({ url: '', isConnected }))
    }
  } catch (error) {
    console.log('get error : ', error)
  }
}

function* updateCalendarMeeting(action) {
  const {
    timeMin,
    timeMax,
    calendarName,
    id,
    tenantId,
    description,
    availability,
    attendees,
    start,
    end,
  } = action.payload
  const payloadData = {
    id,
    tenantId,
    description,
    availability,
    attendees,
    start,
    end,
  }
  const payload = {
    timeMin,
    timeMax,
    calendarName,
    tenantId,
  }
  try {
    yield call(ClientsApi.updateTackleMeeting, payloadData)
    yield call(getUserCalendarEvents, { payload: payload })
  } catch (error) {
    yield put(updateCalendarMeetingAction.REJECTED(error))
    console.log('updating calendar error : ', error)
  }
}

function* deleteGoogleCalendarEvent(action) {
  const { timeMin, timeMax, calendarName, tenantId, eventId } = action.payload

  const payloadData = {
    eventId: eventId,
    tenantId: tenantId,
  }
  const payload = {
    timeMin,
    timeMax,
    calendarName,
    tenantId,
  }
  try {
    yield call(CalendarApi.deleteGoogleCalendarEvent, payloadData)
    yield call(getUserCalendarEvents, { payload: payload })
  } catch (error) {
    yield put(updateCalendarMeetingAction.REJECTED(error))
    console.log('updating calendar error : ', error)
  }
}

function* updateGoogleCalendarEvent(action) {
  const { timeMin, timeMax, calendarName, id, tenantId, description, attendees, start, end } =
    action.payload
  const payloadData = {
    eventId: id,
    tenantId,
    description,
    attendees,
    start,
    end,
  }
  const payload = {
    timeMin,
    timeMax,
    calendarName,
    tenantId,
  }
  try {
    yield call(CalendarApi.updateGoogleCalendarEvent, payloadData)
    yield call(getUserCalendarEvents, { payload: payload })
  } catch (error) {
    yield put(updateCalendarMeetingAction.REJECTED(error))
    console.log('updating calendar error : ', error)
  }
}
function* cancelOutlookEvent(action) {
  const { timeMin, timeMax, calendarName, eventId, tenantId } = action.payload
  const payloadData = {
    eventId: eventId,
  }
  const payload = {
    timeMin,
    timeMax,
    calendarName,
    tenantId,
  }
  try {
    yield call(CalendarApi.cancelOutlookEvent, payloadData)
    yield call(getUserCalendarEvents, { payload: payload })
  } catch (error) {
    yield put(updateCalendarMeetingAction.REJECTED(error))
    console.log('cancel outlook event error : ', error)
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherCalendar() {
  yield takeLatest(GET_OAUTH_URL.STARTED, getOAuthUrl)
  yield takeLatest(GET_CALENDLY_USER.STARTED, getCalendlyUser)
  yield takeLatest(GET_USER_CONNECTORS.STARTED, getUserConnectors)
  yield takeLatest(GET_USER_CALENDAR_EVENTS.STARTED, getUserCalendarEvents)
  yield takeLatest(DELETE_UPCOMING_EVENTS.STARTED, deleteUserCalendarUpcomingEvent)
  yield takeLatest(GET_BO_ADVISOR.STARTED, getBoAdvisors)
  yield takeLatest(SUBSCRIBE_CALENDLY_WEBHOOK.STARTED, subscribeCalendlyWebhook)
  yield takeLatest(SUBSCRIBE_GOOGLE_CALENDAR_WEBHOOK.STARTED, subscribeGoogleCalendarWebhook)
  yield takeLatest(GET_USER_MEETING.STARTED, getUserMeeting)
  yield takeLatest(HANDLE_CYCLR_OAUTH.STARTED, handleCyclrOAuth)
  yield takeLatest(UPDATE_CALENDAR_MEETING.STARTED, updateCalendarMeeting)
  yield takeLatest(DELETE_GOOGLE_CALENDAR_EVENT.STARTED, deleteGoogleCalendarEvent)
  yield takeLatest(UPDATE_GOOGLE_CALENDAR_EVENT.STARTED, updateGoogleCalendarEvent)
  yield takeLatest(CANCEL_OUTLOOK_MEETING.STARTED, cancelOutlookEvent)
}
