import React from 'react'

const UserTableHeader = ({ children, className = '' }) => {
  return (
    <td className={`p-3 text-sm text-grey whitespace-nowrap font-primary ${className}`}>
      <div className="flex flex-col">{children}</div>
    </td>
  )
}

export default UserTableHeader
