import React, { Fragment, useEffect, useState } from 'react'

import PlusIcon from 'components/Common/SvgIcons/PlusIcon'
import { CrossIcon, TickIcon } from 'components/Common/SvgIcons'

const OwnerAssignment = ({
  owner,
  filteredAssignedOwners,
  removeOwner,
  index,
  isAssignedOwner,
  allFilteredOwners,
  addOwner,
  assignedOwners,
}) => {
  const [display, setDisplay] = useState(false)

  const [isNew, setIsNew] = useState(false)

  useEffect(() => {
    if (owner?.isNew) {
      setIsNew(true)
      setDisplay(true)
      setTimeout(() => {
        setIsNew(false)
        delete owner.isNew
      }, 3000)
    } else {
      setDisplay(true)
    }
    // eslint-disable-next-line
  }, [filteredAssignedOwners])

  return (
    <Fragment key={index}>
      {display && (
        <div className="flex justify-between pr-3">
          <div
            className={` rounded-[53px] px-5 py-[3px] mb-[6px] flex items-center  
          ${
            isAssignedOwner &&
            filteredAssignedOwners?.find((selected) => selected.value === owner.value)
              ? `${isNew ? ' bg-gerberared/[0.5] !border-0' : ' bg-primary/[0.5] !border-0'} `
              : ''
          }
          ${
            !isAssignedOwner && assignedOwners?.find((selected) => selected.value === owner.value)
              ? 'bg-primary opacity-50'
              : 'border-[1px] border-primary'
          }
          `}
          >
            <div className="w-full">
              <div className="flex flex-col">
                <label className="font-primary text-base text-primary-text break-all">
                  {owner.label},
                </label>
                <label className="font-primary text-base text-primary-text break-all">
                  {owner.email}
                </label>
              </div>
            </div>
            {isAssignedOwner && (
              <span
                className={` inline-flex pl-1 cursor-pointer`}
                key={index}
                onClick={() => removeOwner(owner)}
              >
                {filteredAssignedOwners?.find((selected) => selected.value === owner.value) ? (
                  <CrossIcon className={'h-[15px] w-[15px] fill-primary-text cursor-pointer'} />
                ) : (
                  <PlusIcon className={'h-[16.8px] w-[16.71px] fill-zinc'} />
                )}
              </span>
            )}
            {!isAssignedOwner && (
              <span
                className={` inline-flex pl-1`}
                key={index}
                onClick={() =>
                  addOwner(allFilteredOwners?.find((user: any) => user.value === owner.value))
                }
              >
                {assignedOwners?.find((selected) => selected.value === owner.value) ? (
                  <TickIcon className={'h-[16.8px] w-[16.71px] fill-zinc mt-1'} />
                ) : (
                  <span className="cursor-pointer">
                    <PlusIcon className={'h-[16.8px] w-[16.71px] fill-zinc '} />
                  </span>
                )}
              </span>
            )}
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default OwnerAssignment
