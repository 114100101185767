import React from 'react'
import ContentLoader from 'react-content-loader'

import { colorsPallete } from 'config'

const TableLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 115"
        backgroundColor={`${colorsPallete.cream.dark}`}
        foregroundColor={`${colorsPallete.grey.lighter}`}
      >
        <rect x="15" y="18" rx="2" ry="2" width="400" height="25" />
        <rect x="15" y="46" rx="2" ry="2" width="400" height="25" />
        <rect x="15" y="75" rx="2" ry="2" width="400" height="25" />
      </ContentLoader>
    </>
  )
}

export default TableLoader
