import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { isMobileOnly } from 'mobile-device-detect'
import SelectField from 'components/Common/FormFields/SelectField'
import InfiniteScroll from 'react-infinite-scroll-component'
import { AdminRightsContainer, EditAccountContainer } from 'pages/Admin/container/UserManagement'
import { actionOption } from 'config'
import { TextLargeWrapper } from 'components/Admin/UserManagement/style'
import ModalBox from 'components/Common/ModalBox'

const UserManagementList = ({ list, isBusinessTab = true, nextPaginatedDataHandler, pageSize }) => {
  const [selectedUser, setSelectedUser]: any = useState()
  let { isSelectedTab } = useParams()
  const [openUsermanagementModal, setOpenUsermanagementModal] = useState(false)

  const title = useMemo(() => {
    return isSelectedTab === 'businesses' && !isMobileOnly
      ? 'Edit Business Account'
      : isSelectedTab !== 'businesses' && !isMobileOnly
      ? 'Edit Advisor Account'
      : isMobileOnly
      ? 'Edit Account'
      : ''
  }, [isSelectedTab])

  useEffect(() => {
    nextPaginatedDataHandler({ pageSize: pageSize })
    // eslint-disable-next-line
  }, [])

  return (
    <div className="border-t-[1px] border-primary pb-4">
      {list && (
        <InfiniteScroll
          dataLength={list?.length}
          next={() => nextPaginatedDataHandler({ pageSize: pageSize })}
          hasMore={true}
          loader={''}
        >
          {list.map((list, index) => {
            return isMobileOnly ? (
              <div
                key={index}
                className="flex justify-between px-5 border-b-[1px] border-primary-outline pt-5"
              >
                <div>
                  <TextLargeWrapper>
                    <p className="font-bold">{list?.name || ''}</p>
                    <p className="pt-1">{list?.businessName} </p>
                    <p className="pt-2">{list?.created}</p>
                    <p className="pt-2 pb-5 capitalize">
                      {list.activated === 'active' ? list.updated : list.activated}
                    </p>
                    <p>{list.activated === 'inactive' ? '' : list.advisors}</p>
                  </TextLargeWrapper>
                  <div className="flex py-4">
                    <SelectField name={'action'} placeholder="Actions" options={actionOption} />
                  </div>
                  {!isBusinessTab && <AdminRightsContainer params={list} />}
                </div>
                <div>
                  <label
                    className="font-primary font-bold text-primary text-sm"
                    onClick={() => {
                      setOpenUsermanagementModal(true)
                      setSelectedUser(list)
                    }}
                  >
                    Edit
                  </label>
                </div>
              </div>
            ) : (
              <>
                <div
                  key={index}
                  className="grid grid-cols-8 px-5 border-b-[1px] border-primary-outline pt-5"
                >
                  <div className="col-span-2">
                    <TextLargeWrapper>
                      <p className="font-bold">{list?.name || ''}</p>
                      {isBusinessTab && <p className="pt-1">{list?.businessName}</p>}
                    </TextLargeWrapper>
                    <div className="flex py-4 items-center">
                      <SelectField name={'action'} options={actionOption} placeholder="Actions" />
                      <label
                        className="font-primary font-bold text-primary text-sm text-center pl-6"
                        onClick={() => {
                          setOpenUsermanagementModal(true)
                          setSelectedUser(list)
                        }}
                      >
                        Edit
                      </label>
                    </div>
                  </div>
                  <div className="col-start-6 col-span-3 text-right">
                    <TextLargeWrapper>
                      <p className="pt-2">{list?.created}</p>
                      <p className="pt-1 pb-2 capitalize">
                        {list.activated === 'active' ? list.updated : list.activated}
                      </p>
                      {isBusinessTab && <p>{list.activated === 'inactive' ? '' : list.advisors}</p>}
                    </TextLargeWrapper>
                  </div>
                  {!isBusinessTab && <AdminRightsContainer params={list} />}
                </div>
              </>
            )
          })}
        </InfiniteScroll>
      )}
      {openUsermanagementModal && (
        <div className="flex justify-between pt-4 w-[100%]">
          <ModalBox
            onClose={() => setOpenUsermanagementModal(false)}
            title={title}
            width="xs:w-full sm:w-auto rounded-[10px]"
            height={'h-max'}
          >
            <EditAccountContainer
              userId={selectedUser?.userId}
              isBusiness={isSelectedTab === 'businesses' ? true : false}
              openUsermanagementModal={openUsermanagementModal}
              setOpenUsermanagementModal={setOpenUsermanagementModal}
            />
          </ModalBox>
        </div>
      )}
    </div>
  )
}

export default React.memo(UserManagementList)
