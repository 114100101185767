import { LOGIN, SIGNUP, FORGOTPASSWORD, FORGOTPASSWORDSUBMIT, LOGOUT } from 'store/types'

export const loginAction = {
  STARTED: (email: string, password: string) => ({
    type: LOGIN.STARTED,
    payload: { email, password },
  }),
  FULLFILLED: (payload) => ({ type: LOGIN.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: LOGIN.REJECTED, payload: error }),
}

export const signupAction = {
  STARTED: (email: string, password: string) => ({
    type: SIGNUP.STARTED,
    payload: { email, password },
  }),
}

export const logoutAction = () => ({
  type: LOGOUT,
})

export const forgotPasswordAction = {
  STARTED: (email: string) => ({
    type: FORGOTPASSWORD.STARTED,
    payload: { email },
  }),
  FULLFILLED: (disbale: any) => ({ type: FORGOTPASSWORD.FULLFILLED, payload: false }),
  REJECTED: (error: any) => ({ type: FORGOTPASSWORD.REJECTED, payload: error }),
}

export const resetPasswordAction = {
  STARTED: (email, code, password) => ({
    type: FORGOTPASSWORDSUBMIT.STARTED,
    payload: { email, code, password },
  }),
  FULLFILLED: (disbale: any) => ({ type: FORGOTPASSWORDSUBMIT.FULLFILLED, payload: false }),
  REJECTED: (error: any) => ({ type: FORGOTPASSWORDSUBMIT.REJECTED, payload: error }),
}
