import React, { Fragment } from 'react'
import CircleWithCheckIcon from 'components/Common/SvgIcons/CircleWithCheckIcon'
import { COMPANY_NAME } from 'config'
import history from 'utils/history'

export default function TermsCondition() {
  return (
    <Fragment>
      <div className="min-h-min flex items-center justify-center mt-10">
        <div className="xs:w-[290px] sm:w-[650px] md:w-[750px] lg:w-[750px]">
          <div className="my-5 flex items-center justify-start content-start">
            <CircleWithCheckIcon className={'h-16 w-16'} />
            <div className="text-2xl font-primary ml-2">
              <h1>{COMPANY_NAME} Solutions</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-0 ">
        <div className="font-primary not-italic mb-24 max-w-[290px] sm:max-w-[650px] md:max-w-[750px] lg:max-w-[750px]">
          <div className="text-left text-base leading-6 mb-20 text-justify">
            <p className="text-2xl font-primary">
              <strong>TERMS OF SERVICE</strong>
            </p>
            <br />
            <strong>
              <p className="xs:text-[10px] sm:text-xs md:text-base lg:text-lg font-primary italic">
                Last Revised on December 27, 2022
              </p>
            </strong>
            <br />
            <p>
              Welcome to the Terms of Service (these “<strong>Terms</strong>”) for the{' '}
              {COMPANY_NAME}
              Platform, gotackle.app (the “<strong>Platform</strong>”), operated on behalf of{' '}
              {COMPANY_NAME}, Inc. (“
              <strong>Company</strong>”, “<strong>we</strong>” or “<strong>us</strong>”), and
              together with any content, tools, features and functionality offered on or through our
              Platform (the “Services”).
            </p>
            <br />
            <p>
              These Terms govern your access to and use of the Services. Please read these Terms
              carefully, as they include important information about your legal rights. By accessing
              and/or using the Services, you are agreeing to these Terms. If you do not understand
              or agree to these Terms, please do not use the Services.
            </p>
            <br />
            <p>
              For purposes of these Terms, “<strong>you</strong>” and “<strong>your</strong>” means
              you as the user of the Services. If you use the Services on behalf of a company or
              other entity then “you” includes you and that entity, and you represent and warrant
              that (a) you are an authorized representative of the entity with the authority to bind
              the entity to these Terms, and (b) you agree to these Terms on the entity's behalf.
            </p>
            <br />
            <p className="border border-black p-2">
              <strong>
                Please note that Section 8. contains an arbitration clause and class action waiver.
                By agreeing to these Terms, you agree (a) to resolve all disputes with us through
                binding individual arbitration, which means that you waive any right to have those
                disputes decided by a judge or jury, and (b) that you waive your right to
                participate in class actions, class arbitrations, or representative actions. You
                have the right to opt-out of arbitration as explained in Section 8.
              </strong>
            </p>
            <br />
            <h3>
              <strong>TABLE OF CONTENTS</strong>
            </h3>
            <br />
            <br />
            <br />
            <ol className="list-decimal list-inside">
              <li>
                <strong className="whitespace-pre ml-7"> WHO MAY USE THE SERVICES</strong>
              </li>
              <br />
              <div className="flex flex column">
                <p className="ml-12">
                  You must be 18 years of age or older and reside in the United States or any of its
                  territories to use the Services. By using the Services, you represent and warrant
                  that you meet these requirements
                </p>
              </div>
              <br />
              <li>
                <strong className="whitespace-pre ml-7"> USER ACCOUNTS</strong>
                <br />
                <ol>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>2.1</span>
                      <div className="ml-7">
                        <span className="underline">Creating and Safeguarding your Account</span>.
                        To use the Services, you may need to create an account (“
                        <strong>Account</strong>
                        ”). You agree to provide us with accurate, complete and updated information
                        for your Account. You can access, edit and update your Account via the
                        following means [
                        <mark>DESCRIBE HOW WEBSITE ALLOWS USERS TO ADJUST ACCOUNT SETTINGS</mark>
                        ]. You are solely responsible for any activity on your Account and for
                        maintaining the confidentiality and security of your password. We are not
                        liable for any acts or omissions by you in connection with your Account. You
                        must immediately notify us at support@gologicsolutions.com if you know or
                        have any reason to suspect that your Account or password have been stolen,
                        misappropriated or otherwise compromised, or in case of any actual or
                        suspected unauthorized use of your Account.
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>2.2</span>
                      <div className="ml-7">
                        <span className="underline">Access to Services</span>. Your access to the
                        Services is determined and governed by the small business development center
                        that has authorized your access and use of the services. In the event that
                        you no longer have access to the Services, please contact the small business
                        development center that authorized your access and use of the services.
                      </div>
                    </div>
                  </li>
                  <br />
                </ol>
              </li>
              <li>
                <strong className="ml-7">LOCATION OF OUR PRIVACY POLICY</strong>
              </li>
              <br />
              <ol>
                <li>
                  <div className="flex flex-column">
                    <span>3.1</span>
                    <div className="ml-7">
                      <span className="underline">Privacy Policy</span>. Our Privacy Policy
                      describes how we handle the information you provide to us when you use the
                      Services. For an explanation of our privacy practices, please visit our
                      Privacy Policy located at{' '}
                      <span
                        className="text-primary underline cursor-pointer"
                        onClick={() => history.push(`/privacy-policy`)}
                      >
                        {' '}
                        Policy
                      </span>
                    </div>
                  </div>
                </li>
                <br />
              </ol>
              <li>
                <strong className="ml-7">RESTRICTIONS</strong>
              </li>
              <br />
              <ol>
                <li>
                  <div className="flex flex-column">
                    <span>4.1</span>
                    <div className="ml-7">
                      <span className="underline">Access</span>.Your access and use of the Services
                      may be interrupted from time to time for any of several reasons, including,
                      without limitation, the malfunction of equipment, periodic updating,
                      maintenance or repair of the Service or other actions that Company, in its
                      sole discretion, may elect to take
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>4.2</span>
                    <div className="ml-7">
                      <span className="underline">Restrictions On Your Use of the Services</span>.
                      You may not do any of the following, unless applicable laws or regulations
                      prohibit these restrictions or you have our written permission to do so:
                    </div>
                  </div>
                </li>
                <br />
                <ol className="ml-10">
                  <li>
                    <div className="flex flex-column">
                      <span>(a)</span>
                      <div className="ml-7">
                        download, modify, copy, distribute, transmit, display, perform, reproduce,
                        duplicate, publish, license, create derivative works from, or offer for sale
                        any information contained on, or obtained from or through, the Services;
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(b)</span>
                      <div className="ml-7">
                        duplicate, decompile, reverse engineer, disassemble or decode the Services
                        (including any underlying idea or algorithm), or attempt to do any of the
                        same;
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(C)</span>
                      <div className="ml-7">
                        use, reproduce or remove any copyright, trademark, service mark, trade name,
                        slogan, logo, image, or other proprietary notation displayed on or through
                        the Services;
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(d)</span>
                      <div className="ml-7">
                        use automation software (bots), hacks, modifications (mods) or any other
                        unauthorized third-party software designed to modify the Services;
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(e)</span>
                      <div className="ml-7">
                        exploit the Services for any commercial purpose, including without
                        limitation communicating or facilitating any commercial advertisement or
                        solicitation;
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(f)</span>
                      <div className="ml-7">
                        access or use the Services in any manner that could disable, overburden,
                        damage, disrupt or impair the Services or interfere with any other party's
                        access to or use of the Services or use any device, software or routine that
                        causes the same;
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(g)</span>
                      <div className="ml-7">
                        attempt to gain unauthorized access to, interfere with, damage or disrupt
                        the Services, accounts registered to other users, or the computer systems or
                        networks connected to the Services;
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(h)</span>
                      <div className="ml-7">
                        circumvent, remove, alter, deactivate, degrade or thwart any technological
                        measure or content protections of the Services;
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(i)</span>
                      <div className="ml-7">
                        use any robot, spider, crawlers or other automatic device, process, software
                        or queries that intercepts, “mines,” scrapes or otherwise accesses the
                        Services to monitor, extract, copy or collect information or data from or
                        through the Services, or engage in any manual process to do the same;
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(j)</span>
                      <div className="ml-7">
                        introduce any viruses, trojan horses, worms, logic bombs or other materials
                        that are malicious or technologically harmful into our systems;
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(k)</span>
                      <div className="ml-7">
                        use the Services for illegal, harassing, unethical, or disruptive purposes;
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(l)</span>
                      <div className="ml-7">
                        violate any applicable law or regulation in connection with your access to
                        or use of the Services; or
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(m)</span>
                      <div className="ml-7">
                        access or use the Services in any way not expressly permitted by these
                        Terms.
                      </div>
                    </div>
                  </li>
                  <br />
                </ol>
              </ol>
              <li>
                <strong className="ml-7">OWNERSHIP AND CONTENT</strong>
              </li>
              <br />
              <ol>
                <li>
                  <div className="flex flex-column">
                    <span>5.1</span>
                    <div className="ml-7">
                      <span className="underline">Ownership of the Services</span>. The Services,
                      including their "look and feel" (e.g., text, graphics, images, logos),
                      proprietary content, information and other materials, are protected under
                      copyright, trademark and other intellectual property laws. You agree that the
                      Company and/or its licensors own all right, title and interest in and to the
                      Services (including any and all intellectual property rights therein) and you
                      agree not to take any action(s) inconsistent with such ownership interests. We
                      and our licensors reserve all rights in connection with the Services and its
                      content (other than Your Content), including, without limitation, the
                      exclusive right to create derivative works.
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>5.2</span>
                    <div className="ml-7">
                      <span className="underline">Ownership of Trademarks</span>. The Company’s
                      name, the Company’s logo and all related names, logos, product and service
                      names, designs and slogans are trademarks of the Company or its affiliates or
                      licensors. Other names, logos, product and service names, designs and slogans
                      that appear on the Services are the property of their respective owners, who
                      may or may not be affiliated with, connected to, or sponsored by us
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>5.3</span>
                    <div className="ml-7">
                      <span className="underline">Ownership of Feedback</span>. We welcome feedback,
                      comments & suggestions for improvements to the Services (“
                      <strong>Feedback</strong>
                      ”). You acknowledge and expressly agree that any contribution of Feedback does
                      not and will not give or grant you any right, title or interest in the
                      Services or in any such Feedback. All Feedback becomes the sole and exclusive
                      property of the Company, and the Company may use and disclose Feedback in any
                      manner and for any purpose whatsoever without further notice or compensation
                      to you and without retention by you of any proprietary or other right or
                      claim. You hereby assign to the Company any and all right, title and interest
                      (including, but not limited to, any patent, copyright, trade secret,
                      trademark, show-how, know- how, moral rights and any and all other
                      intellectual property right) that you may have in and to any and all Feedback.
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>5.4</span>
                    <div className="ml-7">
                      <span className="underline">Your Content License Grant</span>. In connection
                      with your use of the Services, you may be able to post, upload, or submit
                      content to be made available through the Services (“
                      <strong>Your Content</strong>”). In order to operate the Service, we must
                      obtain from you certain license rights in Your Content so that actions we take
                      in operating the Service are not considered legal violations. Accordingly, by
                      using the Service and uploading Your Content, you grant us a license to
                      access, use, host, cache, store, reproduce, transmit, display, publish,
                      distribute, and modify (for technical purposes, e.g., making sure content is
                      viewable on smartphones as well as computers and other devices) Your Content
                      but solely as required to be able to operate and provide the Services. You
                      agree that these rights and licenses are royalty-free, transferable,
                      sub-licensable, worldwide and irrevocable (for so long as Your Content is
                      stored with us), and include a right for us to make Your Content available to,
                      and pass these rights along to, others with whom we have contractual
                      relationships related to the provision of the Services, solely for the purpose
                      of providing such Services, and to otherwise permit access to or disclose Your
                      Content to third parties if we determine such access is necessary to comply
                      with our legal obligations. As part of the foregoing license grant you agree
                      that the other users of the Services shall have the right to comment on and/or
                      tag Your Content and/or to use, publish, display, modify or include a copy of
                      Your Content as part of their own use of the Services; except that the
                      foregoing shall not apply to any of Your Content that you post privately for
                      non-public display on the Services. By posting or submitting Your Content
                      through the Services, you represent and warrant that you have, or have
                      obtained, all rights, licenses, consents, permissions, power and/or authority
                      necessary to grant the rights granted herein for Your Content. You agree that
                      Your Content will not contain material subject to copyright or other
                      proprietary rights, unless you have the necessary permission or are otherwise
                      legally entitled to post the material and to grant us the license described
                      above.
                    </div>
                  </div>
                </li>
                <br />
              </ol>
              <li>
                <strong className="ml-7">THIRD PARTY SERVICES AND MATERIALS </strong>
              </li>
              <br />
              <ol>
                <li>
                  <div className="flex flex-column">
                    <span>6.1</span>
                    <div className="ml-7">
                      <span className="underline">
                        Use of Third Party Materials in the Services
                      </span>
                      . Certain Services may display, include or make available content, data,
                      information, applications or materials from third parties (“
                      <strong>Third Party Materials</strong>
                      ”) or provide links to certain third party websites or tools. By using the
                      Services, you acknowledge and agree that the Company is not responsible for
                      examining or evaluating the content, accuracy, completeness, availability,
                      timeliness, validity, copyright compliance, legality, decency, quality or any
                      other aspect of such Third Party Materials or websites. We do not warrant or
                      endorse and do not assume and will not have any liability or responsibility to
                      you or any other person for any third-party services, Third Party Materials or
                      third-party websites, or for any other materials, products, or services of
                      third parties. Third Party Materials and links to other websites are provided
                      solely as a convenience to you.
                    </div>
                  </div>
                </li>
                <br />
              </ol>
              <li>
                <strong className="ml-7">
                  DISCLAIMERS, LIMITATIONS OF LIABILITY AND INDEMNIFICATION
                </strong>
              </li>
              <br />
              <ol>
                <li>
                  <div className="flex flex-column">
                    <span>7.1</span>
                    <div className="ml-7">
                      <span className="underline">Disclaimers</span>. YOUR ACCESS TO AND USE OF THE
                      SERVICES ARE AT YOUR OWN RISK. YOU UNDERSTAND AND AGREE THAT THE SERVICES ARE
                      PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. WITHOUT LIMITING THE
                      FOREGOING, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE COMPANY,
                      ITS PARENTS, AFFILIATES, RELATED COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES,
                      AGENTS, REPRESENTATIVES, PARTNERS AND LICENSORS (THE “
                      <strong>THE COMPANY ENTITIES</strong>
                      ”) DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF
                      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. THE
                      COMPANY ENTITIES MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL
                      RESPONSIBILITY AND LIABILITY FOR: (A) THE COMPLETENESS, ACCURACY,
                      AVAILABILITY, TIMELINESS, SECURITY OR RELIABILITY OF THE SERVICES; (B) ANY
                      HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM THAT RESULTS FROM
                      YOUR ACCESS TO OR USE OF THE SERVICES; (C) THE OPERATION OR COMPATIBILITY WITH
                      ANY OTHER APPLICATION OR ANY PARTICULAR SYSTEM OR DEVICE; (D) WHETHER THE
                      SERVICES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED,
                      SECURE OR ERROR-FREE BASIS; AND (E) THE DELETION OF, OR THE FAILURE TO STORE
                      OR TRANSMIT, YOUR CONTENT AND OTHER COMMUNICATIONS MAINTAINED BY THE SERVICES.
                      NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM THE COMPANY
                      ENTITIES OR THROUGH THE SERVICES, WILL CREATE ANY WARRANTY OR REPRESENTATION
                      NOT EXPRESSLY MADE HEREIN.
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>7.2</span>
                    <div className="ml-7">
                      <span className="underline">Limitations of Liability</span>. TO THE EXTENT NOT
                      PROHIBITED BY LAW, YOU AGREE THAT IN NO EVENT WILL THE COMPANY ENTITIES BE
                      LIABLE (A) FOR DAMAGES OF ANY KIND, INCLUDING DIRECT, INDIRECT, SPECIAL,
                      EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT
                      LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE, DATA OR
                      PROFITS, BUSINESS INTERRUPTION OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF
                      OR RELATED TO YOUR USE OR INABILITY TO USE THE SERVICES), HOWEVER CAUSED AND
                      UNDER ANY THEORY OF LIABILITY, WHETHER UNDER THESE TERMS OR OTHERWISE ARISING
                      IN ANY WAY IN CONNECTION WITH THE SERVICES OR THESE TERMS AND WHETHER IN
                      CONTRACT, STRICT LIABILITY OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) EVEN IF
                      THE COMPANY ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, OR
                      (B) FOR ANY OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER RESULTING FROM OR
                      ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE DELIVERY, USE OR
                      PERFORMANCE OF THE SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                      LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION OR
                      LIMITATION MAY NOT APPLY TO YOU. THE COMPANY ENTITIES’ TOTAL LIABILITY TO YOU
                      FOR ANY DAMAGES FINALLY AWARDED SHALL NOT EXCEED ONE HUNDRED DOLLARS
                      ($100.00). THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED
                      REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>7.3</span>
                    <div className="ml-7">
                      <span className="underline">Indemnification</span>. By entering into these
                      Terms and accessing or using the Services, you agree that you shall defend,
                      indemnify and hold the Company Entities harmless from and against any and all
                      claims, costs, damages, losses, liabilities and expenses (including attorneys’
                      fees and costs) incurred by the Company Entities arising out of or in
                      connection with: (a) your violation or breach of any term of these Terms or
                      any applicable law or regulation; (b) your violation of any rights of any
                      third party; (c) your access to or use of the Services; (d) Your Content, or
                      (e) your negligence or wilful misconduct.
                    </div>
                  </div>
                </li>
                <br />
              </ol>
              <li>
                <strong className="ml-7">ARBITRATION AND CLASS ACTION WAIVER</strong>
              </li>
              <br />
              <ol>
                <li>
                  <div className="flex flex-column">
                    <span>8.1</span>
                    <div className="ml-7">
                      <span className="underline">Informal Process First</span>. You agree that, in
                      the event of any dispute between you and the Company Entities, you will first
                      contact the Company in writing and make a good faith sustained effort to
                      resolve the dispute before resorting to more formal means of resolution,
                      including without limitation, any arbitration or court action.
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>8.2</span>
                    <div className="ml-7">
                      <span className="underline">
                        Arbitration Agreement and Class Action Waiver
                      </span>
                    </div>
                  </div>
                </li>
                <br />
                <ol className="ml-10">
                  <li>
                    <div className="flex flex-column">
                      <span>(a)</span>
                      <div className="ml-7">
                        If the dispute remains unresolved thirty (30) days after you first contact
                        the Company in writing under Section 8.1, any remaining dispute,
                        controversy, or claim (collectively, “<strong>Claim</strong>”) relating in
                        any way to your purchase or use of the Products, will be resolved by
                        arbitration, including threshold questions of arbitrability of the Claim.
                        You and the Company agree that any Claim will be settled by final and
                        binding arbitration, using the English language, administered by JAMS under
                        its Comprehensive Arbitration Rules and Procedures (the{' '}
                        <strong>“JAMS Rules</strong>”) then in effect (those rules ae deemed to be
                        incorporated by reference into this section, and as of the date of these
                        Terms). The JAMS Rules and the JAMS Consumer Arbitration Minimum Standards,
                        the latter effective July 15, 2009, are available at
                        <a className="text-blue" href="https://www.jamsadr.com" tabIndex={-1}>
                          https://www.jamsadr.com
                        </a>
                        .
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(b)</span>
                      <div className="ml-7">
                        <strong>
                          Arbitration will be handled by a sole, neutral arbitrator in accordance
                          with the JAMS Rules. Judgment on the arbitration award may be entered in
                          any court that has jurisdiction. Any arbitration under these Terms will
                          take place on an individual basis – class, consolidated or representative
                          arbitrations and class, consolidated or representative actions are not
                          permitted. You understand that by agreeing to these Terms, you and the
                          Company are each waiving the right to trial by jury or to participate in a
                          class, consolidated or representative action or class, consolidated or
                          representative arbitration. Court review of an arbitration award is
                          limited. The arbitrator, however, can award on an individual basis the
                          same damages and relief as a court (including injunctive and declaratory
                          relief, or statutory damages).
                        </strong>
                        Notwithstanding the foregoing, you and the Company will have the right to
                        bring an action in a court of proper jurisdiction for injunctive or other
                        equitable or conservatory relief, pending a final decision by the
                        arbitrator. You may instead assert your claim in “small claims” court, but
                        only if your claim qualifies, your claim remains in such court and your
                        claim remains on an individual, non-representative and non-class basis.
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(c)</span>
                      <div className="ml-7">
                        The place of arbitration will be Chicago, Illinois, but if you prefer a
                        hearing in or closer to your hometown area, you and the Company will confer
                        in good faith in order to identify a reasonable alternative location.
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(d)</span>
                      <div className="ml-7">
                        Each of the Parties shall cooperate in good faith in the discovery or
                        exchange of nonprivileged information relevant to the dispute as necessary
                        in accordance with the JAMS Rules
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(e)</span>
                      <div className="ml-7">
                        The arbitrator’s award will consist of a written statement stating the
                        disposition of each claim, and the award will also provide a concise written
                        statement of the essential findings and conclusions on which the award is
                        based.
                      </div>
                    </div>
                  </li>
                  <br />
                  <li>
                    <div className="flex flex-column">
                      <span>(f)</span>
                      <div className="ml-7">
                        You and the Company shall maintain the confidential nature of the
                        arbitration proceeding and the award, including the hearing, except as may
                        be necessary to prepare for or conduct the arbitration hearing on the
                        merits, or except as may be necessary in connection with a court application
                        for a preliminary remedy, a judicial challenge to an award or its
                        enforcement or unless otherwise required by law or judicial decision.
                      </div>
                    </div>
                  </li>
                  <br />
                </ol>
                <li>
                  <div className="flex flex-column">
                    <span>8.3</span>
                    <div className="ml-7">
                      <span className="underline">Costs of Arbitration</span>. Payment for any and
                      all reasonable JAMS filing, administrative and arbitrator fees will be in
                      accordance with the JAMS Rules and the JAMS Consumer Arbitration Minimum
                      Standards. If the value of your claim does not exceed $10,000, the Company
                      will pay for the reasonable filing, administrative and arbitrator fees
                      associated with the arbitration, unless the arbitrator finds that either the
                      substance of your claim or the relief sought was frivolous or brought for an
                      improper purpose.
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <strong>
                    <div className="flex flex-column">
                      <span>8.4</span>
                      <div className="ml-7">
                        <span className="underline">Opt-Out</span>. You have the right to opt-out
                        and not be bound by the arbitration provisions set forth in these Terms by
                        sending written notice of your decision to opt-out to or to the mailing
                        address listed in the “How to Contact Us” section of these Terms. The notice
                        must be sent to the Company within thirty (30) days of your purchase of the
                        Products or agreeing to these Terms, otherwise you shall be bound to
                        arbitrate disputes in accordance with these Terms. If you opt-out of these
                        arbitration provisions, the Company also will not be bound by them.
                      </div>
                    </div>
                  </strong>
                </li>
                <br />
              </ol>
              <li>
                <strong className="ml-7">ADDITIONAL PROVISIONS</strong>
              </li>
              <br />
              <ol>
                <li>
                  <div className="flex flex-column">
                    <span>9.1</span>
                    <div className="ml-7">
                      <span className="underline">Updating These Terms</span>. We may modify these
                      Terms from time to time in which case we will update the “Last Revised” date
                      at the top of these Terms. If we make changes that are material, we will use
                      reasonable efforts to attempt to notify you, such as by e-mail and/or by
                      placing a prominent notice on the first page of the Platform. However, it is
                      your sole responsibility to review these Terms from time to time to view any
                      such changes. The updated Terms will be effective as of the time of posting,
                      or such later date as may be specified in the updated Terms. Your continued
                      access or use of the Services after the modifications have become effective
                      will be deemed your acceptance of the modified Terms.
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>9.2</span>
                    <div className="ml-7">
                      <span className="underline">Termination of License and Your Account</span>. If
                      you breach any of the provisions of these Terms, all access to the Services
                      granted by the Company will terminate automatically. Additionally, the Company
                      may suspend, disable, or delete your Account and/or the Services (or any part
                      of the foregoing) with or without notice, for any or no reason. If the Company
                      deletes your Account for any suspected breach of these Terms by you, you are
                      prohibited from re-registering for the Services under a different name. In the
                      event of Account deletion for any reason, the Company may, but is not
                      obligated to, delete any of Your Content. the Company shall not be responsible
                      for the failure to delete or deletion of Your Content. All sections which by
                      their nature should survive the termination of these Terms shall continue in
                      full force and effect subsequent to and notwithstanding any termination of
                      this Agreement by the Company or you. Termination will not limit any of the
                      Company’s other rights or remedies at law or in equity.
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>9.3</span>
                    <div className="ml-7">
                      <span className="underline">Injunctive Relief</span>. You agree that a breach
                      of these Terms will cause irreparable injury to the Company for which monetary
                      damages would not be an adequate remedy and the Company shall be entitled to
                      equitable relief in addition to any remedies it may have hereunder or at law
                      without a bond, other security or proof of damages
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>9.4</span>
                    <div className="ml-7">
                      <span className="underline">California Residents</span>
                      If you are a California resident, in accordance with Cal. Civ. Code § 1789.3,
                      you may report complaints to the Complaint Assistance Unit of the Division of
                      Consumer Services of the California Department of Consumer Affairs by
                      contacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento,
                      CA 95834, or by telephone at (800) 952-5210.
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>9.5</span>
                    <div className="ml-7">
                      <span className="underline">Miscellaneous</span>. If any provision of these
                      Terms shall be unlawful, void or for any reason unenforceable, then that
                      provision shall be deemed severable from these Terms and shall not affect the
                      validity and enforceability of any remaining provisions. These Terms and the
                      licenses granted hereunder may be assigned by the Company but may not be
                      assigned by you without the prior express written consent of the Company. No
                      waiver by either party of any breach or default hereunder shall be deemed to
                      be a waiver of any preceding or subsequent breach or default. The section
                      headings used herein are for reference only and shall not be read to have any
                      legal effect. The Services are operated by us in the United States. Those who
                      choose to access the Services from locations outside the United States do so
                      at their own initiative and are responsible for compliance with applicable
                      local laws. These Terms are governed by the laws of the State of Illinois,
                      without regard to conflict of laws rules, and the proper venue for any
                      disputes arising out of or relating to any of the same will be the arbitration
                      venue set forth in Section 8, or if arbitration does not apply, then the state
                      and federal courts located in Chicago, Illinois, which shall have exclusive
                      jurisdiction in such instance.
                    </div>
                  </div>
                </li>
                <br />
                <li>
                  <div className="flex flex-column">
                    <span>9.6</span>
                    <div className="ml-7">
                      <span className="underline">How to Contact Us</span>. You may contact us
                      regarding the Services or these Terms at: 222 West Merchandise Plaza #1212,
                      Chicago, IL 60654], or by e-mail at legal@gologicsolutions.com.
                    </div>
                  </div>
                </li>
                <br />
              </ol>
            </ol>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
