import { all, call, put, takeLatest } from 'redux-saga/effects'
import { GET_USER_FILES, UPLOAD_FILE } from 'store/types'
import CommonApi from 'api/common'
import { getUserFilesAction, uploadFileAction } from 'store/actions/common'
import { get } from 'lodash'

function* uploadFile(action) {
  try {
    // yield put(uploadFileAction.FULLFILLED({ uploadFileErrors: '', uploadedImage: '' }))
    const res = yield all(
      action.payload.files.map((file) => call(CommonApi.getSignedUrl, file.type, file.name))
    )
    const signedUrl = res.map((response) => JSON.parse(response.getSignedUrl.data))

    const data = yield all(
      action.payload.files.map((file, index) =>
        call(CommonApi.uploadFile, signedUrl[index].signedUrl, file, file.type)
      )
    )
    let imgUrl = data.map((dat) => dat.split('/uploads/'))
    imgUrl = imgUrl.map((url) =>
      !url[1] ? '' : 'https://' + process.env.REACT_APP_MEDIA_BUCKET + '/uploads/' + url[1]
    )

    const fileData = action.payload.files?.map((file, index) => ({
      name: file.name,
      userId: action.payload.userId,
      url: imgUrl[index],
      id: signedUrl[index].id,
      type: file.type,
      size: file.size.toString(),
      tenantId: action.payload.tenantId,
    }))
    yield call(CommonApi.saveUserFile, fileData)

    let singleFileData
    if (action?.payload?.getSingleFile) {
      const response = yield call(CommonApi.getFile, signedUrl[0].id)
      singleFileData = JSON.parse(get(response, 'getFile.data', ''))
    }

    yield put(
      uploadFileAction.FULLFILLED({
        uploadedImage: action.payload?.getSingleFile ? singleFileData : imgUrl,
      })
    )

    if (action.payload.getFile) {
      const response = yield call(
        CommonApi.getUserFiles,
        action.payload.userId,
        action.payload.tenantId
      )
      const updatedData = JSON.parse(get(response, 'getUserFiles.data', ''))

      yield put(getUserFilesAction.FULLFILLED(updatedData))
    }
  } catch (error: any) {
    console.log('errors : ', error.message)
    yield put(uploadFileAction.FULLFILLED({ uploadFileErrors: error.message }))
  }
}
function* getUserFiles(action) {
  try {
    const res = yield call(CommonApi.getUserFiles, action.payload.userId, action.payload.tenantId)
    const data = JSON.parse(get(res, 'getUserFiles.data', ''))

    yield put(getUserFilesAction.FULLFILLED(data))
  } catch (error) {
    console.log('get Goal Details error : ', error)
  }
}
/// /////////// Watchers ///////////////////////
export function* watcherCommon() {
  yield takeLatest(UPLOAD_FILE.STARTED, uploadFile)
  yield takeLatest(GET_USER_FILES.STARTED, getUserFiles)
}
