import styled from 'styled-components'
import tw from 'twin.macro'

export const AppHeaderStyle = styled.main.attrs({
  className: 'fixed bg-white w-full z-40',
})`
  & {
    .app-header-container {
      ${tw`2xl:container mx-auto xs:px-3 sm:px-6 lg:px-12`}
    }
  }
`

export const HeaderStyle = styled.main.attrs({
  className: 'grid grid-cols-12 text-xl text-zinc relative xs:py-3 items-center',
})`
  & {
    .header-logo {
      ${tw`col-span-8 flex`}
      div {
        ${tw`flex items-end`}
        img {
          ${tw`object-contain xs:h-7 sm:h-10`}
        }
      }
    }
    .header-navigation {
      ${tw`flex col-span-4 justify-end`}
    }
    .header-navigation > div {
      ${tw`inline-flex gap-6`}
    }
  }
`
