import { NAVIGATION } from 'config'
import React from 'react'
import { useParams } from 'react-router-dom'
import { getRoleFromPath, handleSelectedTab, copy } from 'utils/helper'
import history from 'utils/history'

export default function NavBar() {
  const { tenantId, isSelectedTab } = useParams()
  const path = history.location.pathname
  const userRole = getRoleFromPath()

  const HighLightTabs = ({ item }) => {
    const isSelected = handleSelectedTab(
      item.route || item.label.toLowerCase(),
      userRole,
      path,
      tenantId,
      isSelectedTab
    )
    return (
      <p
        className={`${
          isSelected ? 'text-grey border-b-[2px] border-primary pb-2' : 'pb-3'
        }  font-cabin flex flex-col items-center  relative w-auto font-semibold cursor-pointer`}
        onClick={() => item?.path && history.push(item.path)}
      >
        {item.label}
      </p>
    )
  }

  return (
    <div className="xs:hidden sm:hidden mdl:flex pt-[22px] border-primary-outline border-t-[1px]">
      <div className="inline-flex text-xl text-silver tracking-wider font-semibold">
        {NAVIGATION[userRole].map((it, index) => {
          const item = copy(it)
          if (item.path) item.path = item.path.replace(':tenantId', tenantId)
          return (
            item.isNav && (
              <div className={`font-cabin ${index > 0 ? `pl-6` : ''}`} key={index}>
                <HighLightTabs item={item} />
              </div>
            )
          )
        })}
      </div>
    </div>
  )
}
