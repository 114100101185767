import moment from 'moment'
import sortBy from 'lodash/sortBy'
import pick from 'lodash/pick'

import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import {
  GET_ADVISORS_DASHBOARD,
  GET_CLIENTS,
  GET_CLIENT_ACTIVITY_LOGS,
  GET_CLIENT_ACTIVITY_SCORE,
  GET_CLIENT_PROFILE,
  GET_CLIENT_PROFILE_DETAILS,
  GET_CLIENT_EDIT_PROFILE_DETAIL,
  GET_TOP_ACTIVE_CLIENTS,
  UPDATE_CLIENT_PROFILE_DETAIL,
  GET_CLIENT_ASSESSMENTS,
  GET_CLIENT_ASSESSMENT_LIST,
  GET_CLIENT_CONNECTED_TOOLS,
  GET_ALL_TOOLS,
  GET_TACKLE_MEETING_EVENTS,
  UPDATE_TACKLE_MEETING,
  DE_AUTHENTICATE_TOOLS,
  CANCEL_TACKLE_MEETING,
  UPDATE_MY_TOOLS,
  GET_MY_TOOLS,
  SET_AUTH_TOOLS_SUCCESS,
} from 'store/types'
import ClientsApi from 'api/clients'
import {
  getClientActivityLogsAction,
  getClientActivityScoreAction,
  getClientProfileAction,
  getClientProfileDetailsAction,
  getClientEditProfileDetailAction,
  getAdvisorDashboardAction,
  getTopActiveClientsAction,
  getClientsAction,
  updateClientProfileDetailAction,
  getAssessmentListAction,
  getAssessmentAction,
  getAllToolsAction,
  getTackleMeetingEventsAction,
  updateTackleMeetingAction,
  getMyToolsAction,
  toolOauthSuccessAction,
} from 'store/actions/clients'
import UserApi from 'api/user'
import AssessmentApi from 'api/assessment'
import { get, pickBy } from 'lodash'
import hookForms from 'utils/hookForms'
import { FORM, formKeys } from 'config'
import goalAPi from 'api/goal'
import _ from 'lodash'
import {
  getBusinessAndAssessmentResponseAction,
  getUserProfileAndAdvisorsAction,
} from 'store/actions/userManagement'
import { getUserGoalAction } from 'store/actions/owner/playbooks'
import { getAssessmentResponse } from 'utils/helper'
import { getGoalsSelector } from 'store/selectors/clients'
import { getConnectedToolsAction } from 'store/actions/clients'
import { getUserAssessments } from 'store/sagas/owner'
import { setFormData } from 'store/actions/form'
import { getUserConnectorsAction } from 'store/actions/calendar'
import { isSameObject } from 'utils/helper'
import { getUserProfileAction, updateMyToolsAction } from 'store/actions/user'

function* getAdvisorDashboard(action) {
  try {
    let res = yield call(ClientsApi.getAdvisorDashboard, action.payload)
    res = JSON.parse(res?.getAdvisorDashboard.data)
    yield put(getAdvisorDashboardAction.FULLFILLED(res))
  } catch (error) {
    yield put(getAdvisorDashboardAction.REJECTED(error))
    console.log(error)
  }
}

function* getClients(action) {
  try {
    yield put(getClientsAction.FULLFILLED([]))
    let res = yield call(ClientsApi.getClients, action.payload)
    res = get(res, 'getClients.data')
    yield put(getClientsAction.FULLFILLED(res))
  } catch (error) {
    yield put(getClientsAction.REJECTED(error))
    console.log(error)
  }
}
function* getTopActiveClients(action) {
  try {
    let res = yield call(ClientsApi.getTopActiveClients, action.payload)
    const data = get(res.getTopActiveClients, 'data')
    yield put(getTopActiveClientsAction.FULLFILLED(JSON.parse(data)))
  } catch (error) {
    yield put(getTopActiveClientsAction.REJECTED(error))
    console.log(error)
  }
}

function* getClientProfile(action) {
  try {
    if (!action?.payload) return
    const res = yield call(
      UserApi.getUserProfile,
      action?.payload?.clientId,
      action?.payload?.tenantId
    )
    const userProfile = get(res, 'getMyProfile', {})

    const currentDate = moment().startOf('day')
    const totalDays = userProfile?.firstSigninDate
      ? currentDate.diff(moment(userProfile?.firstSigninDate).startOf('day'), 'days')
      : 0

    const clientProfile = {
      ...userProfile,
      totalDays,
    }

    yield put(getClientProfileAction.FULLFILLED(clientProfile))
  } catch (error) {
    console.log(error)
  }
}

function* getClientActivityLogs(action) {
  try {
    const { clientId, tenantId } = action.payload
    let activityLogs = yield call(ClientsApi.getActivityLogs, clientId, tenantId)
    activityLogs = get(activityLogs, 'getActivityLogs.data', [])

    if (activityLogs) {
      activityLogs = JSON.parse(activityLogs)
      yield put(getClientActivityLogsAction.FULLFILLED(activityLogs))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getClientActivityScore(action) {
  try {
    const { clientId, tenantId } = action?.payload

    const res = yield call(ClientsApi.getOwnerActivityDetails, clientId, tenantId)
    let activityDetail = res?.getOwnerActivityDetails?.data
    if (activityDetail) {
      activityDetail = JSON.parse(activityDetail)
      yield put(getClientActivityScoreAction.FULLFILLED(activityDetail))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getClientProfileDetails(action) {
  try {
    const type: any = 'initial'
    const tenantId = yield select((state) => state.user.user?.tenantId[0])
    const clientId = action?.payload?.clientId
    const userGoals = yield call(goalAPi.getUserGoals)
    yield put(getUserGoalAction.FULLFILLED(userGoals?.getUserGoals))

    const [userProfile, businessProfile, assessmentResponse] = yield all([
      call(getClientProfile, { payload: { clientId, tenantId } }),
      call(UserApi.getBusinessProfile, clientId),
      call(AssessmentApi.getUserAssessmentResponse, type, clientId),
    ])

    let userAssessmentsResponse: any = get(
      assessmentResponse,
      'getUserAssessmentResponse.data.assessmentResponse',
      ''
    )
    userAssessmentsResponse = userAssessmentsResponse ? JSON.parse(userAssessmentsResponse) : []

    const payload = {
      userProfile,
      businessProfile: businessProfile?.getBusinessProfile
        ? businessProfile?.getBusinessProfile
        : {},
      assessmentResponse: userAssessmentsResponse,
    }
    yield put(getClientProfileDetailsAction.FULLFILLED(payload))
  } catch (error) {
    console.log(error)
  }
}

function* getClientProfileDetail(action) {
  const { userId, tenantId } = action.payload
  try {
    yield all([
      put(
        getUserProfileAndAdvisorsAction.STARTED({
          tenantId,
          userId,
          setForms: [FORM.USER_PROFILE_FORM],
        })
      ),
      put(
        getBusinessAndAssessmentResponseAction.STARTED({
          setForms: [FORM.USER_MANAGEMENT_BUSINESS_FORM],
          userId,
          tenantId,
          type: 'initial',
        })
      ),
    ])

    const res = yield call(
      UserApi.getUserProfile,
      (action?.payload?.tenantId, action?.payload?.userId)
    )

    let otherBusinessDetails = yield select((state) => state.user.businessProfile)

    otherBusinessDetails = {
      ...otherBusinessDetails,
      otherGoals: JSON.parse(otherBusinessDetails.otherGoals),
    }
    const otherBusinessDetail = { ...otherBusinessDetails, ...res.getMyProfile }
    if (action.payload?.setForms?.length > 0) {
      const { setForms } = action.payload
      // const form = hookForms.getForm(setForms[0])
      let businessOtherDetail = pickBy(otherBusinessDetail, (value, key) =>
        formKeys[setForms[0]].includes(key)
      )

      const goals = yield select(getGoalsSelector)

      goals.forEach((goal) => {
        if (goal.label === businessOtherDetail.topGoal) {
          businessOtherDetail.topGoal = goal.value
        }
        return businessOtherDetail
      })

      yield put(setFormData({ form: setForms[0], data: businessOtherDetail }))
      // form.reset({ ...businessOtherDetail })
    }
    yield put(getClientEditProfileDetailAction.FULLFILLED({ update: true }))
  } catch (error) {
    console.log(error)
  }
}

function* updateClientProfileDetail(action) {
  const { userId, tenantId } = action.payload
  const form = yield select((state) => state.form)
  try {
    const userProfileForm = hookForms.getForm(FORM.USER_PROFILE_FORM)
    const businessOtherDetail = hookForms.getForm(FORM.BUSINESS_OTHER_DETAILS)
    const userManagementBusinessForm = hookForms.getForm(FORM.USER_MANAGEMENT_BUSINESS_FORM)

    let userProfileFormValues = userProfileForm.getValues()
    const businessOtherDetailValues = businessOtherDetail.getValues()
    const businessFormValues = userManagementBusinessForm.getValues()

    const businessProfileFormValues = pickBy(businessFormValues, (value, key) =>
      formKeys[FORM.USER_MANAGEMENT_BUSINESS_FORM].includes(key)
    )

    userProfileFormValues = pickBy(userProfileFormValues, (value, key) =>
      formKeys[FORM.PROFILE_FORM].includes(key)
    )
    let topGoal = pickBy(businessOtherDetailValues, (value, key) =>
      formKeys[FORM.TOPGOAL].includes(key)
    )
    const businessOtherDetails = pickBy(businessOtherDetailValues, (value, key) =>
      formKeys[FORM.BUSINESS_DETAILS].includes(key)
    )
    const otherGoals = pickBy(businessOtherDetailValues, (value, key) =>
      formKeys[FORM.OTHER_GOALS].includes(key)
    )

    let initialAssessmentFormValues = _.omit(
      businessFormValues,
      Object.keys(businessProfileFormValues)
    )

    let getAssessments = yield call(AssessmentApi.getAssessments, 'initial')

    const assessmentList = getUserAssessments(getAssessments, false)
    const questionId = assessmentList?.find((res) => res?.meta?.isTopGoal)?.id
    const topGoalData = { [questionId]: topGoal?.topGoal }

    initialAssessmentFormValues = { ...initialAssessmentFormValues, ...topGoalData }
    const businessProfile = {
      ...businessProfileFormValues,
      ...businessOtherDetails,
      otherGoals: JSON.stringify(otherGoals.otherGoals),
    }

    let assessmentResponse: any = []

    assessmentResponse = getAssessmentResponse({
      keys: initialAssessmentFormValues,
      assessment_questions: assessmentList,
    })
    const apiCalls: any = []
    if (
      !isSameObject(
        userProfileFormValues,
        pick(form.USER_PROFILE_FORM, ['firstName', 'lastName', 'mobileContactNumber'])
      )
    )
      apiCalls.push(
        call(UserApi.updateUserProfile, {
          ...userProfileFormValues,
          userId,
          tenantId,
        })
      )

    const otherData = { ...businessOtherDetails, ...otherGoals }
    if (
      !isSameObject(businessFormValues, form.USER_MANAGEMENT_BUSINESS_FORM) ||
      !isSameObject(otherData, form.BUSINESS_OTHER_DETAILS)
    )
      apiCalls.push(
        call(UserApi.updateBusinessProfile, {
          ...otherData,
          ...businessProfile,
          id: userId,
          tenantId,
        })
      )
    if (apiCalls.length > 0) yield all(apiCalls)
    yield call(AssessmentApi.saveAssessmentResponse, {
      userId,
      type: 'initial',
      tenantId: tenantId,
      assessmentResponse: JSON.stringify(assessmentResponse),
    })

    const payload = { clientId: userId }
    yield call(getClientProfileDetails, { payload })
    yield put(updateClientProfileDetailAction.FULLFILLED())
  } catch (error) {
    yield put(updateClientProfileDetailAction.REJECTED())
    console.log(error)
  }
}

function* getAssessmentList() {
  try {
    const user = yield select((state) => ({
      userId: state.clients.client.profile.id,
      tenantId: state.clients.client.profile.tenantId[0],
    }))
    let getAssessments = yield call(AssessmentApi.getAssessmentList, user.userId, user.tenantId)
    yield put(getAssessmentListAction.FULLFILLED(getAssessments.listAssessments.data))
  } catch (error) {
    console.log('get assessment question error : ', error)
  }
}

function* getAssessment(action) {
  try {
    const { type, userId } = action.payload
    let getAssessments = yield call(AssessmentApi.getAssessments, type)
    getAssessments = getUserAssessments(getAssessments, false)
    getAssessments = sortBy(getAssessments, (q) => q.previous)
    let questions: any = { assessmentResponse: [] }
    const res = yield call(AssessmentApi.getUserAssessmentResponse, type, userId)
    questions = get(res, 'getUserAssessmentResponse.data', '')
    const assessmentResponse = {}
    questions.assessmentResponse = get(questions, 'assessmentResponse')
      ? JSON.parse(questions.assessmentResponse)
      : []
    questions.assessmentResponse.forEach((answer) => {
      assessmentResponse[answer.id] = answer.value
    })

    yield put(
      getAssessmentAction.FULLFILLED({
        [type]: {
          questions: getAssessments,
          assessmentResponse: questions,
        },
      })
    )
  } catch (error) {
    console.log('get assessment question error : ', error)
  }
}

function* getConnectedTools() {
  try {
    const user = yield select((state) => ({
      userId: get(state?.clients, 'client.profile.id', '') || get(state.user, 'user.id', ''),
      tenantId:
        get(state?.clients, 'client.profile.tenantId[0]', '') ||
        get(state.user, 'user.tenantId[0', ''),
    }))
    const tenantId = user.tenantId
    let getConnectedTools = yield call(ClientsApi.getClientConnectedTools, user.userId, tenantId)
    let data = get(getConnectedTools, 'getUserIntegrations.data')
    if (data) data = JSON.parse(data)
    yield put(getConnectedToolsAction.FULLFILLED(data))
  } catch (error) {
    console.log('get connected tools error : ', error)
  }
}

function* getAllTools(action) {
  try {
    const res = yield call(ClientsApi.getAllTools, action.payload)
    let data = get(res, 'getAllTools.data')
    if (data) data = JSON.parse(data)
    yield put(getAllToolsAction.FULLFILLED(data))
  } catch (error) {
    console.log('get connected tools error : ', error)
  }
}

function* getTackleMeeting(payloadData?: any) {
  try {
    const tenantId = yield select((state) => state.user.user?.tenantId[0])

    const payload = {
      tenantId: tenantId,
      filter: payloadData?.payload?.filter.trim(),
      isPaginated: false,
    }

    let data = yield call(ClientsApi.getTackleMeetings, payload)

    yield put(
      getTackleMeetingEventsAction.FULLFILLED({
        data: get(data, 'getTackleMeetings.data', []),
        filter: payloadData?.payload?.filter.trim(),
      })
    )
  } catch (error) {
    console.log('get Tackle meetings error : ', error)
  }
}

function* updateTackleMeeting(action) {
  try {
    let payload
    if (action.payload.description) {
      payload = {
        description: action.payload.description,
        id: action.payload.id,
        tenantId: action.payload.tenantId,
        availability: action.payload.availability,
        attendees: action.payload.attendees,
      }
    }

    yield call(ClientsApi.updateTackleMeeting, payload ? payload : action.payload)
    if (action.payload.comment) {
      yield put(updateTackleMeetingAction.FULLFILLED(action.payload))
    }
    if (action.payload.description) {
      const payload = {
        isPaginated: false,
        filter: 'upcoming',
      }
      yield call(getTackleMeeting, { payload })
    }
  } catch (error) {
    console.log('comment updating error : ', error)
  }
}

function* deauthenticateConnector(payload) {
  const user = yield select((state) => ({
    tenantId: get(state.user, 'user.tenantId[0]', ''),
  }))
  yield call(ClientsApi.deauthenticateConnector, payload.payload, user.tenantId)
  yield call(getMyTools)
  yield put(getUserConnectorsAction.STARTED(undefined))
}

function* cancelTackleMeeting(action) {
  try {
    yield call(ClientsApi.cancelTackleMeeting, action.payload)
    const payload = {
      filter: 'upcoming',
    }
    yield call(getTackleMeeting, { payload })
  } catch (error) {
    console.log('cannot cancel meeting error : ', error)
  }
}
function* updateMyTools(action) {
  try {
    const res = yield call(ClientsApi.updateMyTools, action.payload)
    yield put(updateMyToolsAction.FULLFILLED(res))
    if (action.payload.ownerExperience) {
      const response = yield call(UserApi.getUserProfile)

      const user = response?.getMyProfile
      yield put(getUserProfileAction.FULLFILLED({ user }))
    } else {
      yield call(getMyTools)
    }
  } catch (error) {
    console.log('error while updating: ', error)
  }
}
function* getMyTools() {
  try {
    const payload = yield select((state) => ({
      tenantId: state.user.user.tenantId[0],
      userId: state.user.user.id,
    }))
    const res = yield call(ClientsApi.getMyTools, payload.userId, payload.tenantId)
    let data = get(res, 'getUserTools.data')
    if (data) data = JSON.parse(data)
    yield put(getMyToolsAction.FULLFILLED(data))
  } catch (error) {
    console.log('error while updating: ', error)
  }
}

function* toolOauthSuccess(action) {
  try {
    const payload = yield select((state) => ({
      tenantId: state.user.user.tenantId[0],
    }))
    yield call(
      ClientsApi.toolOauthSuccess,
      action.payload.connectorName,
      payload.tenantId,
      action.payload.playId
    )
    yield put(toolOauthSuccessAction.FULLFILLED())
  } catch (error) {
    console.log('error while updating: ', error)
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherClients() {
  yield takeLatest(GET_ADVISORS_DASHBOARD.STARTED, getAdvisorDashboard)
  yield takeLatest(GET_TOP_ACTIVE_CLIENTS.STARTED, getTopActiveClients)
  yield takeLatest(GET_CLIENT_PROFILE.STARTED, getClientProfile)
  yield takeLatest(GET_CLIENT_ACTIVITY_LOGS.STARTED, getClientActivityLogs)
  yield takeLatest(GET_CLIENT_ACTIVITY_SCORE.STARTED, getClientActivityScore)
  yield takeLatest(GET_CLIENT_PROFILE_DETAILS.STARTED, getClientProfileDetails)
  yield takeLatest(GET_CLIENTS.STARTED, getClients)
  yield takeLatest(GET_CLIENT_EDIT_PROFILE_DETAIL.STARTED, getClientProfileDetail)
  yield takeLatest(UPDATE_CLIENT_PROFILE_DETAIL.STARTED, updateClientProfileDetail)
  yield takeLatest(GET_CLIENT_ASSESSMENTS.STARTED, getAssessment)
  yield takeLatest(GET_CLIENT_ASSESSMENT_LIST.STARTED, getAssessmentList)
  yield takeLatest(GET_CLIENT_CONNECTED_TOOLS.STARTED, getConnectedTools)
  yield takeLatest(GET_ALL_TOOLS.STARTED, getAllTools)
  yield takeLatest(GET_TACKLE_MEETING_EVENTS.STARTED, getTackleMeeting)
  yield takeLatest(UPDATE_TACKLE_MEETING.STARTED, updateTackleMeeting)
  yield takeLatest(DE_AUTHENTICATE_TOOLS.STARTED, deauthenticateConnector)
  yield takeLatest(CANCEL_TACKLE_MEETING.STARTED, cancelTackleMeeting)
  yield takeLatest(UPDATE_MY_TOOLS.STARTED, updateMyTools)
  yield takeLatest(GET_MY_TOOLS.STARTED, getMyTools)
  yield takeLatest(SET_AUTH_TOOLS_SUCCESS.STARTED, toolOauthSuccess)
}
