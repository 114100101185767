import * as yup from 'yup'
import { UPPER_CASE_REGEX, NUMBER_REGEX, SPECIAL_CHARACTER_REGEX } from 'config'

export const schema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
})

export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
})

export const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .required('Password not complete')
    .matches(RegExp(UPPER_CASE_REGEX), 'Password rules not met')
    .matches(RegExp(NUMBER_REGEX), 'Password rules not met')
    .matches(RegExp(SPECIAL_CHARACTER_REGEX), 'Password rules not met'),
  confirmpassword: yup
    .string()
    .required('Password not complete')
    .oneOf([yup.ref('password'), null], 'Passwords do not match'),
})
