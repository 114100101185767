import React, { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import { isMobileOnly, isTablet } from 'mobile-device-detect'
import SearchIcon from 'components/Common/SvgIcons/SearchIcon'
import SelectField from 'components/Common/FormFields/SelectField'
import { sortOptions, statusOptions } from 'config/data'
import InputField from 'components/Common/FormFields/InputField'
import { CrossIcon } from 'components/Common/SvgIcons'
import FilterIcon from 'components/Common/SvgIcons/FilterIcon'
import { USER_MANAGEMENT_FILTER } from 'config'
import ButtonField from 'components/Common/FormFields/ButtonField'
import history from 'utils/history'
import { useParams } from 'react-router-dom'
import { getRoleFromPath } from 'utils/helper'

let emptyFunction = (obj) => {}
const Filters = ({
  isBusinessTab = false,
  setShowFilters,
  filterAction = emptyFunction,
  showFilters,
  sortHandler = ({ sortOrder, fieldType, sortField }) => {},
  advisors = [],
  filter = { status: '', advisor: '' },
}) => {
  const [searchValue, setSearchValue] = useState<any>()
  const [sort, setSort] = useState<any>()
  const { tenantId } = useParams()
  const userRole = getRoleFromPath()

  const searchData = (data: string) => {
    filterAction({
      type: USER_MANAGEMENT_FILTER.SEARCH_QUERY,
      value: data,
    })
  }
  const debounceLoadData = debounce(searchData, 1500)

  const handleSearch = (e) => {
    setSearchValue(e.target.value)
    debounceLoadData(e.target.value)
    if (e.target.value === '') {
      filterAction({
        type: USER_MANAGEMENT_FILTER.SEARCH_QUERY,
        value: '',
      })
    }
  }

  useEffect(() => {
    return () => {
      filterAction({
        type: USER_MANAGEMENT_FILTER.ADVISOR,
        value: 'all',
      })
      filterAction({
        type: USER_MANAGEMENT_FILTER.STATUS,
        value: 'all',
      })
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      {showFilters && (
        <div className="h-[50px] absolute w-full bg-white top-0 border-primary-outline border-b-[1px]">
          <div className="flex flex-row justify-between items-center px-5 h-[51px]">
            <div className="text-primary-text text-xl font-primary">Filter, Sort, Search</div>
            <span onClick={() => setShowFilters(false)}>
              <CrossIcon className={'fill-silver  h-[18px] w-[18px] cursor-pointer'} />
            </span>
          </div>
        </div>
      )}

      {(isTablet || showFilters) && (
        <div className={`${isMobileOnly ? ' h-screen' : ''} px-5 pt-5 pb-5 mdl:hidden`}>
          <div className="grid grid-cols-12 gap-4">
            <div className={`${isMobileOnly ? 'col-span-12' : 'col-span-5'}`}>
              {isMobileOnly && (
                <>
                  {/* Search */}
                  <div className="flex justify-between items-center">
                    <InputField
                      label="SEARCH"
                      type={'search'}
                      placeholder={isBusinessTab ? 'Search Name or Business Name' : 'Search Name'}
                      onChange={handleSearch}
                      className="w-full"
                      name={'search'}
                    />
                    <span
                      onClick={() => {
                        filterAction({
                          type: USER_MANAGEMENT_FILTER.SEARCH_QUERY,
                          value: searchValue,
                        })
                      }}
                    >
                      <SearchIcon
                        className={`${
                          isMobileOnly ? 'ml-3 mt-7' : 'ml-7'
                        } fill-primary cursor-pointer`}
                      />
                    </span>
                  </div>
                </>
              )}

              {/* filterByStatus */}
              <div className={`${isMobileOnly ? 'col-span-12' : 'col-span-5'} pt-2`}>
                <SelectField
                  label="FILTER BY STATUS"
                  name={'status'}
                  placeholder="Select"
                  options={statusOptions}
                  onChange={(value) => {
                    filterAction({
                      type: USER_MANAGEMENT_FILTER.STATUS,
                      value,
                    })
                  }}
                  value={filter?.status === '' ? 'all' : filter?.status}
                />
              </div>
            </div>

            {/* filterByAdvisor */}
            {isBusinessTab && (
              <div className={`${isMobileOnly ? 'col-span-12' : 'col-span-7'} `}>
                <div className="pt-2">
                  <SelectField
                    name={'filter by advisor'}
                    label="FILTER BY ADVISOR"
                    placeholder="Select"
                    options={advisors}
                    onChange={(value) => {
                      filterAction({
                        type: USER_MANAGEMENT_FILTER.ADVISOR,
                        value,
                      })
                    }}
                    value={filter?.advisor === '' ? 'all' : filter?.advisor}
                  />
                </div>
              </div>
            )}

            {isBusinessTab && (
              <div className={`${isMobileOnly ? 'col-span-12' : 'col-span-5'} `}>
                <div className="pt-2">
                  <SelectField
                    placeholder="Select"
                    label="SORT BY"
                    name={'sort'}
                    options={sortOptions}
                    onChange={(value) => {
                      setSort(value)
                      sortHandler({ sortOrder: 'ASC', fieldType: value, sortField: value })
                    }}
                    value={sort}
                  />
                </div>
              </div>
            )}

            {isTablet && (
              <div className="col-span-7">
                <label className="font-semibold	text-primary-text font-inter text-sm invisible">
                  hide
                </label>
                <div className="flex justify-between items-center">
                  <InputField
                    type={'search'}
                    placeholder={isBusinessTab ? 'Search Name or Business Name' : 'Search Name'}
                    onChange={handleSearch}
                    className="w-full"
                    name={'search'}
                  />
                  <span
                    onClick={() => {
                      filterAction({
                        type: USER_MANAGEMENT_FILTER.SEARCH_QUERY,
                        value: searchValue,
                      })
                    }}
                  >
                    <SearchIcon
                      className={`${
                        isMobileOnly ? 'ml-3 mt-7' : 'ml-5 mt-2'
                      } fill-primary cursor-pointer`}
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {isMobileOnly && (
        <div className="flex justify-end pr-5">
          <div className="flex my-5">
            <label className="font-primary font-bold text-sm text-primary inline">
              Filter, Sort, Search
            </label>
            <span onClick={() => setShowFilters(true)}>
              <FilterIcon className={''} />
            </span>
          </div>
        </div>
      )}
      <div className="hidden mdl:block">
        <div className="flex justify-between w-full min-w-[800px] self-end">
          <div className="flex gap-3">
            {/* filter by status */}
            <div className="min-w-[210px]">
              <SelectField
                label={'FILTER BY STATUS'}
                name={'status'}
                placeholder="Select"
                options={statusOptions}
                onChange={(value) => {
                  filterAction({
                    type: USER_MANAGEMENT_FILTER.STATUS,
                    value,
                  })
                }}
                value={filter?.status === '' ? 'all' : filter?.status}
              />
            </div>

            {/* filter by adviser */}
            {isBusinessTab && (
              <div className="min-w-[290px]">
                <SelectField
                  label={'FILTER BY ADVISOR'}
                  name={'status'}
                  placeholder="Select"
                  options={advisors}
                  onChange={(value) => {
                    filterAction({
                      type: USER_MANAGEMENT_FILTER.ADVISOR,
                      value,
                    })
                  }}
                  value={filter?.advisor === '' ? 'all' : filter?.advisor}
                />
              </div>
            )}

            {/* search by name & business name */}
            <div className="w-[400px] flex gap-2">
              <div className="self-end w-[350px]">
                <InputField
                  type="text"
                  placeholder={isBusinessTab ? 'Search Name or Business Name' : 'Search Name'}
                  defaultValue=""
                  name=""
                  value={searchValue}
                  onChange={handleSearch}
                  label=""
                />
              </div>
              <div
                className="self-end"
                onClick={() => {
                  filterAction({
                    type: USER_MANAGEMENT_FILTER.SEARCH_QUERY,
                    value: searchValue,
                  })
                }}
              >
                <SearchIcon className={'fill-primary mb-3 cursor-pointer'} />
              </div>
            </div>
          </div>

          {/* business account button */}
          {!isTablet && (
            <div className="self-end w-[240px]">
              <ButtonField
                type="button"
                name={`+ New ${isBusinessTab ? 'Business' : 'Advisor'} Account`}
                onClick={() =>
                  history.push(
                    `${
                      isBusinessTab
                        ? `/${userRole}/${tenantId}/create-business-owner`
                        : `/${userRole}/${tenantId}/create-advisor`
                    }`
                  )
                }
                variant="secondary"
                className="w-[240px]"
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Filters
