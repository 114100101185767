import React from 'react'
import ContentLoader from 'react-content-loader'

import { colorsPallete } from 'config'

const UserProfileLoader = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 350 260"
      backgroundColor={`${colorsPallete.cream.dark}`}
      foregroundColor={`${colorsPallete.grey.lighter}`}
    >
      <circle cx="173" cy="80" r="52" />
      <rect x="60" y="150" rx="8" ry="8" width="221" height="15" />
    </ContentLoader>
  )
}

export default UserProfileLoader
