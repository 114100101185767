import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import { isMobileOnly } from 'mobile-device-detect'

import history from 'utils/history'

import { tabsOptions } from 'config/data'
import ButtonTabs from 'components/Common/ButtonTabs'

import {
  AdvisorsAccountsContainer,
  BusinessAccountsContainer,
} from 'pages/Admin/container/UserManagement'
import { Container } from 'components/Common/StyledComponents'

const UserManagement = () => {
  let { isSelectedTab, tenantId } = useParams()

  const [selectedTab, setSelectedTab] = useState(tabsOptions[0])

  useEffect(() => {
    if (isSelectedTab === tabsOptions[0].link) {
      setSelectedTab(tabsOptions[0])
    } else {
      setSelectedTab(tabsOptions[1])
    }
  }, [isSelectedTab])

  const onHandleTabChange = (value) => {
    setSelectedTab(value)

    if (value.label === tabsOptions[0].label) {
      history.push(`/admin/${tenantId}/user-management/${tabsOptions[0].link}`)
    } else if (value.label === tabsOptions[1].label) {
      history.push(`/admin/${tenantId}/user-management/${tabsOptions[1].link}`)
    }
  }
  return (
    <Container>
      <div className={`${isMobileOnly ? 'p-[8px]' : ''} grid-cols-12 w-full h-full`}>
        <div className="col-span-12 items-center justify-between">
          <ButtonTabs
            options={tabsOptions}
            setSelectedTab={onHandleTabChange}
            selectedTab={selectedTab}
            size="lg"
            fontSize="sm:!text-2xl"
          />
        </div>
        <div className="col-span-12 relative z-[5] xs:-mt-2 md:-mt-3 lg:-mt-5">
          <div className="xs:bock bg-white grid-cols-12  pt-6 pb-2 lg:p-12  rounded-2xl mb-8 gap-12 shadow-lg  mdl:pb-6 lg:pb-3 xl:pb-10">
            {isSelectedTab === 'businesses' && <BusinessAccountsContainer />}
            {isSelectedTab === 'advisors' && <AdvisorsAccountsContainer />}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default UserManagement
