/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 26
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/gologicsolutions/Tackle/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser'

export type Environment = 'production' | 'development'

export const ApiKey: Record<Environment, string> = {
  production: 'd64d818c98d978ed1fad719844da9578',
  development: 'c7b4d85989f2b493ca80101ed066948f',
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '26',
    branch: 'main',
    source: 'web',
    versionId: '5e470daf-eafe-433b-afe3-324c111ecebb',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
}

export interface LoadOptionsBase {
  disabled?: boolean
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment
  client?: { configuration?: BrowserOptions }
}
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions }
}
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient }
}

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance

export interface IdentifyProperties {
  email?: string
  firstName?: string
  lastName?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  roles?: string[]
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  supportOrgIds?: string[]
}

export interface AccountCreatedProperties {
  email: string
  firstName: string
  id: string
  lastName: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  roles: string[]
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  supportOrgIds: string[]
}

export interface AssessmentFinishedProperties {
  id: string
  name: string
}

export interface AssessmentQuestionAnsweredProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  answerIds: string[]
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  answers: string[]
  id: string
  name: string
  question: string
}

export interface AssessmentStartedProperties {
  id: string
  name: string
}

export interface LoggedInProperties {
  email: string
  firstName: string
  id: string
  lastName: string
}

export interface LoginFailedProperties {
  email: string
}

export interface PasswordResetCompletedProperties {
  email: string
}

export interface PasswordResetInitiatedProperties {
  email: string
}

export class Identify implements BaseEvent {
  event_type = 'Identify'

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties
  }
}

export class AccountCreated implements BaseEvent {
  event_type = 'Account Created'

  constructor(public event_properties: AccountCreatedProperties) {
    this.event_properties = event_properties
  }
}

export class AssessmentFinished implements BaseEvent {
  event_type = 'Assessment Finished'

  constructor(public event_properties: AssessmentFinishedProperties) {
    this.event_properties = event_properties
  }
}

export class AssessmentOpened implements BaseEvent {
  event_type = 'Assessment Opened'
}

export class AssessmentQuestionAnswered implements BaseEvent {
  event_type = 'Assessment Question Answered'

  constructor(public event_properties: AssessmentQuestionAnsweredProperties) {
    this.event_properties = event_properties
  }
}

export class AssessmentStarted implements BaseEvent {
  event_type = 'Assessment Started'

  constructor(public event_properties: AssessmentStartedProperties) {
    this.event_properties = event_properties
  }
}

export class LoggedIn implements BaseEvent {
  event_type = 'Logged In'

  constructor(public event_properties: LoggedInProperties) {
    this.event_properties = event_properties
  }
}

export class LoggedOut implements BaseEvent {
  event_type = 'Logged Out'
}

export class LoginFailed implements BaseEvent {
  event_type = 'Login Failed'

  constructor(public event_properties: LoginFailedProperties) {
    this.event_properties = event_properties
  }
}

export class LoginPrompted implements BaseEvent {
  event_type = 'Login Prompted'
}

export class PasswordResetCompleted implements BaseEvent {
  event_type = 'Password Reset Completed'

  constructor(public event_properties: PasswordResetCompletedProperties) {
    this.event_properties = event_properties
  }
}

export class PasswordResetInitiated implements BaseEvent {
  event_type = 'Password Reset Initiated'

  constructor(public event_properties: PasswordResetInitiatedProperties) {
    this.event_properties = event_properties
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> }

const getVoidPromiseResult = () => ({ promise: Promise.resolve() })

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Account Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest/Account%20Created)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  accountCreated(
    properties: AccountCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreated(properties), options);
  }

  /**
   * Assessment Finished
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest/Assessment%20Finished)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  assessmentFinished(
    properties: AssessmentFinishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssessmentFinished(properties), options);
  }

  /**
   * Assessment Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest/Assessment%20Opened)
   *
   * Owner: Ben Leung
   *
   * @param options Amplitude event options.
   */
  assessmentOpened(
    options?: EventOptions,
  ) {
    return this.track(new AssessmentOpened(), options);
  }

  /**
   * Assessment Question Answered
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest/Assessment%20Question%20Answered)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. answerIds)
   * @param options Amplitude event options.
   */
  assessmentQuestionAnswered(
    properties: AssessmentQuestionAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssessmentQuestionAnswered(properties), options);
  }

  /**
   * Assessment Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest/Assessment%20Started)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  assessmentStarted(
    properties: AssessmentStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssessmentStarted(properties), options);
  }

  /**
   * Logged In
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest/Logged%20In)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  loggedIn(
    properties: LoggedInProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoggedIn(properties), options);
  }

  /**
   * Logged Out
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest/Logged%20Out)
   *
   * Owner: Ben Leung
   *
   * @param options Amplitude event options.
   */
  loggedOut(
    options?: EventOptions,
  ) {
    return this.track(new LoggedOut(), options);
  }

  /**
   * Login Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest/Login%20Failed)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  loginFailed(
    properties: LoginFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginFailed(properties), options);
  }

  /**
   * Login Prompted
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest/Login%20Prompted)
   *
   * Owner: Ben Leung
   *
   * @param options Amplitude event options.
   */
  loginPrompted(
    options?: EventOptions,
  ) {
    return this.track(new LoginPrompted(), options);
  }

  /**
   * Password Reset Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest/Password%20Reset%20Completed)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  passwordResetCompleted(
    properties: PasswordResetCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PasswordResetCompleted(properties), options);
  }

  /**
   * Password Reset Initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/gologicsolutions/Tackle/events/main/latest/Password%20Reset%20Initiated)
   *
   * Owner: Ben Leung
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  passwordResetInitiated(
    properties: PasswordResetInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PasswordResetInitiated(properties), options);
  }
}

export const ampli = new Ampli()

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions

export type BrowserClient = amplitude.Types.BrowserClient
export type BaseEvent = amplitude.Types.BaseEvent
export type IdentifyEvent = amplitude.Types.IdentifyEvent
export type GroupEvent = amplitude.Types.GroupIdentifyEvent
export type Event = amplitude.Types.Event
export type EventOptions = amplitude.Types.EventOptions
export type Result = amplitude.Types.Result
