import { createSelector } from 'reselect'
import { get, size } from 'lodash'
import moment from 'moment'

import { copy, dateFormatterHelper, sortHandlerHelper } from 'utils/helper'

export const getClientsData = (state) => state.clients?.clientsData
export const getScoreCards = (state) => state.clients?.client?.activity?.scoreCards
export const getActivityLogs = (state) => state.clients?.client?.activity?.activityLogs
export const getBusinessProfile = (state) => state.clients?.client?.businessProfile
export const getAssessmentResponse = (state) => state.clients?.client?.assessmentResponse
const getGoalSelector = (state) => state.owner.goals
export const getAssessmentQuestion = (state) => state.owner.questions
export const getToolsData = (state) => state.clients?.myTools
const getTackleMeetingEvents = (state) => state.clients
const getCurrentUser = (state) => state.user.user
const getClientsComment = (state) => state.clients

export const getClientsActivity = createSelector([getClientsData], (clientsData) => {
  let clientData: any = []
  clientData.push({
    title: '# ACCOUNTS CREATED',
    count: clientsData?.accounts?.length || 0,
    icon: 'Accounts',
  })
  clientData.push({ title: '# SIGN-INS', count: clientsData?.signIns || 0, icon: 'Signin' })
  clientData.push({
    title: '# MEETINGS SCHEDULED',
    count: clientsData?.meetingScheduled || 0,
    icon: 'MeetingSchedule',
  })
  clientData.push({
    title: '# MEETINGS RE-SCHEDULED',
    count: clientsData?.meetingReScheduled || 0,
    icon: 'MeetingReschedule',
  })
  return clientData
})

export const getClientActivityScoreCards = createSelector([getScoreCards], (scoreCard) => {
  try {
    let scoreCardsArr: any = []

    scoreCardsArr.push({
      header: '# times signed in',
      value: scoreCard?.timesSignedIn ? scoreCard.timesSignedIn : 0,
      avg: scoreCard?.signInPerWeek ? `${parseInt(scoreCard?.signInPerWeek)} times per week` : '',
      textSize: 'text-6xl',
    })

    scoreCardsArr.push({
      header: '# chats',
      value: scoreCard.chats ? scoreCard.chats : 0,
      avg: '',
      textSize: 'text-6xl',
    })

    scoreCardsArr.push({
      header: 'time spent on platform',
      value: scoreCard.timeOnPlatform ? scoreCard.timeOnPlatform : 0,
      avg: scoreCard?.avgMinsPerSession
        ? `${parseInt(scoreCard?.avgMinsPerSession)} minutes per session`
        : '',
      textSize: 'text-5xl',
    })

    scoreCardsArr.push({
      header: '# meetings scheduled',
      value: scoreCard.meetingsScheduled ? scoreCard.meetingsScheduled : 0,
      avg: '',
      textSize: 'text-6xl',
    })

    scoreCardsArr.push({
      header: 'tech tools integrated',
      value: scoreCard.toolsIntegrated ? scoreCard.toolsIntegrated : 0,
      avg: '',
      textSize: 'text-6xl',
    })

    scoreCardsArr.push({
      header: '# meetings re-scheduled',
      value: scoreCard.meetingsReScheduled ? scoreCard.meetingsReScheduled : 0,
      avg: '',
      textSize: 'text-6xl',
    })

    return scoreCardsArr
  } catch (err) {
    console.log('error in selector (getClientActivityScoreCards)')
    return []
  }
})

export const getClientActivityLogs = createSelector([getActivityLogs], (activityLogs) => {
  try {
    const activityLogsData = {}

    activityLogs = sortHandlerHelper({
      sortField: 'createdAt',
      sortOrder: 'DSC',
      fieldType: 'date',
      data: activityLogs,
    })

    activityLogs?.forEach((activity) => {
      if (activity?.showClientActivity) {
        const month = activity?.createdAt
          ? moment(activity?.createdAt).format('MMMM').toUpperCase()
          : null
        if (month) {
          if (moment().format()) if (!activityLogsData[month]) activityLogsData[month] = []
          activity.createdAt = dateFormatterHelper(activity.createdAt)
          activityLogsData[month].push(activity)
        }
      }
    })

    for (let key in activityLogsData) {
      let sortedArr = sortHandlerHelper({
        sortField: 'updatedAt',
        sortOrder: 'DSC',
        fieldType: 'date',
        data: activityLogsData[key],
      })

      activityLogsData[key] = sortedArr
    }

    return activityLogsData
  } catch (err) {
    console.log(err)
    return {}
  }
})

export const getClientBusinessDetails = createSelector(
  [getBusinessProfile, getGoalSelector, getAssessmentResponse],
  (businessProfile, goals) => {
    if ((businessProfile.length && goals.length) === 0) return

    let otherGoals = []
    try {
      if (businessProfile.otherGoals) {
        const parseGoal = JSON.parse(get(businessProfile, 'otherGoals', '{}'))
        if (parseGoal?.length) {
          otherGoals = goals?.filter((goal) => {
            return parseGoal.find((element) => {
              return element === goal.id
            })
          })
        }
      }
      let businessProfileData = {
        businessName: businessProfile?.businessName,
        mobileContactNumber: businessProfile?.mobileContactNumber,
        yearsInBusiness: businessProfile?.yearsInBusiness,
        challenges: businessProfile?.challenges,
        businessVision: businessProfile?.businessVision,
        otherGoals: otherGoals,
      }
      return businessProfileData
    } catch (err) {
      console.log('err: ', err)
      return {}
    }
  }
)

export const getGoalsSelector = createSelector([getGoalSelector], (goals) => {
  try {
    if (goals && goals.length) {
      const userGoals = goals.map((goal) => ({
        label: goal.name,
        value: goal.id || '',
      }))
      return userGoals
    }
    return
  } catch (err: any) {
    console.log(err.message)
    return []
  }
})

export const getOrderedAssessmentsSelector = createSelector(
  [getAssessmentQuestion],
  (assessment) => {
    const lastItem = assessment.pop()
    assessment.unshift(lastItem)
    let assessmentData = assessment.filter((question) => question?.meta?.showToBusinessProfile)
    assessmentData = assessmentData.sort(function (a, b) {
      return a?.meta?.advisorEditOwnerSequence - b?.meta?.advisorEditOwnerSequence
    })
    return assessmentData
  }
)
export const getCompletedToolsCountSelector = createSelector([getToolsData], (myTools) => {
  let count = myTools?.filter((tool) => tool.isConnected)
  return size(count)
})
export const getTackleMeetingSelector = createSelector(
  [getTackleMeetingEvents, getCurrentUser],
  (clients, user) => {
    const tackleEvents: any = []
    let date = new Date()
    let todayDate = date.getDate()
    let isAttendee
    let event = copy(clients?.tackleMeeting?.data)
    try {
      for (let i = 0; i < event?.length; i++) {
        let time
        let eventDate

        time = `${moment(event[i]?.startTime).format('hh:mm A')} - ${moment(
          event[i]?.endTime
        ).format('hh:mm A')}`

        if (event[i]?.startTime && clients?.tackleMeeting?.filter.includes('upcoming')) {
          let dateObj = new Date(event[i]?.startTime)
          if (dateObj.getDate() === todayDate) {
            eventDate = 'Today'
          } else if (dateObj.getDate() === todayDate + 1) {
            eventDate = 'Tomorrow'
          } else {
            eventDate = moment(event[i]?.startTime).format('MMM D, y')
          }
        } else {
          eventDate = moment(event[i]?.startTime).format('MMM D, y')
        }

        if (event[i]?.attendees) {
          event[i].attendees = JSON.parse(event[i]?.attendees)
          isAttendee = event[i]?.attendees?.some((attendee) => attendee.email === user.email)
        }
        if (event[i].description) {
          var html = event[i].description
          var div = document.createElement('div')
          div.innerHTML = html
          event[i].text = div.innerText
          event[i].text = event[i].text.replace(/\n/g, ' ')
        }

        tackleEvents.push({
          id: event[i].id,
          title: event[i]?.title ? JSON.parse(event[i]?.title) : '',
          comment: event[i]?.comment || '',
          description: event[i]?.description || '',
          text: event[i].text || '',
          timezone: event[i]?.timezone,
          eventDate,
          start: event[i]?.startTime,
          end: event[i]?.endTime,
          time: time,
          isAttendee,
          attendees: event[i]?.attendees,
          eventId: event[i]?.eventId,
          inviteeId: event[i]?.inviteeId,
          advisorId: user?.id,
          availability: event[i]?.availability,
        })
      }

      return tackleEvents
    } catch (err) {
      console.log('error: ', err)
    }
  }
)

export const getCommentsValueSelector = createSelector([getClientsComment], (clients) => {
  let clientComments: any = []
  if (clients?.updatedComments) {
    for (let i = 0; i < clients?.updatedComments.length; i++) {
      clientComments.push({
        id: clients?.updatedComments[i].id || '',
        tenantId: clients?.updatedComments[i].tenantId || '',
        comment: clients?.updatedComments[i].comment || '',
      })
    }
  }
  if (clients?.tackleMeeting?.data) {
    for (let i = 0; i < clients?.tackleMeeting?.data.length; i++) {
      clientComments.push({
        id: clients?.tackleMeeting?.data[i].id || '',
        tenantId: clients?.tackleMeeting?.data[i].tenantId || '',
        comment: clients?.tackleMeeting?.data[i].comment || '',
      })
    }
  }
  return clientComments
})
