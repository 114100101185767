import React, { useEffect } from 'react'
import get from 'lodash/get'
import { logChatPromiseExecution } from 'stream-chat'
import {
  MessageToSend,
  MessageInput,
  Window,
  Thread,
  useChatContext,
  useChannelActionContext,
} from 'stream-chat-react'
import { MessageList } from 'components/Chat/components/MessageList'
import { MessagingChannelHeader, MessagingInput } from 'components/Chat/components'
import { useGiphyContext } from 'components/Chat/Giphy'
import type { StreamChatGenerics } from 'components/Chat/types'
import { useUnreadContext } from 'components/Chat/contexts/UnreadContext'

export type ChannelInnerProps = {
  usersAssessmentResponseByQuestion: any
  chatUnreadMessageCountAction: any
  setShowChannelPreview: (showChannelPreview) => void
  theme: string
  showChannelPreview: boolean
  setShowDesktopUserProfile: (showDesktopUserProfile) => void
  showDesktopUserProfile
  uploadFileAction: (streamMsgId: string) => void
}

export const ChannelInner = (props: ChannelInnerProps) => {
  const {
    theme,
    setShowChannelPreview,
    showChannelPreview,
    setShowDesktopUserProfile,
    showDesktopUserProfile,
    chatUnreadMessageCountAction,
    uploadFileAction,
  } = props
  const { giphyState, setGiphyState } = useGiphyContext()
  const { sendMessage } = useChannelActionContext<StreamChatGenerics>()
  const { channel, client } = useChatContext<StreamChatGenerics>()
  const { chatsUnreadCount, setChatsUnreadCount, setChannelMessageCount } = useUnreadContext()

  const overrideSubmitHandler = async (message: MessageToSend<StreamChatGenerics>) => {
    let updatedMessage

    if (message.attachments?.length && message.text?.startsWith('/giphy')) {
      const updatedText = message.text.replace('/giphy', '')
      updatedMessage = { ...message, text: updatedText }
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`
      updatedMessage = { ...message, text: updatedText }
    }

    if (channel) {
      const newMessage = updatedMessage || message
      const parentMessage = newMessage.parent

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      }

      if (get(channel, 'data.frozen')) {
        const payload = { ...messageToSend, attachments: [] }
        const sendMessagePromise = sendMessage(payload)
        logChatPromiseExecution(sendMessagePromise, 'send message')
        return
      }

      if (channel) {
        channel.sendMessage(messageToSend).then((resp) => {
          if (get(resp, 'message.id')) uploadFileAction(resp.message.id)
        })
      }
    }

    setGiphyState(false)
  }

  const actions = ['delete', 'edit', 'react', 'reply']

  useEffect(() => {
    const handlerNewMessageEvent: any = (event) => {
      try {
        const { message, user } = event

        if (user?.id !== client?.userID && channel?.cid !== message?.cid) {
          setChannelMessageCount((state) => ({
            ...state,
            [message.cid]: state[message.cid] ? state[message.cid] + 1 : 1,
          }))
          setChatsUnreadCount(chatsUnreadCount + 1)
          chatUnreadMessageCountAction(chatsUnreadCount + 1)
        }
      } catch (error: any) {
        console.log('error: ', error)
      }
    }

    client?.on('message.new', handlerNewMessageEvent)

    return () => client?.off('message.new', handlerNewMessageEvent)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, client, chatsUnreadCount, setChatsUnreadCount])

  return (
    <>
      <Window>
        <MessagingChannelHeader
          theme={theme}
          setShowChannelPreview={setShowChannelPreview}
          showChannelPreview={showChannelPreview}
          setShowDesktopUserProfile={setShowDesktopUserProfile}
          showDesktopUserProfile={showDesktopUserProfile}
          usersAssessmentResponseByQuestion={props.usersAssessmentResponseByQuestion}
        />
        <MessageList messageActions={actions} />
        <MessageInput focus overrideSubmitHandler={overrideSubmitHandler} />
      </Window>
      <Thread Input={MessagingInput} />
    </>
  )
}
