import { all } from 'redux-saga/effects'
import { watcherUser } from 'store/sagas/user'
import { watcherAuth } from 'store/sagas/auth'
import { watcherGoal } from 'store/sagas/goal'
import { watcherPlay } from 'store/sagas/play'
import { watcherForm } from 'store/sagas/form'
import { watcherAdmin } from 'store/sagas/admin'
import { watcherCommon } from 'store/sagas/common'
import { watcherClients } from 'store/sagas/clients'
import { watcherAssessment } from 'store/sagas/owner'
import { watcherCalendar } from 'store/sagas/calendar'
import { watcherPlayBook } from 'store/sagas/playBook'
import { watcherUserManagement } from 'store/sagas/userManagement'
import { watcherManagePlays } from 'store/sagas/managePlays'

export default function* rootSaga() {
  const sagas: any = [
    watcherAuth(),
    watcherUser(),
    watcherGoal(),
    watcherForm(),
    watcherPlay(),
    watcherAdmin(),
    watcherCommon(),
    watcherClients(),
    watcherCalendar(),
    watcherPlayBook(),
    watcherAssessment(),
    watcherManagePlays(),
    watcherUserManagement(),
  ]
  yield all(sagas)
}
