import findKey from 'lodash/findKey'
import last from 'lodash/last'
import includes from 'lodash/includes'
import { fileIcons, defaultFileIcon, fileExtension } from 'config'

export const getExt = (fileName: string) => last(fileName?.split('.'))

const getFileIcons = (fileType) =>
  fileIcons.find((icons) => {
    return includes(icons.iconLabel, fileType)
  })

export const getFileIcon = (fileName: string) => {
  const ext = getExt(fileName)
  if (ext) {
    const fileType = findKey(fileExtension, (allExts) => includes(allExts, ext))
    return getFileIcons(fileType) || defaultFileIcon
  }
  return defaultFileIcon
}
