import React from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import ButtonField from 'components/Common/FormFields/ButtonField'
import { ElipseIcon } from 'components/Common/SvgIcons'
import DropzoneComponent from 'components/Common/UploadFiles/Dropzone'
import { getFileIcon } from 'components/Common/UploadFiles/utils'

const UploadFiles = ({ handleClose, showUploadModal, uploadedImage, uploadFileAction }) => {
  const handleChange = (file) => {
    file = [...file]
    uploadFileAction({
      files: file,
    })
  }

  return (
    <>
      <div className="grid xs:grid-cols-1 sm:grid-cols-12 pt-10">
        <DropzoneComponent handleChange={handleChange} />
        <div className="col-span-6 px-4 relative">
          {map(uploadedImage, (file) => {
            const icon = getFileIcon(file?.file?.name)
            return (
              <div key={file.id} className="flex items-center border-primary-outline pb-4 ">
                <span className="mx-3 relative">
                  <ElipseIcon className={'fill-primary-outline h-[50px] w-[50px]'} />
                  {icon && (
                    <icon.value className={'absolute top-2 left-2 w-[32px] h-[35px] pl-1'} />
                  )}
                </span>
                <span className="flex flex-col w-full pr-8 text-ellipsis overflow-hidden">
                  <span className="text-primary text-base text-ellipsis overflow-hidden">
                    {file?.file?.name}
                  </span>
                  <span className="text-silver-darker text-base">
                    <div
                      className={`${
                        get(file, 'state') === 'finished' ? 'bg-primary' : 'bg-primary-disabled'
                      } w-full bg-gray-200 rounded-full h-2.5 `}
                    >
                      <div className="bg-blue-600 h-2.5 rounded-full"></div>
                    </div>
                  </span>
                </span>
              </div>
            )
          })}
          <div className="absolute bottom-8 right-8">
            <ButtonField name={'Close'} onClick={handleClose} variant="primary" />
          </div>
        </div>
      </div>
    </>
  )
}

export default UploadFiles
