import { getAuthToken } from 'lib/auth'
import NProgress from 'nprogress'

export const API_URL = process.env.REACT_APP_API_URL!

class API {
  fetch = async (query, variables) => {
    NProgress.start()
    const authToken = await getAuthToken()
    const res = await fetch(API_URL, {
      method: 'POST',
      headers: {
        ...(authToken && { Authorization: authToken }),
      },
      body: JSON.stringify({ query, variables }),
      keepalive: true,
      cache: 'no-cache',
    })
    const json = await res.json()

    if (json.errors) {
      const { message } = json.errors[0]
      NProgress.done()
      throw new Error(message)
    }
    NProgress.done()
    return json.data
  }

  uploadFile = async (url, formData, type) => {
    NProgress.start()
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      body: formData,
    })

    if (res.status === 200) {
      NProgress.done()
      return res.url.split('?')[0]
    } else {
      NProgress.done()
      throw new Error('Failed to upload')
    }
  }
}

// eslint-disable-next-line
export default new API()
