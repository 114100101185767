import {
  CHANGE_PASSWORD,
  GET_USER_FILES,
  SAVE_USER_FILE,
  UPLOAD_FILE,
  TOTAL_UPCOMING_MEETINGS,
  RESET_IMAGE_URL,
} from 'store/types'

export const changePasswordAction = {
  STARTED: (payload: any) => ({ type: CHANGE_PASSWORD.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: CHANGE_PASSWORD.FULLFILLED, payload }),
  REJECTED: () => ({ type: CHANGE_PASSWORD.REJECTED }),
}

export const uploadFileAction = {
  STARTED: (payload: any) => ({ type: UPLOAD_FILE.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: UPLOAD_FILE.FULLFILLED, payload }),
  REJECTED: () => ({ type: UPLOAD_FILE.REJECTED }),
}
export const saveUserFileAction = {
  STARTED: (payload: any) => ({ type: SAVE_USER_FILE.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: SAVE_USER_FILE.FULLFILLED, payload }),
  REJECTED: () => ({ type: SAVE_USER_FILE.REJECTED }),
}
export const getUserFilesAction = {
  STARTED: (payload: any) => ({ type: GET_USER_FILES.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_USER_FILES.FULLFILLED, payload }),
  REJECTED: () => ({ type: GET_USER_FILES.REJECTED }),
}

export const totalUpComingMeetingsAction = (payload) => ({
  type: TOTAL_UPCOMING_MEETINGS,
  payload,
})
export const resetImageUrlAction = () => ({
  type: RESET_IMAGE_URL,
})
