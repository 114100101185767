import React, { useEffect, useState } from 'react'

import { isMobileOnly, isTablet } from 'mobile-device-detect'

import { sortHandlerHelper } from 'utils/helper'
import { businessDataTableColumns } from 'config'

import ModalBox from 'components/Common/ModalBox'
import DataTable from 'components/Common/DataTable'
import Filters from 'components/Admin/UserManagement/Filters'
import Analytics from 'components/Admin/UserManagement/Analytics'
import { IpadViewPort } from 'components/Admin/UserManagement/style'
import { UserManagementListContainer } from 'pages/Admin/container/UserManagement'
import UserManagementHeading from 'components/Admin/UserManagement/StyledComponents/UserManagementHeading'
import Loader from 'components/Loaders'

const Businesses = ({
  getBusinessesAccountDetailsAction,
  businessAccountFilterAction,
  businessesAccountDetails,
  getAdvisorsAction,
  businessAccountFilter,
  ownerDataLoading,
  advisors,
}) => {
  const [showFilters, setShowFilters] = useState(false)
  const [businessList, setBusinessList] = useState<any>()
  const [pageSize, setPageSize] = useState(20)

  const closemodal = () => {
    setShowFilters(false)
  }
  useEffect(() => {
    getAdvisorsAction({ isPaginated: false })
    getBusinessesAccountDetailsAction({ isPaginated: false })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setBusinessList(businessesAccountDetails)
  }, [businessesAccountDetails])

  const nextPaginatedDataHandler: any = (params) => {
    getBusinessesAccountDetailsAction({ ...params, pageSize })
  }

  const sortHandler = ({ sortField, sortOrder, fieldType }) => {
    let sortedTableData = sortHandlerHelper({
      sortField,
      sortOrder,
      fieldType,
      data: businessList,
    })

    setBusinessList(sortedTableData)
  }

  return (
    <>
      <IpadViewPort>
        <div className="xs:col-span-12 lg:col-span-12 lg:block hideInIpadProView mdl:px-8 xl:px-0">
          {/* desktop & ipad */}

          {/* DesktopView */}
          <div className="w-full p-2 overflow-auto mdl:overflow-hidden">
            <UserManagementHeading title={'Business'} />
            {/* Note: Data is static that'why i am commenting this code 
              {/* <LicenseDetail /> */}
            <div className="hidden mdl:block pb-6">
              <Filters
                isBusinessTab={true}
                advisors={advisors}
                setShowFilters={setShowFilters}
                showFilters={showFilters}
                sortHandler={sortHandler}
                filterAction={businessAccountFilterAction}
                filter={businessAccountFilter}
              />
            </div>

            {/* data-table */}
            {ownerDataLoading ? (
              <Loader loader="UserManagementLoader" />
            ) : (
              <div className="w-full min-w-[800px]">
                <DataTable
                  pageSize={pageSize}
                  columns={businessDataTableColumns}
                  rows={businessesAccountDetails}
                  pagination={true}
                  setPageSize={setPageSize}
                />
              </div>
            )}
          </div>
        </div>
      </IpadViewPort>
      <>
        <IpadViewPort>
          {/* Mobile */}
          {(isMobileOnly || isTablet) && (
            <div className="col-span-12 hideInIpadView">
              <Analytics isBusinessTab={true} />
              <Filters
                isBusinessTab={true}
                advisors={advisors}
                setShowFilters={setShowFilters}
                showFilters={showFilters}
                sortHandler={sortHandler}
                filterAction={businessAccountFilterAction}
                filter={businessAccountFilter}
              />

              <UserManagementListContainer
                list={businessList}
                isBusinessTab={true}
                nextPaginatedDataHandler={nextPaginatedDataHandler}
                pageSize={pageSize}
              />
            </div>
          )}

          {showFilters && (
            <ModalBox onClose={closemodal} title="Filter, Sort, Search" width={'w-full'}>
              <Filters
                advisors={advisors}
                isBusinessTab={true}
                sortHandler={sortHandler}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                filterAction={businessAccountFilterAction}
                filter={businessAccountFilter}
              />
            </ModalBox>
          )}
        </IpadViewPort>
      </>
    </>
  )
}

export default Businesses
