import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class CLIENTS_API {
  getClients = ({ advisorId, tenantId }): Promise<any> => {
    return API.fetch(api['GetClientsDocument'], { input: { advisorId, tenantId } })
  }
  getAdvisorDashboard = ({ filterType, tenantId }): Promise<any> => {
    return API.fetch(api['GetAdvisorDashboardDocument'], { filterType, tenantId })
  }
  getTopActiveClients = ({ tenantId }): Promise<any> => {
    return API.fetch(api['GetTopActiveClientsDocument'], { tenantId })
  }

  getOwnerActivityDetails = (ownerId, tenantId): Promise<any> => {
    return API.fetch(api['GetOwnerActivityDetailsDocument'], { ownerId, tenantId })
  }

  getActivityLogs = (userId, tenantId): Promise<any> => {
    return API.fetch(api['GetActivityLogsDocument'], { userId, tenantId })
  }

  getClientConnectedTools = (userId = undefined, tenantId = undefined): Promise<any> => {
    return API.fetch(api['GetUserIntegrationsDocument'], { userId, tenantId })
  }
  getTackleMeetings = (payload): Promise<any> => {
    return API.fetch(api['GetTackleMeetingsDocument'], { input: payload })
  }
  updateTackleMeeting = (payload): Promise<any> => {
    return API.fetch(api['UpdateTackleMeetingDocument'], { input: payload })
  }
  deauthenticateConnector = (connectorName = undefined, tenantId = undefined): Promise<any> => {
    return API.fetch(api['DeauthenticateConnectorDocument'], { input: { connectorName, tenantId } })
  }
  cancelTackleMeeting = (input): Promise<any> => {
    return API.fetch(api['CancelTackleMeetingDocument'], { input: input })
  }
  getAllTools = (input): Promise<any> => {
    return API.fetch(api['GetAllToolsDocument'], { input })
  }
  updateMyTools = (payload): Promise<any> => {
    return API.fetch(api['UpdateOwnerDocument'], { input: payload })
  }
  getMyTools = (userId = undefined, tenantId = undefined): Promise<any> => {
    return API.fetch(api['GetUserToolsDocument'], { userId, tenantId })
  }
  toolOauthSuccess = (
    connectorName = undefined,
    tenantId = undefined,
    playId = undefined
  ): Promise<any> => {
    return API.fetch(api['ToolOauthSuccessDocument'], {
      input: { connectorName, tenantId, playId },
    })
  }
}

// eslint-disable-next-line
export default new CLIENTS_API()
