import React, { useEffect, useState } from 'react'
import { getAuthToken } from 'lib/auth'
import { useLocation } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { Route } from 'react-router'
import { connect } from 'react-redux'
import { AnimateKeyframes } from 'react-simple-animate'

import history from 'utils/history'
import { ROLES } from 'config'
import { IdleTrackingContainer } from 'pages/Common/container'
import { getUserProfileAction } from 'store/actions/user'
import isEmpty from 'lodash/isEmpty'
import { logoutAction } from 'store/actions/auth'
import UserApi from 'api/user'
import Logo from 'assets/images/Tackle-Logo.png'
/**
 * PrivateRoute is used to support the react router and it renders the routes
 * which is marked as private or is only accessible authenticated users
 * @param {React.Component} component
 */
const PrivateRoute = ({ component, user, logoutAction, getUserProfileAction, ...rest }) => {
  const location = useLocation()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      const jwtToken = await getAuthToken()
      if (!jwtToken) {
        history.push(`/auth/login`)
        return
      }
    })()
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', async function (e) {
      UserApi.logActivity({ action: 'tabClose', accessToken: await getAuthToken() })
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setLoading(true)
    if (isEmpty(user)) {
      getUserProfileAction()
    } else {
      let found = false
      const roles = user.roles
      const pathName = location.pathname

      roles.forEach((role) => {
        const words = pathName.split('/')
        if (words.includes(ROLES[role])) {
          found = true
        }
      })
      if (!found) {
        history.goBack()
      }
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const routeComponent = (props) => React.createElement(component, props)
  return (
    <>
      {!loading ? (
        <>
          <IdleTrackingContainer />
          <Route {...rest} render={routeComponent} pageTitle="" />
        </>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: '46%',
            left: '42%',
          }}
        >
          <AnimateKeyframes
            play
            iterationCount="infinite"
            duration={2}
            keyframes={[
              'transform: translate(0px, 0px)',
              'transform: translate(200px, 0px)',
              'transform: translate(0px, 0px)',
            ]}
          >
            <img src={Logo} alt="Tackle icon" className="object-contain h-[39px]" />
          </AnimateKeyframes>
        </div>
      )}
    </>
  )
}

const mapProps = (state: any) => {
  const { user } = state.user
  return {
    user,
  }
}

function mapDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUserProfileAction: getUserProfileAction.STARTED,
      logoutAction: logoutAction,
    },
    dispatch
  )
}

export default connect(mapProps, mapDispatch)(PrivateRoute)
