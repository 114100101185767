import { pick } from 'lodash'
import {
  GET_ADVISORS_DASHBOARD,
  GET_CLIENTS,
  GET_CLIENT_ACTIVITY_LOGS,
  GET_CLIENT_ACTIVITY_SCORE,
  GET_CLIENT_ASSESSMENTS,
  GET_CLIENT_ASSESSMENT_LIST,
  GET_CLIENT_PROFILE,
  GET_CLIENT_PROFILE_DETAILS,
  GET_TOP_ACTIVE_CLIENTS,
  OPEN_CLIENT_DETAIL,
  UPDATE_CLIENT_PROFILE_DETAIL,
  GET_CLIENT_CONNECTED_TOOLS,
  GET_ALL_TOOLS,
  GET_TACKLE_MEETING_EVENTS,
  UPDATE_TACKLE_MEETING,
  SHOW_COMMENTS,
  GET_MY_TOOLS,
} from 'store/types'

const initialState = {
  clientsData: {},
  list: [],
  topActiveClients: [{}, {}, {}],
  clientConnectedTools: {},
  clientAssessmentList: [],
  clientAssessments: {},
  client: {
    profile: {},
    businessProfile: {},
    activity: {
      scoreCards: {},
      activityLogs: [],
    },
    details: {},
  },
  assignedOwnerList: [],
  openClientDetail: false,
  disableSaveButton: false,
  allTools: {},
  meetingEvents: [],
  updatedComments: [{ id: '', tenantId: '', comment: '' }],
  showComments: false,
  ownerIds: [],
  tackleMeeting: {
    data: [],
    pagination: '',
    totalCount: 0,
    totalUnread: 0,
    moreDataExists: true,
    filter: '',
  },
  myTools: [],
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADVISORS_DASHBOARD.FULLFILLED:
      return {
        ...state,
        list: state.list.length > 0 ? state.list : action.payload?.accounts,
        clientsData: action.payload,
      }
    case GET_CLIENTS.FULLFILLED:
      return {
        ...state,
        assignedOwnerList: action.payload,
      }
    case GET_TOP_ACTIVE_CLIENTS.FULLFILLED:
      return {
        ...state,
        topActiveClients: action.payload,
      }
    case GET_CLIENT_PROFILE.FULLFILLED:
      return {
        ...state,
        client: {
          ...state.client,
          profile: action.payload,
        },
      }

    case GET_CLIENT_ACTIVITY_LOGS.FULLFILLED:
      return {
        ...state,
        client: {
          ...state.client,
          activity: {
            ...state.client.activity,
            activityLogs: action.payload,
          },
        },
      }

    case GET_CLIENT_ACTIVITY_SCORE.FULLFILLED:
      return {
        ...state,
        client: {
          ...state.client,
          activity: {
            ...state.client.activity,
            scoreCards: action.payload,
          },
        },
      }

    case GET_CLIENT_PROFILE_DETAILS.FULLFILLED:
      return {
        ...state,
        client: {
          ...state.client,
          businessProfile: action?.payload.businessProfile,
          assessmentResponse: action?.payload?.assessmentResponse,
        },
      }
    case UPDATE_CLIENT_PROFILE_DETAIL.STARTED:
      return {
        ...state,
        disableSaveButton: true,
        openClientDetail: true,
      }
    case UPDATE_CLIENT_PROFILE_DETAIL.FULLFILLED:
      return {
        ...state,
        disableSaveButton: false,
        openClientDetail: false,
      }
    case UPDATE_CLIENT_PROFILE_DETAIL.REJECTED:
      return {
        ...state,
        disableSaveButton: false,
        openClientDetail: false,
      }
    case OPEN_CLIENT_DETAIL:
      return {
        ...state,
        openClientDetail: action.payload,
      }
    case GET_CLIENT_ASSESSMENT_LIST.FULLFILLED:
      return {
        ...state,
        clientAssessmentList: action.payload,
      }

    case GET_CLIENT_ASSESSMENTS.FULLFILLED:
      return {
        ...state,
        clientAssessments: {
          ...state.clientAssessments,
          ...action.payload,
        },
      }
    case GET_CLIENT_CONNECTED_TOOLS.FULLFILLED:
      return {
        ...state,
        clientConnectedTools: {
          ...action.payload,
        },
      }
    case GET_ALL_TOOLS.FULLFILLED:
      return {
        ...state,
        allTools: {
          ...action.payload,
        },
      }
    case GET_TACKLE_MEETING_EVENTS.FULLFILLED:
      return {
        ...state,
        tackleMeeting: {
          ...state.tackleMeeting,
          ...pick(action.payload, [
            'data',
            'pagination',
            'totalCount',
            'totalUnread',
            'moreDataExists',
            'filter',
          ]),
        },
      }
    case UPDATE_TACKLE_MEETING.FULLFILLED:
      return {
        ...state,
        updatedComments: state.updatedComments.some((comment) => comment.id === action.payload.id)
          ? state.updatedComments.map((obj) =>
              obj.id === action.payload.id ? action.payload : obj
            )
          : [...state.updatedComments, action.payload],
      }
    case SHOW_COMMENTS:
      return {
        ...state,
        ownerIds: state.ownerIds.find((id) => id === action.payload)
          ? state.ownerIds.filter((element) => element !== action.payload)
          : [...state.ownerIds, action.payload],
      }
    case GET_MY_TOOLS.FULLFILLED:
      return {
        ...state,
        myTools: action.payload,
      }
    default:
  }
  return state
}

export default reducer
