import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class USER_MANAGEMNT_API {
  getBigQuery = (query): Promise<any> => {
    return API.fetch(api['GetBigQueryDocument'], { query: query })
  }

  getBusinesses = (getBusinessesInput): Promise<any> => {
    return API.fetch(api['GetBusinessesDocument'], { getBusinessesInput: getBusinessesInput })
  }

  getAdvisors = (getAdvisorsInput): Promise<any> => {
    return API.fetch(api['GetAdvisorsDocument'], { getAdvisorsInput: getAdvisorsInput })
  }
}

// eslint-disable-next-line
export default new USER_MANAGEMNT_API()
